import { phoneFormat } from '@utils/strings';
import { RiBarcodeFill, RiCalendarLine, RiWhatsappFill } from 'react-icons/ri';
import styles from './styles.module.css';

interface AttendantInfoProps {
  attendantName: string;
  attendantNumber: string;
}

interface ChatInfoProps {
  protocol: number;
  platform: string;
  date: string | undefined;
}

function AttendantInfo({ attendantName, attendantNumber }: AttendantInfoProps) {
  return (
    <div className={styles.attendantInfo}>
      <p>Chat de atendimento</p>
      <h2>{attendantName}</h2>
      <p>Número de Contato</p>
      <h2>{phoneFormat(attendantNumber)}</h2>
    </div>
  );
}

function ChatInfo({ protocol, platform, date }: ChatInfoProps) {
  return (
    <div className={styles.chatInfo}>
      <div className={styles.infoItem}>
        <p>Protocolo</p>
        <div className={styles.itemIcon}>
          <RiBarcodeFill className={styles.icons} size={22} />
          <h2>{protocol}</h2>
        </div>
      </div>
      <div className={styles.infoItem}>
        <p>Plataforma</p>
        <div className={styles.itemIcon}>
          <RiWhatsappFill className={styles.icons} size={22} />
          <h2>{platform.charAt(0).toUpperCase() + platform.slice(1)}</h2>
        </div>
      </div>
      <div className={styles.infoItem}>
        <p>Data de abertura</p>
        <div className={styles.itemIcon}>
          <RiCalendarLine className={styles.icons} size={22} />
          <h2>{date}</h2>
        </div>
      </div>
    </div>
  );
}

export { AttendantInfo, ChatInfo };
