/* eslint-disable jsx-a11y/media-has-caption */
import Modal from '@components/Modal';
import { quickReply } from '@flux/models/pages/chatPage';
import { useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';

import styles from './styles.module.css';

export interface QRModalProps {
  isOpen: boolean;
  onClose: () => void;
  quickReplies: quickReply[];
  setQRSuggestion: (arg0: string) => void;
  onAfterClose?: () => void;
}

function QRModal({
  isOpen,
  onClose,
  quickReplies, 
  setQRSuggestion,
}: QRModalProps) {
  const [QRs, setQRs] = useState<quickReply[]>(quickReplies);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setQRs(quickReplies);
  }, [quickReplies]);

  useEffect(() => {
    if (search === ''){
      setQRs(quickReplies);
    } else {
      setQRs(quickReplies.filter(item => 
        item.name 
          ? item.name.toLowerCase().includes(search.toLowerCase())
          : item.text.toLowerCase().includes(search.toLowerCase())
      ));
    }
  }, [search]);

  const handleChangeQR = (text: string) => {
    setQRSuggestion(text);
    onClose();
  };
  
  return (
    <div className={styles.contentModal}>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className={styles.content}>
          <div className={styles.header}>
            <p>Mensagens Rápidas</p>
            <button type='button' onClick={() => onClose()}>X</button>
          </div>

          <div className={styles.search}>
            <BsSearch size={20} style={{ marginRight: 3, marginLeft: 11 }} />
            <input 
              type='text' 
              placeholder='Pesquisar' 
              onChange={event => setSearch(event.target.value)}
              value={search}
              className={styles.inputSearch}
            />
          </div>

          <div className={styles.QRList}>
            {QRs.map( (QR) => (
              <button type='button' onClick={() => handleChangeQR(QR.text)} className={styles.QRSuggestion} key={QR.id}>
                {
                  QR.name 
                  ? <p>{QR.name}</p>
                  : <p>{QR.text}</p>
                }
              </button>
              )
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default QRModal;
