import { useEffect, useState } from 'react';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import PeriodPicker from '@components/PeriodPicker';
import HoursChart from '@pages/Admin/ManagerView/components/HoursChart';
import * as Application from '@flux/models/application/hooks';
import styles from '../CollaboratorsTab/styles.module.css';
import chartStyles from '../../ManagerView/styles.module.css';


function HoursTab() {
  const [since, setSince] = useState<Date>(new Date('2021-01-17T13:46:06.868Z'));
  const [until, setUntil] = useState<Date>(new Date('2021-06-17T13:46:06.868Z'));
  const { user } = Application.useModel();

  const {
    logs,
    getLogs
  } = useModel();

  useEffect(() => {
    getLogs( { since, until, company:user?.company || '' } );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ since, until]);

  return (
    <div>
      <section className={styles.navBarTeam}>
        <h1>Aprovação de novos usuários</h1>
        <PeriodPicker
          onSelect={(period) => {
            setSince(period.since);
            setUntil(period.until);
          }}
        />
      </section>
      <div className={chartStyles.statsChart}>
        <div className={chartStyles.chart}>
          <h1>Quantidade de horas logadas</h1>
          <HoursChart data={logs} />
        </div>
      </div>
    </div>
  );
}
export default HoursTab;
