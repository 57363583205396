import { Message } from '@entities';
import { dateLabel } from '@utils/strings';
import QuickReplies from '../FbQuickReplies';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
}

function FbTextMessage({ message }: MessageProps) {
  const source = message.origin === 'user' ? 'messageOrigin' : 'messageSender';
  const textFormatter = (text: string) => {
    const paragrahList = text.split('\n');
    return paragrahList.map(paragrah => <p key={Math.random()}>{paragrah}</p>);
  };

  return (
    <div className={styles[source]}>
      <p>{textFormatter(message.message)}</p>

      {message.body?.quick_replies
        && <QuickReplies quick_replies={message.body.quick_replies} />}
      <span>{dateLabel(message.created_at)}</span>
    </div>
  );
}

export default FbTextMessage;
