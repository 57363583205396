import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Message, Contact } from '@entities';
import api, { errorHandler } from '@services/api';
import socket, {
  SEND_CHAT_MESSAGE_EVENT,
} from '@services/socket';

export interface ChatContactPageState {
  error: string;
  contact: Contact | null;
  messages: Message[];
  reactivationMessage: string;
}

export const chatContactPage = createModel<RootModel>()({
  state: {
    error: '',
    contact: null,
    messages: [],
    reactivationMessage: '',
  } as ChatContactPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setContact(state, payload: Contact) {
      state.contact = payload;
      return state;
    },

    setMessages(state, payload: Message[]) {
      state.messages = payload;
      return state;
    },

    addMessage(state, payload: Message) {
      state.messages.push(payload);
      return state;
    },

    setReactivationMessage(state, payload: string) {
      state.reactivationMessage = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async getContactInfo(payload: string, state) {
      const {
        setError,
        setContact,
        setMessages,
        getReactivationMessage
      } = dispatch.chatContactPage;

      await errorHandler(async () => {
        const response = await api.get(`/contacts/${payload}`);
        const { messages, contact } = response.data;
        await getReactivationMessage({
          bot_id: contact.bot_id
        }) ;
        setMessages(messages);
        setContact(contact);
      }, setError);
    },

    async sendMessage(payload: { message: string, bot_id: string }, state) {
      const { addMessage, setError } = dispatch.chatContactPage;
      const { contact } = state.chatContactPage;
      let plataformId = '';

      await errorHandler(async () => {
        const res = await api.get(`/bots/bot?bot_id=${payload.bot_id}`);
        plataformId = res.data.plataform_id;
      }, setError);


      if (contact && plataformId) {
        const newMessage = createMessage(payload.message, contact, plataformId);
        socket.emit(SEND_CHAT_MESSAGE_EVENT, newMessage);
        addMessage(newMessage);
      }
    },

    async getReactivationMessage(payload: { bot_id: string }, state) {
      const { setError, setReactivationMessage } = dispatch.chatContactPage;
      const { bot_id } = payload;
      await errorHandler(async () => {
        const res = await api.get(`/bots/bot?bot_id=${bot_id}`);
        const bot = res.data;
        const message = bot.reactivationMessage || '';
        setReactivationMessage(message);
      }, setError);
    },
  }),
});

const createMessage = (content: string, contact: Contact, plataformId: string): Message => ({
  platform_id: plataformId,
  user_id: `whatsapp:+55${contact.phone}`,
  platform: 'whatsapp',
  message: content,
  created_at: new Date().toISOString(),
  origin: 'attendants',
  client: contact.company || '',
  attendant_name: '',
  bot_id: contact.bot_id,
});
