import { useState, Fragment, useEffect } from 'react';
import { IconType } from 'react-icons';
import styles from './styles.module.css';

export interface TabItem {
  label: string;
  value: string;
  icon?: IconType;
}

export interface TabSwitchProps {
  options: TabItem[];
  onSelect: (selected: string) => void;
  selectedIndex?: number;
}

function TabSwitch({ options, onSelect, selectedIndex = 0 }: TabSwitchProps) {
  const [selected, setSelected] = useState(options[selectedIndex].value || '');

  useEffect(() => {
    setSelected(options[selectedIndex].value);
  }, [selectedIndex, options, setSelected]);

  return (
    <div className={styles.switch}>
      {options.map((item, index) => (
        <Fragment key={Math.random()}>
          <button
            key={String(Math.random())}
            className={selected === item.value ? styles.selected : ''}
            type="button"
            onClick={() => {
              setSelected(item.value);
              onSelect(item.value);
            }}
          >
            {item.icon && (
              <item.icon
                color={selected === item.value ? '#4a69bd' : 'black'}
                size={20}
              />
            )}
            {item.label}
          </button>
          {index !== options.length - 1 && <span />}
        </Fragment>
      ))}
    </div>
  );
}

export default TabSwitch;
