import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { AttendancePageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const attendancePageState = useSelector<RootState, AttendancePageState>(
    (state) => state.attendancePage
  );

  return {
    ...dispatch.attendancePage,
    ...attendancePageState,
  };
};

export const useLoading = () => {
  const loadingAttendances = useSelector<RootState, boolean>(
    (state) => state.loading.effects.attendancePage.getAttendances
  );

  return { loadingAttendances };
};
