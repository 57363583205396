import { Models } from '@rematch/core';

import { application } from './application';
import { tags } from './tags';

import { quickReplies } from './modals/QuickReplies';

import { loginPage } from './pages/loginPage';
import { registerPage } from './pages/registerPage';
import { recoverPasswordPage } from './pages/recoverPasswordPage';
import { sendEmailPage } from './pages/sendEmailPage';
import { settingsPage } from './pages/settingsPage';
import { settingsOptionsPage } from './pages/SettingsOptionsPage';
import { attendancePage } from './pages/attendancePage';
import { adminPage } from './pages/adminPage';
import { chatPage } from './pages/chatPage';
import { chatContactPage } from './pages/chatContactPage';
import { historyPage } from './pages/historyPage';
import { contactPage } from './pages/contactsPage';
import { profilePage } from './pages/profilePage';
import { statisticsPage } from './pages/statisticsPage';
import { bulkMessagingPage } from './pages/bulkMessagingPage';

export interface RootModel extends Models<RootModel> {
  application: typeof application;
  loginPage: typeof loginPage;
  recoverPasswordPage: typeof recoverPasswordPage;
  sendEmailPage: typeof sendEmailPage;
  settingsPage: typeof settingsPage;
  settingsOptionsPage: typeof settingsOptionsPage;
  attendancePage: typeof attendancePage;
  chatPage: typeof chatPage;
  bulkMessagingPage: typeof bulkMessagingPage;
  chatContactPage: typeof chatContactPage;
  registerPage: typeof registerPage;
  adminPage: typeof adminPage;
  tags: typeof tags;
  historyPage: typeof historyPage;
  contactPage: typeof contactPage;
  statisticsPage: typeof statisticsPage;
  profilePage: typeof profilePage;

  quickReplies: typeof quickReplies;
}

export const models: RootModel = {
  application,
  loginPage,
  recoverPasswordPage,
  sendEmailPage,
  settingsPage,
  settingsOptionsPage,
  attendancePage,
  bulkMessagingPage,
  chatPage,
  chatContactPage,
  registerPage,
  adminPage,
  tags,
  historyPage,
  contactPage,
  profilePage,
  statisticsPage,
  quickReplies
};
