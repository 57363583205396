import LoadingSpinner from '@components/LoadingSpinner';
import styles from './styles.module.css';

export interface InfoProps {
  title: string;
  quantity: number;
  loading?: boolean
}

function Info({ title, quantity, loading }: InfoProps) {
  if (loading) {
    return (
      <div className={styles.loadingSpinner}>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className={styles.info}>
      <p>{title}</p>
      <h2>{quantity}</h2>
    </div>
  );
}

export default Info;
