import { Redirect, Switch } from 'react-router-dom';

import Login from '@pages/Login';
import Register from '@pages/Register';
import Attendance from '@pages/Attendance';
import Admin from '@pages/Admin';
import RecoverPassword from '@pages/RecoverPassword';
import SendEmail from '@pages/SendEmail';
import Statistics from '@pages/Statistics';
import History from '@pages/History';
import Contacts from '@pages/Contacts';
import Settings from '@pages/Settings';
import SettingsOptions from '@pages/Settings/SettingsOptions';
import Profile from '@pages/Profile';
import EditProfile from '@pages/EditProfile';
import Chat from '@pages/Chat';
import ChatContact from '@pages/ChatContact';
import RegisterGoogle from '@pages/RegisterGoogle';
import BulkMessaging from '@pages/BulkMessaging';

import Route from './Route';

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/cadastrar" component={Register} />
      <Route exact path="/cadastrar-google" component={RegisterGoogle} />
      <Route exact path="/redefinir-senha" component={RecoverPassword} />
      <Route exact path="/enviar-email" component={SendEmail} />

      <Route isPrivate exact path="/atendimentos" component={Attendance} />
      <Route isPrivate exact path="/admin" component={Admin} />
      <Route isPrivate exact path="/configuracoes" component={Settings} />
      <Route
        isPrivate
        exact
        path="/configuracoes/escolhas"
        component={SettingsOptions}
      />
      <Route isPrivate exact path="/historico" component={History} />
      <Route isPrivate exact path="/disparo-ativo" component={BulkMessaging} />
      <Route isPrivate exact path="/contatos" component={Contacts} />
      <Route isPrivate exact path="/estatisticas" component={Statistics} />
      <Route isPrivate exact path="/perfil" component={Profile} />
      <Route isPrivate exact path="/editar-perfil" component={EditProfile} />
      <Route isPrivate exact path="/atendimentos/:protocol" component={Chat} />
      <Route isPrivate exact path="/contacts/:id" component={ChatContact} />

      <Route exact path="/*" component={() => <Redirect to="/login" />} />
    </Switch>
  );
}

export default Routes;
