import { useEffect, useCallback, useState, useRef } from 'react';
import { FaCheckSquare, FaMinusSquare } from 'react-icons/fa';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useModel, useLoading } from '@flux/models/pages/attendancePage/hooks';
import * as Chat from '@flux/models/pages/chatPage/hooks';
import * as App from '@flux/models/application/hooks';
import * as AttendanceHook from '@flux/models/pages/attendancePage/hooks';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import { Attendance, Message } from '@entities';
import { formatNumber } from '@utils/strings';
import DashboardMenu from '@components/DashboardMenu';
import clockImg from '@assets/clock.png';
import speedImg from '@assets/attendance/speed.png';
import calendarImg from '@assets/attendance/calendar.png';
import notificationSound from '@assets/attendance/notification.mp3';
import { newMessageNotification } from '@utils/notifications';
import Table from '@components/Table';
import TabSwitch, { TabItem } from '@components/TabSwitch';
import CurrentAttendancesByTeam from '@components/CurrentAttendancesByTeam';

import Metric from '../../components/Metric';
import Paginator from './components/paginator';

import styles from './styles.module.css';

import {
  PENDING_COLUMNS,
  pendingToLineItem,
  CURRENT_COLUMNS,
  currentToLineItem,
  INACTIVE_COLUMNS,
  inactiveToLineItem
} from './tableInfos';

function AttendancePage() {
  const { setAttendance } = Chat.useModel();
  const { setPendingInterval } = AttendanceHook.useModel();
  const { loadingAttendances } = useLoading();
  const [audio] = useState(new Audio(notificationSound));
  const firstUpdate = useRef(true);

  const [currentPage, setCurrentPage] = useState(1);

  const {
    getAvailability,
    getAnalytics,
    getAttendances,
    available,
    toggleAvailability,
    openAttendence,
    averageAttendance,
    averageWaiting,
    completedAttendances,
    pending,
    current,
    messagesNotReadCounter,
    getMessagesNotReadCounter,
    lastMsgSender,
    lastMsgSenderName,
    getInactiveUsers,
    updateInactiveUsers,
    inactiveAttendances,
    getUnrespondedUsers,
    unrespondedAttendances,
    hasNextPage,
    setOrder,
    setSkip,
    order,
    skip
  } = useModel();

  const {
    teams,
    getTeams
  } = AdminHook.useModel();

  const { user } = App.useModel();
  // console.log(current);

  const fetchAttendancesInfos = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('fetch attendances', Date.now());
    getAttendances();
    getAnalytics();
    getAvailability();
    
    getInactiveUsers({ user_id: user?.id!, client: user?.company! });
    getUnrespondedUsers({ user_id: user?.id!, client: user?.company! });
  }, [getAttendances, getAnalytics, getAvailability, getInactiveUsers]);
  
  const handleOnChatMessage = useCallback((msg: Message) => {
    // eslint-disable-next-line no-console
    console.log('handleOnChatMessage', Date.now());
    updateInactiveUsers({ user_id: msg.user_id!, bot_id: msg.bot_id, client: user?.company! });
    getMessagesNotReadCounter(msg.user_id || '');
    getUnrespondedUsers({ user_id: user?.id!, client: user?.company! });
  }, [getMessagesNotReadCounter, updateInactiveUsers]);

  useEffect(() => {
    fetchAttendancesInfos();
  }, [order, skip]);

  useEffect(() => {
    fetchAttendancesInfos();
    socket.on(`changeOfAttendance-${user?.company}`, fetchAttendancesInfos);
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => handleOnChatMessage(msg));
    return () => {
      socket.off(`changeOfAttendance-${user?.company}`);
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, [fetchAttendancesInfos, user?.company]);

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);
  useEffect(() => {
    if (pending.length > 0 || current.length > 0) {
      audio.play();
    }

    const MIN = 1000 * 60;
    setPendingInterval(
      setInterval(() => {
        if (pending.length > 0) {
          audio.play();
        }
      }, MIN * 10)
    );
  }, [pending, current, audio, setPendingInterval]);

  useEffect(() => {
    document.title = pending.length ? `(${pending.length}) Roboter` : 'Roboter';
    return () => {
      document.title = 'Roboter';
    };
 }, [pending]);

  useEffect(() => {
    // console.log('pending', current);
    socket.emit('receive-ongoing', current.map((attend: Attendance) => attend.user_id));
  }, [current]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      console.log('VAI PEGAR OS TIMES DE', user?.company);
      getTeams(user?.company || '');
    }
  }, []);

  const selectPending = useCallback(
    (attendance: Attendance) => {
      setAttendance(attendance);
      openAttendence(attendance.protocol);
    },
    [setAttendance, openAttendence]
  );

  // paginação
  useEffect(() => {
    if (currentPage == 1) setSkip(0);
    else setSkip(3 * (currentPage - 1));
  }, [currentPage]);

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Atendimentos</h1>
              <p>Tenha uma visão geral dos atendimentos</p>
              <TabSwitch
                selectedIndex={available ? 0 : 1}
                onSelect={toggleAvailability}
                options={TAB_OPTIONS}
              />
            </div>

            <div className={styles.metricsContainer}>
              <Metric
                img={clockImg}
                title="Tempo médio
                de atendimento"
                description="(minutos)"
                value={formatNumber(averageAttendance)}
              />

              <Metric
                img={speedImg}
                title="Tempo médio
                de espera"
                description="(minutos)"
                value={formatNumber(averageWaiting)}
              />

              <Metric
                img={calendarImg}
                title="Atendimentos
                finalizados"
                description="(hoje)"
                value={formatNumber(completedAttendances)}
              />
            </div>
          </section>

          <section className={styles.tablesContainer}>
            <Table
              loading={loadingAttendances}
              width="47%"
              title="Novos pedidos de atendimento"
              columns={PENDING_COLUMNS}
              data={pendingToLineItem(pending, selectPending, teams )}
              topRightItem={
                <span className={styles.tableNum}>{pending.length}</span>
              }
              downRightItem={
                <Paginator 
                  current={currentPage}
                  hasNext={hasNextPage}
                  nextPage={() => setCurrentPage(currentPage + 1)}
                  previousPage={() => {
                    if (currentPage > 1) setCurrentPage(currentPage - 1);
                  }}
                  DescOrder={() => setOrder(-1)}
                  AscOrder={() => setOrder(1)}
                />
              }
            />

            <Table
              loading={loadingAttendances}
              width="47%"
              title="Atendimentos em andamento"
              columns={CURRENT_COLUMNS}
              data={currentToLineItem(current, setAttendance, unrespondedAttendances, messagesNotReadCounter, user!, teams)}
              topRightItem={
                <span className={styles.tableNum}>{current.length}</span>
              }
            />
          </section>
          {
            user && (user.type === 'manager' || user.type === 'administrator') 
            ?
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <CurrentAttendancesByTeam 
                  loading={loadingAttendances}
                  width="47%"
                  teams={teams} 
                  attendances={current}
                />
                <Table
                  loading={loadingAttendances}
                  width="47%"
                  title="Atendimentos Inativos"
                  columns={INACTIVE_COLUMNS}
                  data={inactiveToLineItem(inactiveAttendances, setAttendance, teams)}
                  topRightItem={
                    <span className={styles.tableNum}>{inactiveAttendances.length}</span>
                  }
                />
              </div>
            :
              <Table
                loading={loadingAttendances}
                width="96%"
                title="Atendimentos Inativos"
                columns={INACTIVE_COLUMNS}
                data={inactiveToLineItem(inactiveAttendances, setAttendance, teams)}
                topRightItem={
                  <span className={styles.tableNum}>{inactiveAttendances.length}</span>
                }
              />
          }
        </div>
      </DashboardMenu>
    </div>
  );
}

export default AttendancePage;

const TAB_OPTIONS: TabItem[] = [
  {
    label: 'Disponível',
    value: 'avaiable',
    icon: FaCheckSquare,
  },
  {
    label: 'Indisponível',
    value: 'unavaiable',
    icon: FaMinusSquare,
  },
];
