import { useEffect, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import DashboardMenu from '@components/DashboardMenu';
import { formatDate } from '@utils/strings';
import * as Application from '@flux/models/application/hooks';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import photo from '@assets/person.png';
import SubmitButton from '@components/SubmitButton';
import ProfileInfo from '@components/ProfileInfo';
import { newMessageNotification } from '@utils/notifications';
import { useHistory } from 'react-router-dom';
import { Message } from '@entities';
import styles from './styles.module.css';

function Profile() {
  const history = useHistory();

  const { user } = Application.useModel();
  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, [user]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  const getRole = (role: string) => {
    if (role === 'manager') return 'Gerente';
    return 'Atendente';
  };

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Meu perfil</h1>
              <p>Visualize seus dados e edite informações</p>
            </div>

            <div className={styles.lastAccess}>
              <h1>Último acesso</h1>
              <p>
                {user?.last_access ?
                  formatDate(user?.last_access || '').substring(0, 10) :
                  formatDate(user?.created_at || '').substring(0, 10)}
              </p>
            </div>
          </section>

          <section className={styles.profileData}>
            <div className={styles.photoSection}>
              {/* <button type="button" aria-label="photo" /> */}
              <img src={user?.avatar_url || photo} alt="Gerente" />
            </div>

            <div className={styles.data}>
              <ProfileInfo dataLabel="Nome completo" data={user?.name} />

              <ProfileInfo dataLabel="E-mail" data={user?.email} />

              <ProfileInfo dataLabel="Empresa" data={user?.company} />

              <div className={styles.block}>
                <ProfileInfo
                  dataLabel="Cargo"
                  data={getRole(user?.type || '')}
                  width="95%"
                  backgroundColor="#BCBCBC"
                  justifyContent="center"
                  paddingLeft="0"
                />

                <ProfileInfo
                  dataLabel="Cadastro"
                  data={formatDate(user?.created_at || '').substring(0, 10)}
                  width="95%"
                  backgroundColor="#BCBCBC"
                  justifyContent="center"
                  paddingLeft="0"
                  marginLeft="3%"
                />
              </div>
              <div className={styles.button}>
                <SubmitButton
                  label="Editar meu perfil"
                  onClick={() => history.push('/editar-perfil')}
                />
              </div>
            </div>
          </section>
        </div>
      </DashboardMenu>
    </div>
  );
}

export default Profile;
