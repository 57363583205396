
import { Message } from '@entities';
import { dateLabel } from '@utils/strings';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
}

function TextMessage({ message }: MessageProps) {
  return (
    <div
      className={
        message.origin === 'user'
          ? styles.messageOrigin
          : styles.messageSender
      }
      id={`text-message${Math.random()}`}
    >
      <p>{message.message}</p>
      <span>{dateLabel(message.created_at)}</span>
    </div>
  );
}

export default TextMessage;