import { store } from 'react-notifications-component';
import notificationSound from '@assets/attendance/notification.mp3';
import { Attendance } from '@entities';

export function newMessageNotification(
  lastMsgSenderName: String,
  messagesNotReadCounter: { [index:string]:number },
  lastMsgSender: String,
  current: Attendance[]
) {
  if (!attendanceInCurrent(lastMsgSender, current)) return;

  try {
    const audio = new Audio(notificationSound);
    audio.play();
  } catch (e) {
    console.log(e);
  }

  store.addNotification({
    title: 'Nova Mensagem',
    message: `${lastMsgSenderName}: ${messagesNotReadCounter[lastMsgSender.toString()]} mensagens não lidas`,
    type: 'default',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}

function attendanceInCurrent(userId: String, attendances: Attendance[]) {
  for (let i = 0; i < attendances.length; i++) {
    if (attendances[i].user_id === userId) {
      return true;
    }
  }
  return false;
}
