import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { RegisterPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const registerPageState = useSelector<RootState, RegisterPageState>(
    (state) => state.registerPage
  );

  return {
    ...dispatch.registerPage,
    ...registerPageState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.registerPage.handleRegister
  );

  const getCompaniesLoading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.registerPage.getCompanies
  );

  return { loading, getCompaniesLoading };
};
