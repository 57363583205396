import { useEffect, useState } from 'react';
import { BsChatDots, BsPersonFill, BsAlarm } from 'react-icons/bs';
import { RiTeamFill } from 'react-icons/ri';
import { MdPersonAdd } from 'react-icons/md';
import TabSwitch, { TabItem } from '@components/TabSwitch';
import ChatbotsTab from '@pages/Admin/ManagerView/components/ChatbotsTab';
import TeamsTab from '@pages/Admin/ManagerView/components/TeamsTab';
import CollaboratorsTab from '@pages/Admin/components/CollaboratorsTab';
import { Chatbot, Team, User } from '@entities';
import RequestsTab from '@pages/Admin/components/RequestsTab';
import styles from './styles.module.css';
import HoursTab from '../components/HoursTab';

interface ManagerProps {
  chatbots: Chatbot[];
  teams: Team[];
  collaborators: User[];
  requests: User[];
  user: User | null;
}

function Manager({ chatbots, collaborators, requests, user, teams }: ManagerProps) {
  const [tabSelected, setTabSelected] = useState(TAB_OPTIONS[0].value);

  useEffect(() => {
    const localStorageTab = localStorage.getItem('tabAdmin');
    if (localStorageTab) {
      setTabSelected(localStorageTab);
      localStorage.removeItem('tabAdmin');
    }
  }, []);

  const getTabComponent = () => {
    if (tabSelected === 'chatbots') {
      return <ChatbotsTab chatbots={chatbots} />;
    }
    if (tabSelected === 'equipes') {
      return <TeamsTab teams={teams} />;
    }
    if (tabSelected === 'colaboradores') {
      return <CollaboratorsTab
        collaborators={collaborators.filter((colab: User) =>
          colab.id !== user?.id)}
        user={user}
        teams={teams}
      />;
    }
    if (tabSelected === 'solicitacoes'){
      return <RequestsTab requests={requests} origem={user?.type || ''} />;
    }
    return <HoursTab />;
  };

  const getSelectedIndex = () => {
    if (tabSelected === 'chatbots') {
      return 0;
    }
    if (tabSelected === 'equipes') {
      return 1;
    }
    if (tabSelected === 'colaboradores') {
      return 2;
    }
    if (tabSelected === 'solicitacoes') {
      return 3;
    }
    return 4;
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1>Painel de Equipe</h1>
          <p>Gerencie os seus chatbots e colaboradores</p>
        </div>
        <TabSwitch
          onSelect={setTabSelected}
          options={TAB_OPTIONS}
          selectedIndex={getSelectedIndex()}
        />
      </div>
      <div>{getTabComponent()}</div>
    </section>
  );
}

export default Manager;

const TAB_OPTIONS: TabItem[] = [
  {
    label: 'Chatbots',
    value: 'chatbots',
    icon: BsChatDots,
  },
  {
    label: 'Equipes',
    value: 'equipes',
    icon: RiTeamFill,
  },
  {
    label: 'Colaboradores',
    value: 'colaboradores',
    icon: BsPersonFill,
  },
  {
    label: 'Solicitações',
    value: 'solicitacoes',
    icon: MdPersonAdd,
  },
  {
    label: 'Horas logadas',
    value: 'horas_logadas',
    icon: BsAlarm,
  },
];
