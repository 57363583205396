import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useModel, useLoading } from '@flux/models/tags/hooks';
import LoadingLabel from '@components/LoadingLabel';
import alert from '@assets/alert.png';
import { Tag } from '@entities';
import TagLine from '../TagLine';
import styles from './styles.module.css';

export interface FilterViewProps {
  selectedTags: string[];
  setSelectedTags: (newList: string[]) => void;
  setTags: (tags: string) => void;
}

interface TagsListProps {
  selected: string[];
  tags: Tag[];
  nonSelected: Tag[];
  setSelectedTags: (newList: string[]) => void;
}

function TagsList({ selected, tags, nonSelected, setSelectedTags } : TagsListProps) {

  const handleRemoveTag = (tagId: string) => {
    setSelectedTags(selected.filter(tag => tag != tagId));
  };

  const handleAddTag = (tagId: string) => {
    setSelectedTags([...selected, tagId]);
  };

  return (
    <div className={styles.tagsList}>
      <div>
        {
          tags.filter(e => selected.includes(e.id)).map((tag) =>
            <TagLine
              key={Math.random()}
              tag={tag}
              hideEdit
              mode="edit"
              onDelete={handleRemoveTag}
            />
          )
        }
      </div>
      {selected.length > 0 && <hr className={styles.separator} />}
      <div>
        {
          nonSelected?.map((tag) => (
            <TagLine
              key={Math.random()}
              tag={tag}
              hideEdit
              mode="add"
              onAdd={handleAddTag}
            />
          ))
        }
      </div>
    </div>
  );
}

function FilterView({ selectedTags, setSelectedTags, setTags } : FilterViewProps) {
  const {
    tags
  } = useModel();
  const loading = useLoading();
  const [shouldAdd, setShouldAdd] = useState(false);
  const [nonSelected, setNonSelected] = useState<Tag[]>([]);
  const [listTags, setListTags] = useState<Tag[]>([]);

  useEffect(() => {
    setNonSelected(tags.filter(tag => !selectedTags?.includes(tag.id)));
    setTags(tags.filter(tag => selectedTags.includes(tag.id)).map(tag => tag.name).join(','));
  }, [selectedTags]);

  return (
    <div className={styles.container}>
      <div className={styles.searchInput}>
        <BsSearch size={20} />
        <input placeholder="Pesquise por tags..." />
      </div>

      <div className={styles.tags}>
        {!loading && tags.length === 0 && (
          <div className={styles.alert}>
            <img src={alert} alt="Alerta" />
            <h2>Não existem tags cadastradas.</h2>
          </div>
        )}
        {loading ? (
          <LoadingLabel size="medium" />
        ) : 
          <TagsList 
            selected={selectedTags} 
            tags={tags} 
            setSelectedTags={setSelectedTags} 
            nonSelected={nonSelected}
          />}
      </div>

    </div>
  );
}

export default FilterView;
