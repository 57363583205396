import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import api from '@services/api';
import {
  incorrectPasswords,
  invalidChangePassword,
} from '@utils/messages.json';
import { errorHandler } from '../settingsPage';

export interface SettingsOptionsPageState {
  error: string;
  successChangePassword: boolean;
  successDeleteAccount: boolean;
  authorized: boolean;
}

interface HandleChangePasswordPayload {
  newPassword: string;
  confirmNewPassword: string;
  oldPassword: string;
}

interface HandleDeleteAccountPayload {
  password: string;
}

export const settingsOptionsPage = createModel<RootModel>()({
  state: {
    error: '',
    successChangePassword: false,
    successDeleteAccount: false,
    authorized: false,
  } as SettingsOptionsPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setSuccessChangePassword(state, payload: boolean) {
      state.successChangePassword = payload;
      return state;
    },

    setSuccessDeleteAccount(state, payload: boolean) {
      state.successDeleteAccount = payload;
      return state;
    },

    setAuthorized(state, payload: boolean) {
      state.authorized = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async handleChangePassword(payload: HandleChangePasswordPayload, state) {
      const {
        setSuccessChangePassword,
        setError,
      } = dispatch.settingsOptionsPage;
      const { newPassword, confirmNewPassword, oldPassword } = payload;
      if (newPassword !== confirmNewPassword) {
        setError(incorrectPasswords);
      } else if (oldPassword === newPassword) {
        setError(invalidChangePassword);
      } else {
        await errorHandler(async () => {
          const stateJSON = localStorage.getItem('persist:root');
          const info = JSON.parse(stateJSON || '');
          const application = JSON.parse(info.application);
          const { user } = application;
          await api.put('/profile/', {
            password: newPassword,
            password_confirmation: confirmNewPassword,
            old_password: oldPassword,
            email: user?.email,
            name: user?.name,
            company: user?.company
          });
          setSuccessChangePassword(true);
        }, setError);
      }
    },

    async handleDeleteAccount(payload: HandleDeleteAccountPayload, state) {
      const {
        setSuccessDeleteAccount,
        setError,
      } = dispatch.settingsOptionsPage;
      const { password } = payload;

      await errorHandler(async () => {
        await api.delete('/users', {
          data: { password },
        });
        setSuccessDeleteAccount(true);
      }, setError);
    },
  }),
});
