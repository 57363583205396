import { FormEvent, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { BsPersonFill, BsFillLockFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { useModel, useLoading } from '@flux/models/pages/registerPage/hooks';
import logo from '@assets/logo.png';
import check from '@assets/check.png';
import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import AlertModal from '@components/AlertModal';
import { Company } from '@entities';
import SearchCompanies from './components/SearchCompanies';
import styles from './styles.module.css';

function Register() {
  const { loading } = useLoading();
  const { success, error, setError, handleRegister } = useModel();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState<Company | null>(null);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      
      if (company) {
        handleRegister({
          name,
          email,
          company_id: company?.id,
          password,
          passwordConfirmation,
        });
      }
    },
    [handleRegister, name, email, company, password, passwordConfirmation]
  );

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <section>
          <div className={styles.formHeader}>
            <img src={logo} alt="Elife" />
            <h1>Inscreva-se</h1>
          </div>

          <div className={styles.formTool}>
            <IoIosArrowBack size={20} color="#4A69BD" />
            <Link to="/login">Voltar</Link>
          </div>

          {success ? (
            <SuccessAlert />
          ) : (
            <form className={styles.form} onSubmit={handleSubmit}>
              <FormInput
                required
                type="text"
                placeholder="Nome completo"
                icon={() => <BsPersonFill color="black" />}
                value={name}
                onChange={(e) => setName(e.target.value.replace(/[^a-zà-úA-ZÀ-Ú ]/g, ''))}
              />
              <FormInput
                required
                type="email"
                placeholder="e-mail"
                icon={() => <MdEmail color="black" />}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className={styles.companySelect}>
                <SearchCompanies
                  onSelect={setCompany}
                />
              </div>
              <FormInput
                required
                type="password"
                placeholder="Senha"
                icon={() => <BsFillLockFill color="black" />}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormInput
                required
                type="password"
                placeholder="Confirmar senha"
                icon={() => <BsFillLockFill color="black" />}
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />

              <SubmitButton
                className={styles.submitButton}
                submit
                label="Cadastrar"
                loading={loading}
              />
              <p>
                Ao clicar em “Cadastrar”, você concorda com os nossos{' '}
                <strong>
                  <a
                    href={process.env.REACT_APP_TERMOS_CONDICOES}
                    target="_blank"
                    rel="noreferrer"
                  >
                    termos e condições de uso.
                  </a>
                </strong>
              </p>
            </form>
          )}
        </section>

        <section className={styles.sideImage}>
          <h1>Gerenciamento de atendimentos dos chatbots</h1>
        </section>
      </div>

      <AlertModal
        isOpen={!!error}
        message={error}
        onClose={() => setError('')}
      />
    </div>
  );
}

export default Register;

function SuccessAlert() {
  const history = useHistory();
  const { setSuccess } = useModel();

  return (
    <div className={styles.success}>
      <img src={check} alt="Cadastro realizado com sucesso!" />
      <p>
        Estamos avaliando o seu cadastro, vamos te enviar um e-mail assim que
        for concluído.
      </p>

      <SubmitButton
        label="OK"
        onClick={() => {
          setSuccess(false);
          history.push('/login');
        }}
      />
    </div>
  );
}
