import { Message, Facebook } from '@entities';
import { dateLabel } from '@utils/strings';
import QuickReplies from '../FbQuickReplies';
import Carousel from '../Carousel';
import styles from './styles.module.css';

// Facebook's Generic Template message.
function Generic({ element }: { element: Facebook.FbGenericElement }) {
  const hasDefaultAction = !!element.default_action;

  return (
    <div className={styles.generic}>
      {hasDefaultAction
        ? (

          <input
            type='image'
            src={element.image_url} 
            alt=''
            onClick={() => window.open(element.default_action!.url, '_blank')}
          /> 

        ) : (element.image_url && <img src={element.image_url} alt='' />)}

      <h4>{element.title}</h4>
      {element.subtitle && <p>{element.subtitle}</p>}

      {element.buttons?.map((b) => (
        <button
          key={JSON.stringify(b)}
          type='button'
          onClick={() => ('url' in b) && window.open(b.url, '_blank')}
          disabled={b.type === 'postback'}
        >
          {b.title}
        </button>
      ))}
    </div>
  );
}

interface MessageProps {
  message: Message;
}

function FbGenericMessage({ message }: MessageProps) {
  const payload = message.body!.payload as Facebook.FbGenericTemplate;
  const source = message.origin === 'user' ? 'messageOrigin' : 'messageSender';

  return (
    <div className={styles[source]}>
      <Carousel>
        {payload.elements.map((el) => (
          <li key={JSON.stringify(el)}>
            <Generic element={el} />
          </li>
        ))}
      </Carousel>

      {message.body?.quick_replies
        && <QuickReplies quick_replies={message.body.quick_replies} />}
      <span>{dateLabel(message.created_at)}</span>
    </div>
  );
}

export default FbGenericMessage;
