import { io } from 'socket.io-client';

export const SEND_CHAT_MESSAGE_EVENT = 'sendMessage';
export const NEW_CHAT_MESSAGE_EVENT = 'message';
export const CHAT_DISCONNECT_EVENT = 'close';
export const ONGOING_CHAT_MSG_EVENT = 'ongoing-message';
export const ONLINE_EVENT = 'online';
export const UPDATE_ONLINE_STATUS_EVENT = 'update-online-status';
export const SEND_TEMPLATE_PAYLOAD = 'template-message-postback';

const socketURL = process.env.REACT_APP_API || '';
const socketReplaceURL = socketURL.replace('/api', '/');

const socket = io(socketReplaceURL, {
  transports: ['websocket'],
  upgrade: false,
});

export default socket;
