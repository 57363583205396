import { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useModel, useLoading } from '@flux/models/tags/hooks';
import LoadingLabel from '@components/LoadingLabel';
import alert from '@assets/alert.png';
import TagLine from '../TagLine';
import styles from './styles.module.css';

function AddView() {
  const {
    tags,
    attendanceTags,
    addAttendanceTag,
    deleteAttendanceTag,
  } = useModel();
  const loading = useLoading();
  const [shouldAdd, setShouldAdd] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.searchInput}>
        <BsSearch size={20} />
        <input placeholder="Pesquise por tags..." />
      </div>

      {shouldAdd ? (
        <div className={styles.tags}>
          {!loading && tags.length === 0 && (
            <div className={styles.alert}>
              <img src={alert} alt="Alerta" />
              <h2>Não existem tags cadastradas.</h2>
            </div>
          )}
          {loading ? (
            <LoadingLabel size="medium" />
          ) : (
            tags.map((tag) => (
              <TagLine
                key={Math.random()}
                tag={tag}
                mode="add"
                onAdd={() => {
                  addAttendanceTag(tag.id);
                  setShouldAdd(false);
                }}
              />
            ))
          )}
        </div>
      ) : (
        <div className={styles.tags}>
          {!loading && attendanceTags.length === 0 && (
            <div className={styles.alert}>
              <img src={alert} alt="Alerta" />
              <h2>Não existem tags nesse atendimento.</h2>
            </div>
          )}
          {loading ? (
            <LoadingLabel size="medium" />
          ) : (
            attendanceTags.map((tag) => (
              <TagLine
                key={Math.random()}
                tag={tag}
                hideEdit
                mode="edit"
                onDelete={deleteAttendanceTag}
              />
            ))
          )}
        </div>
      )}

      <button
        className={styles.newTag}
        type="button"
        onClick={() => setShouldAdd(!shouldAdd)}
      >
        <span>
          {shouldAdd ? 'Ver todas as tags' : 'Adicionar tag ao atendimento'}
        </span>
        {!shouldAdd && <AiFillPlusCircle size={30} color="white" />}
      </button>
    </div>
  );
}

export default AddView;
