/* eslint-disable jsx-a11y/media-has-caption */

import { Message } from '@entities';
import { FaPlay } from 'react-icons/fa';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
  setMedia: (type: string, content: string) => void;
}

function VideoMessage({ message, setMedia }: MessageProps) {
  return (
    <>
      <button 
        type='button'
        onClick={() => {
          setMedia(message.body?.message_type || '', message.body?.media_content || '');
        }}
      >
        <div
          id={`image-message${Math.random()}`}
          className={
            message.origin === 'user'
              ? styles.messageOrigin
              : styles.messageSender
          }
        >
          <video src={message.body?.media_content || ''} />
          <FaPlay size={40} />
          {message.body?.content_caption ? <p> {message.body?.content_caption} </p> : <> </>} 
        </div>
      </button>      
    </>
  );
}

export default VideoMessage;