import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { LoginPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const loginPageState = useSelector<RootState, LoginPageState>(
    (state) => state.loginPage
  );

  return {
    ...dispatch.loginPage,
    ...loginPageState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.models.loginPage
  );

  return loading;
};
