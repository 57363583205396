import { IconType } from 'react-icons';
import Cell from '@pages/Admin/components/Cell';
import { User } from '@entities';
import styles from './styles.module.css';

export interface GradCellsProps<Item> {
  items: Item[];
  onSelect: (item: Item) => void;
  getTitle: (item: Item) => string;
  getDescription: (item: Item) => string;
  getIcons?: (item: Item) => IconType[];
}

function GradCells<Item>({
  items,
  getDescription,
  getIcons,
  getTitle,
  onSelect,
}: GradCellsProps<Item>) {
  return (
    <section className={styles.listItems}>
      {items.map((item) => (
        <Cell
          key={Math.random()}
          title={getTitle(item)}
          description={getDescription(item)}
          onClick={() => onSelect(item)}
          icons={getIcons && getIcons(item)}
        />
      ))}
    </section>
  );
}

export default GradCells;
