import { AiFillPlusCircle } from 'react-icons/ai';
import { RiRobotLine, RiTeamFill } from 'react-icons/all';
import { FormEvent, useEffect, useState } from 'react';
import { Contact } from '@entities';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import { useModel } from '@flux/models/pages/contactsPage/hooks';
import styles from './styles.module.css';

interface UpdateViewProps {
  contacts: Contact[],
  onClose: Function,
  selectedContact?: Contact
}

function UpdateView({ contacts, onClose, selectedContact }: UpdateViewProps) {
  const [name, setName] = useState(selectedContact?.name || '');
  const [phone, setPhone] = useState(selectedContact?.phone || '');
  const { updateContact } = useModel();

  const { getChatbots, chatbots } = AdminHook.useModel();
  const [selectedBot, setSelectedBot] = useState('');

  useEffect(() => {
    setSelectedBot(selectedContact?.bot_id || '');
  }, [selectedContact?.bot_id]);

  useEffect(() => {
    getChatbots(selectedContact?.company || '');
  }, [getChatbots, selectedContact?.company]);

  const handleUpdate = (e: FormEvent) => {
    e.preventDefault();
    if (name && phone && selectedBot) {
      updateContact({ id: selectedContact?.id || '', newName: name, newPhone: phone, newBotId: selectedBot, contacts, onClose });
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.container} onSubmit={handleUpdate}>
        <div className={styles.title}>
          <h1>Editar Contato</h1>
        </div>

        <div className={styles.input}>
          <RiTeamFill size={20} />
          <input
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className={styles.input}>
          <RiTeamFill size={20} />
          <input
            placeholder="Telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className={styles.input}>
          <RiRobotLine size={20} />
          <select
            value={selectedBot}
            onChange={e => setSelectedBot(e.target.value)}
            required={true}
          >
            {chatbots.map(bot =>
              <option key={bot.id} value={bot.id}>{bot.name}</option>
            )}
          </select>
        </div>

        <button className={styles.newTeam} type="submit">
          <span>Editar Contato</span>
          <AiFillPlusCircle size={30} color="white" />
        </button>
      </form>
    </div>
  );
}

export default UpdateView;
