import { CSSProperties } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconType } from 'react-icons';
import * as Application from '@flux/models/application/hooks';
import styles from './styles.module.css';

export interface DashboardItemProps {
  label: string;
  path?: string;
  icon: IconType;
  userTypes: ('administrator' | 'manager' | 'attendant')[];
  onClick?: () => void;
  hidden?: boolean;
  secondary?: boolean;
}

function DashboardItem({
  hidden,
  path,
  onClick,
  label,
  icon: Icon,
  userTypes,
  secondary,
}: DashboardItemProps) {
  const { navActive, navDefault } = styles;
  const { pathname } = useLocation();
  const { user } = Application.useModel();

  return (
    <div
      className={!hidden && path === pathname ? navActive : navDefault}
      hidden={hidden || !userTypes.includes(user?.type || 'attendant')}
    >
      <Link
        onClick={onClick}
        to={path || pathname}
        style={secondary ? secondaryStyle : {}}
      >
        <Icon />
        {label}
      </Link>
    </div>
  );
}

const secondaryStyle: CSSProperties = {
  fontWeight: 'normal',
  fontSize: '14px',
};

export default DashboardItem;
