import { ImSpinner9 } from 'react-icons/im';

interface LoadingSpinnerProps {
  spinSize?: number
}

const LoadingSpinner = ({ spinSize }: LoadingSpinnerProps) => (
  <>
    <ImSpinner9 className="spin" size={spinSize || 15} />
  </>
);

export default LoadingSpinner;