import { useEffect } from 'react';
import DashboardMenu from '@components/DashboardMenu';
import AdminView from '@pages/Admin/AdminView';
import ManagerView from '@pages/Admin/ManagerView';
import { useModel } from '@flux/models/application/hooks';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import LoadingSpinner from '@components/LoadingSpinner';
import styles from './styles.module.css';

function Admin() {
  const { user } = useModel();
  const {
    companies,
    chatbots,
    teams,
    collaborators,
    requests,
    getChatbots,
    getTeams,
    getUsers,
    getCompanies,
  } = AdminHook.useModel();

  const {
    loadingGetUsers,
    loadingGetBots,
    loadingGetRequests
  } = AdminHook.useLoading();

  useEffect(() => {
    let company = '';
    if (user?.type === 'manager') {
      company = user?.company;
    }
    getChatbots(company);
    getTeams(company || '-');
    getUsers(company);
    getCompanies();
  }, [getChatbots, getUsers, getCompanies, user]);

  return (
    <DashboardMenu>
      <div className={styles.content}>
        {user?.type === 'administrator' ? (
          <AdminView
            chatbots={chatbots}
            collaborators={collaborators}
            requests={requests}
            companies={companies}
            user={user}
            teams={teams}
          />
        ) : (
          <ManagerView
            chatbots={chatbots}
            teams={teams}
            collaborators={collaborators}
            requests={requests}
            user={user}
          />
        )}
      </div>
    </DashboardMenu>
  );
}

export default Admin;
