import { useEffect, useState } from 'react';
import { BsPlus, BsArrowLeft } from 'react-icons/bs';
import { GrLanguage } from 'react-icons/gr';
import { RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import SearchInput from '@components/SearchInput';
import GradCells from '@pages/Admin/components/GradCells';
import ChatBotCard from '@pages/Admin/AdminView/components/ChatBotCard';
import AddBot from '@pages/Admin/AdminView/components/AddBot';
import { Chatbot } from '@entities';
import styles from './styles.module.css';

interface ChatbotsTabProps {
  chatbots: Chatbot[];
}

function ChatbotsTab({ chatbots }: ChatbotsTabProps) {
  const [searchTerms, setSearchTerms] = useState('');
  const [selected, setSelected] = useState<Chatbot | null>(null);
  const [showBots, setShowBots] = useState<Chatbot[]>([]);
  const [addBot, setAddBot] = useState(false);

  useEffect(() => {
    if (searchTerms) {
      setShowBots(
        chatbots.filter(
          (bot: Chatbot) =>
            bot.name
              .toLocaleLowerCase()
              .includes(searchTerms.toLocaleLowerCase()) ||
            bot.platform
              .toLocaleLowerCase()
              .includes(searchTerms.toLocaleLowerCase())
        )
      );
    } else {
      setShowBots(chatbots);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  useEffect(() => {
    setShowBots(chatbots);
  }, [chatbots]);

  const toggleSelected = (item: Chatbot) => {
    setSelected(selected?.id === item.id ? null : item);
  };

  return (
    <div>
      <section className={styles.navBarTeam}>
        <h1>Todos os Chatbots</h1>

        <button 
          type="button" 
          onClick={() => {
            setAddBot(!addBot);
          }}
        >
          {addBot ? <BsArrowLeft color="#4a69bd" size={35} /> : <BsPlus color="#4a69bd" size={35} />}
          {addBot ? 'Voltar' : 'Novo Bot'}
        </button>

        {!addBot && <SearchInput
          placeholder="Pesquise por nome e plataformas..."
          type="text"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />}
      </section>

      <section className={styles.chatbotsContainer}>
        {addBot ? 
          <AddBot callback={() => setAddBot(!addBot)} /> :
          <GradCells
            items={showBots}
            getTitle={(chatbot) => chatbot.name}
            getDescription={(chatbot) => `ID: ${chatbot.id}`}
            getIcons={getIcons}
            onSelect={toggleSelected}
          />} 

        {selected && (
          <section className={styles.infoChatbot}>
            <ChatBotCard 
              chatbot={selected} 
              setChatbot={setSelected}
            />
          </section>
        )}
      </section>
    </div>
  );
}

const getIcons = (chatbot: Chatbot) => {
  const icons = [];

  if (chatbot.platform === 'facebook') {
    icons.push(RiFacebookCircleFill);
    return icons;
  }

  if (chatbot.platform === 'whatsapp') {
    icons.push(RiWhatsappFill);
    return icons;
  }

  icons.push(GrLanguage);
  return icons;
};

export default ChatbotsTab;
