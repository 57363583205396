import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { RecoverPasswordPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const recoverPasswordPageState = useSelector<
    RootState,
    RecoverPasswordPageState
  >((state) => state.recoverPasswordPage);

  return {
    ...dispatch.recoverPasswordPage,
    ...recoverPasswordPageState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.recoverPasswordPage.sendNewPassword
  );

  return loading;
};
