import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { ContactPageState } from './entities';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const contactPageState = useSelector<RootState, ContactPageState>(
    (state) => state.contactPage
  );

  return {
    ...dispatch.contactPage,
    ...contactPageState,
  };
};

export const useLoading = () => {
  const loadingContacts = useSelector<RootState, boolean>(
    (state) => state.loading.effects.contactPage.getContacts
  );
  const loadingImportingContacts = useSelector<RootState, boolean>(
    (state) => state.loading.effects.contactPage.importContacts
  );
  const loadingCreateContacts = useSelector<RootState, boolean>(
    (state) => state.loading.effects.contactPage.createContact
  );

  return { loadingContacts, loadingImportingContacts, loadingCreateContacts };
};
