import { Tag } from '@entities';
import { Link } from 'react-router-dom';
import { ImEye } from 'react-icons/im';
import styles from './styles.module.css';

export interface LinkToProps {
  to: string;
  onClick?: () => void;
  tags: Tag[];
}

function LinkTo({ to, onClick, tags }: LinkToProps) {
  return (
    <Link onClick={onClick} className={styles.container} to={to}>
      <ImEye size={20} color="#4a69bd" />
      <span className={styles.tooltiptext}>{tags.map(t => t.name).join(' | ')}</span>
    </Link>
  );
}

export default LinkTo;
