import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

export default function BackButton() {
  return (
    <div className={styles.backButton}>
      <IoIosArrowBack size={20} color="#4A69BD" />
      <Link to="/contatos">Voltar ao painel de contatos</Link>
    </div>
  );
}
