import { FormEvent, useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import * as Application from '@flux/models/application/hooks';
import * as Login from '@flux/models/pages/loginPage/hooks';
import { useModel, useLoading } from '@flux/models/pages/registerPage/hooks';
import logo from '@assets/logo.png';
import check from '@assets/check.png';
import SubmitButton from '@components/SubmitButton';
import AlertModal from '@components/AlertModal';
import { Company } from '@entities';
import SearchCompanies from '@pages/Register/components/SearchCompanies';
import styles from './styles.module.css';

function RegisterGoogle() {
  const { user } = Application.useModel();
  const { loading } = useLoading();
  const { success, error, setError, handleRegister } = useModel();
  const { setRegisterIncomplete } = Login.useModel();

  const [company, setCompany] = useState<Company | null>(null);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (user && company) {
        handleRegister({
          name: user.name,
          email: user.email,
          company_id: company.id,
          password: user.password || '',
          passwordConfirmation: user.password || '',
        });
      } 
    },
    [handleRegister, user, company]
  );

  useEffect(() => {
    if (success) {
      setRegisterIncomplete(false);
    }
  }, [setRegisterIncomplete, success]);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <section>
          <div className={styles.formHeader}>
            <img src={logo} alt="Elife" />
            <p>Para continuar, é necessário que informe a qual 
              empresa pertence.
            </p>
          </div>

          <div className={styles.formTool}>
            <IoIosArrowBack size={20} color="#4A69BD" />
            <Link to="/login">Voltar</Link>
          </div>

          {success ? (
            <SuccessAlert />
          ) : (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.companySelect}>
                <SearchCompanies
                  onSelect={setCompany}
                />
              </div>

              <SubmitButton
                className={styles.submitButton}
                submit
                label="Cadastrar"
                loading={loading}
              />
              <p>
                Ao clicar em “Cadastrar”, você concorda com os nossos{' '}
                <strong>
                  <a
                    href={process.env.REACT_APP_TERMOS_CONDICOES}
                    target="_blank"
                    rel="noreferrer"
                  >
                    termos e condições de uso.
                  </a>
                </strong>
              </p>
            </form>
          )}
        </section>

        <section className={styles.sideImage}>
          <h1>Gerenciamento de atendimentos dos chatbots</h1>
        </section>
      </div>

      <AlertModal
        isOpen={!!error}
        message={error}
        onClose={() => setError('')}
      />
    </div>
  );
}

export default RegisterGoogle;

function SuccessAlert() {
  const history = useHistory();
  const { setSuccess } = useModel();

  return (
    <div className={styles.success}>
      <img src={check} alt="Cadastro realizado com sucesso!" />
      <p>
        Estamos avaliando o seu cadastro, vamos te enviar um e-mail assim que
        for concluído.
      </p>

      <SubmitButton
        label="OK"
        onClick={() => {
          setSuccess(false);
          history.push('/login');
        }}
      />
    </div>
  );
}
