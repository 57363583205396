import { useState } from 'react';
import { BsPencilSquare, BsPlusCircleFill } from 'react-icons/bs';
import { AiFillDelete, AiFillCheckCircle, AiTwotoneTags, AiOutlineInfoCircle } from 'react-icons/ai';
import { Tag } from '@entities';
import styles from './styles.module.css';

type ModeType = 'add' | 'edit' | 'view';
export interface TagLineProps {
  tag: Tag;
  mode: ModeType;
  onAdd?: (tagId: string) => void;
  onEdit?: (newTag: Tag) => void;
  onDelete?: (tagId: string) => void;
  hideEdit?: boolean;
}

function TagLine({
  tag,
  mode,
  onEdit,
  onDelete,
  onAdd,
  hideEdit,
}: TagLineProps) {
  const [shouldEdit, setShouldEdit] = useState(false);
  const [name, setName] = useState(tag.name);
  const [info, setInfo] = useState(false);

  const handleEdit = () => {
    setShouldEdit(!shouldEdit);

    if (onEdit && shouldEdit) {
      onEdit({ ...tag, name });
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(tag.id);
    }
  };

  const handleAdd = () => {
    if (onAdd) {
      onAdd(tag.id);
    }
  };

  const handleShowInfo = () => {
    setInfo(!info);
  };

  return (
    <div className={styles.container}>
      <div className={styles.tagContainer}>
        <AiTwotoneTags size={20} className={`${shouldEdit ? styles.edit : ''}`} />
        <input
          className={`${shouldEdit ? styles.edit : ''}`}
          disabled={!shouldEdit}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TagButtons
          mode={mode}
          hideEdit={hideEdit}
          shouldEdit={shouldEdit}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleShowInfo={handleShowInfo}
        />
      </div>
      {info &&
      <div className={styles.infoSpan}><b>Última Edição:</b> {tag.lastUpdateUserEmail}</div>}
    </div>
  );
}

interface TagButtonsProps {
  mode: ModeType;
  shouldEdit: boolean;
  handleAdd: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleShowInfo: () => void;
  hideEdit?: boolean;
}

function TagButtons({
  mode,
  shouldEdit,
  handleDelete,
  handleEdit,
  handleAdd,
  hideEdit,
  handleShowInfo,
}: TagButtonsProps) {
  if (mode === 'view') return <></>;

  if (mode === 'add')
    return (
      <div className={styles.buttons}>
        <button type="button" onClick={handleAdd}>
          <BsPlusCircleFill size={20} />
        </button>
      </div>
    );

  return (
    <div className={styles.buttons}>
      {!shouldEdit && (
        <button type="button" onClick={handleShowInfo}>
          <AiOutlineInfoCircle size={20} />
        </button>
      )}

      <button hidden={hideEdit} type="button" onClick={handleEdit}>
        {shouldEdit ? (
          <AiFillCheckCircle color="#1a9b05" size={20} />
        ) : (
          <BsPencilSquare size={20} />
        )}
      </button>

      <button type="button" onClick={handleDelete}>
        <AiFillDelete size={20} />
      </button>
    </div>
  );
}

export default TagLine;
