import styles from './styles.module.css';

export interface HeaderProps {
  title: string;
  description: string;
}

function Header({ title, description }: HeaderProps) {
  return (
    <div className={styles.header}>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
}

export default Header;
