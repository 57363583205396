
import { Message } from '@entities';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
  setMedia: (type: string, content: string) => void;
}

function ImageMessage({ message, setMedia }: MessageProps) {
  return (
    <>
      <button 
        type='button'
        onClick={() => {
          setMedia(message.body?.message_type || '', message.body?.media_content || '');
        }}
      >
        <div
          id={`image-message${Math.random()}`}
          className={
            message.origin === 'user'
              ? styles.messageOrigin
              : styles.messageSender
          }
        >
          <img src={message.body?.media_content || ''} alt='IMG' />
          {message.body?.content_caption ? <p> {message.body?.content_caption} </p> : <> </>} 
        </div>
      </button>      
    </>
  );
}

export default ImageMessage;