import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { SettingsPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const settingsPageState = useSelector<RootState, SettingsPageState>(
    (state) => state.settingsPage
  );

  return {
    ...dispatch.settingsPage,
    ...settingsPageState,
  };
};

export const useLoading = () => {
  const loadingSendEmail = useSelector<RootState, boolean>(
    (state) => state.loading.effects.settingsPage.handleSendEmail
  );

  const loadingCheckPIN = useSelector<RootState, boolean>(
    (state) => state.loading.effects.settingsPage.handleVerifyPIN
  );

  return { loadingSendEmail, loadingCheckPIN };
};
