import {
    BarChart,
    Bar,
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    YAxis,
    LabelList,
    Tooltip,
} from 'recharts';

function Chart({ data }: { data: { attendant_name: string, attendant_id: string, company: string, hours: number }[] }) {
    const newData = data.map(dt => ({ name: dt.attendant_name, horas: Math.floor(dt.hours) }));
    if (newData.length) newData.sort((a, b)=> { if (a.name > b.name) return 1; return -1 ; });
    return (
      <ResponsiveContainer width="100%" height="95%">
        <BarChart
          width={150}
          height={40}
          data={newData}
        >
          <CartesianGrid vertical={false} strokeDasharray="3" stroke="#000000" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="horas" barSize={35} fill="#4A69BD">
            <LabelList dataKey="horas" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
}

export default Chart;
