import { useCallback, useState, FormEvent } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';
import Modal from '@components/Modal';
import { Period } from '../../periods';
import styles from './styles.module.css';

export interface PersonalizedModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (period: Period) => void;
}

function PersonalizedModal({
  isOpen,
  onClose,
  onSelect,
}: PersonalizedModalProps) {
  const [since, setSince] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (since && until) {
        const untilDate = DateTime.fromJSDate(until).endOf('day').toJSDate();

        onSelect({ since, until: untilDate });
        onClose();
      }
    },
    [since, until, onSelect, onClose]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <form className={styles.container} onSubmit={handleSubmit}>
        <header className={styles.header}>
          <div>
            <FaCalendarAlt size={30} color="#4a69bd" />
            <h1>Intervalo personalizado</h1>
          </div>

          <button type="button" onClick={onClose}>
            <AiFillCloseCircle size={30} color="#949494dc" />
          </button>
        </header>

        <div>
          <div className={styles.date}>
            <span>Data de início</span>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={since}
              onChange={(date: Date) => setSince(date)}
              selectsStart
              startDate={since}
              endDate={until}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"

            />
          </div>

          <div className={styles.date}>
            <span>Data de término</span>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={until}
              onChange={(date: Date) => setUntil(date)}
              selectsEnd
              startDate={since}
              endDate={until}
              minDate={since}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>

        <button className={styles.submit} type="submit">
          <AiFillCheckCircle color="white" size={30} />
          Confirmar intervalo
        </button>
      </form>
    </Modal>
  );
}

export default PersonalizedModal;
