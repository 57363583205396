import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { ChatPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const chatPageState = useSelector<RootState, ChatPageState>(
    (state) => state.chatPage
  );

  return {
    ...dispatch.chatPage,
    ...chatPageState,
  };
};

export const useLoading = () => {
  const loadingSendEmail = useSelector<RootState, boolean>(
    (state) => state.loading.effects.chatPage.sendEmail
  );

  const loadingReactivationMessage = useSelector<RootState, boolean>(
    (state) => state.loading.effects.chatPage.getReactivationMessage
  );

  return { loadingSendEmail, loadingReactivationMessage };

};