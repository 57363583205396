import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import { FormEvent, useState } from 'react';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import SearchCompanies from '@pages/Register/components/SearchCompanies';
import { Company } from '@entities';
import styles from './styles.module.css';

interface AddCompanyProps {
  callback: () => void;
}

function AddBot({ callback }: AddCompanyProps) {

  const [nome, setNome] = useState('');
  const [url, setUrl] = useState('');
  const [plataform, setPlataform] = useState('');
  const [plataformId, setPlataformId] = useState('');
  const [company, setCompany] = useState<Company | null>(null);

  const { addChatbot } = AdminHook.useModel();
  const { loadingAddBot } = AdminHook.useLoading();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (nome && url && plataform && plataformId && company) {
      await addChatbot({
        nome,
        company,
        url,
        plataform,
        plataformId
      });
      callback();
    }
  };

  return (
    <div className={styles.containerAddBot}>
      <section>
        <div className={styles.formHeader}>
          <h1>Adicionar Chatbot</h1>
        </div>
        <form className={styles.form}>
          <FormInput
            required
            type="text"
            placeholder="Nome do Chatbot"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <FormInput
            required
            type="text"
            placeholder="URL do Chatbot"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />

          <div className={styles.plataformSelect}>
            <p>Plataforma</p>
            <select name='plataform' id='plataform-select' value={plataform} onChange={(e) => setPlataform(e.target.value)}>
              <option value=''>Selecionar</option>
              <option value='whatsapp'>WhatsApp</option>
              <option value='facebook'>Facebook</option>
              <option value='web'>Web</option>
            </select>
          </div>

          <FormInput
            required
            type="text"
            placeholder="ID da Plataforma"
            value={plataformId}
            onChange={(e) => setPlataformId(e.target.value)}
          />

          <div className={styles.companySelect}>
            <SearchCompanies
              onSelect={setCompany}
            />
          </div>
          

          <SubmitButton
            className={styles.submitButton}
            submit
            label="Adicionar"
            loading={loadingAddBot}
            onClick={(e) => handleSubmit(e)}
          />
        </form>
      </section>
    </div>
  );
}

export default AddBot;