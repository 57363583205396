import { Team } from '@entities';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';

interface RemoveViewProps {
  teams: Team[],
  selectedTeam?: Team,
  onClose: Function
}

function RemoveView({ teams, selectedTeam, onClose }: RemoveViewProps) {
  const { removeTeam } = useModel();

  const handleNo = () => {
    onClose();
  };

  const handleYes = () => {
    removeTeam({ id: selectedTeam?.id || '', teams });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Quer remover a equipe {selectedTeam?.name}?</h1>
        <div className={styles.buttonsContainer}>
          <button className={styles.buttonYes} type="button" onClick={handleYes}>Sim</button>
          <button className={styles.buttonNo} type="button" onClick={handleNo}>Não</button>
        </div>
      </div>
    </div>
  );
}

export default RemoveView;
