import { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TiGroup } from 'react-icons/ti';
import { AiFillBook, AiTwotoneTags } from 'react-icons/ai';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { RiHistoryFill, RiFileDownloadFill, RiContactsLine, RiMessage3Line } from 'react-icons/ri';
import { BsGraphUp } from 'react-icons/bs';
import * as Application from '@flux/models/application/hooks';
import * as HistoryPage from '@flux/models/pages/historyPage/hooks';
import * as StatisticsPage from '@flux/models/pages/statisticsPage/hooks';
import socket, {
  ONLINE_EVENT,
} from '@services/socket';
import logo from '@assets/logo.png';
import TagsModal from '@components/TagsModal';
import QuickReplyModal from '@components/QRModal';
import DropDown from './components/DropDown';
import DashboardItem from './components/DashboardItem';
import styles from './styles.module.css';

const domToPdf = require('dom-to-pdf');

export interface DashboardMenuProps {
  children: ReactNode;
}

function DashboardMenu({ children }: DashboardMenuProps) {
  const { user } = Application.useModel();
  const { exportHistory } = HistoryPage.useModel();
  const { exportStatistics } = StatisticsPage.useModel();
  const { pathname } = useLocation();

  const [showTags, setShowTags] = useState(false);
  const [showQRS, setShowQRS] = useState(false);

  useEffect(() => {
    socket.emit(ONLINE_EVENT, { userId: user?.id });
  }, []);

  const StatisticsToPDF = () => {
    const element = document.getElementById('root');
    const options = {
      filename: 'statistics.pdf',
      excludeTagNames: [ 'nav', 'header' ],
    };
    domToPdf(element, options, (pdf: {}) => {
      console.log('done');
      console.log(pdf);
    });
  };

  return (
    <div className={styles.background}>
      <header className={styles.header}>
        <Link to="/login">
          <img src={logo} alt="Elife" />
        </Link>

        <DropDown />
      </header>

      <nav className={styles.nav}>
        <DashboardItem
          label={user?.type === 'manager' ? 'Equipe' : 'Admin'}
          icon={TiGroup}
          path="/admin"
          userTypes={['manager', 'administrator']}
        />

        <DashboardItem
          label="Atendimentos"
          icon={HiOutlineChatAlt2}
          path="/atendimentos"
          userTypes={['attendant', 'manager']}
        />

        <DashboardItem
          label="Histórico"
          icon={RiHistoryFill}
          path="/historico"
          userTypes={['attendant', 'manager', 'administrator']}
        />

        <DashboardItem
          label="Disparo Ativo"
          icon={RiMessage3Line}
          path="/disparo-ativo"
          userTypes={['attendant', 'manager', 'administrator']}
        />
        <DashboardItem
          label="Contatos"
          icon={RiContactsLine}
          path="/contatos"
          userTypes={['manager', 'administrator']}
        />

        <DashboardItem
          label="Estatísticas"
          icon={BsGraphUp}
          path="/estatisticas"
          userTypes={['manager', 'administrator']}
        />

        <DashboardItem
          label="Tags"
          hidden={pathname.startsWith('/atendimentos/')}
          icon={AiTwotoneTags}
          onClick={() => setShowTags(true)}
          userTypes={[ 'attendant', 'manager']}
        />

        <DashboardItem
          label="Respostas Rápidas"
          hidden={pathname.startsWith('/atendimentos/')}
          icon={AiFillBook}
          onClick={() => setShowQRS(true)}
          userTypes={['attendant', 'manager', 'administrator']}
        />

        <div className={styles.line} />

        <DashboardItem
          label="Exportar Histórico"
          onClick={exportHistory}
          icon={RiFileDownloadFill}
          hidden={pathname !== '/historico'}
          userTypes={['attendant', 'manager', 'administrator']}
          secondary
        />

        <DashboardItem
          label="Exportar Relatório"
          onClick={StatisticsToPDF}
          icon={RiFileDownloadFill}
          hidden={pathname !== '/estatisticas'}
          userTypes={['attendant', 'manager', 'administrator']}
          secondary
        />

        <DashboardItem
          label="Exportar Equipe"
          icon={RiFileDownloadFill}
          hidden={pathname !== '/admin'}
          userTypes={['attendant', 'manager', 'administrator']}
          secondary
        />
      </nav>

      <main>{children}</main>

      <TagsModal isOpen={showTags} onClose={() => setShowTags(false)} />
      <QuickReplyModal isOpen={showQRS} onClose={() => setShowQRS(false)} />
    </div>
  );
}

export default DashboardMenu;
