import { Contact } from '@entities';
import { useModel } from '@flux/models/pages/contactsPage/hooks';
import styles from './styles.module.css';

interface RemoveViewProps {
  contacts: Contact[],
  selectedContact?: Contact,
  onClose: Function
}

function RemoveView({ contacts, selectedContact, onClose }: RemoveViewProps) {
  const { removeContact } = useModel();

  const handleNo = () => {
    onClose();
  };

  const handleYes = () => {
    removeContact({ id: selectedContact?.id || '', contacts });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Quer remover o contato {selectedContact?.name}?</h1>
        <div className={styles.buttonsContainer}>
          <button className={styles.buttonYes} type="button" onClick={handleYes}>Sim</button>
          <button className={styles.buttonNo} type="button" onClick={handleNo}>Não</button>
        </div>
      </div>
    </div>
  );
}

export default RemoveView;
