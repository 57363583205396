/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Message, Media } from '@entities';
import { useModel, useLoading } from '@flux/models/pages/chatPage/hooks';
import { AiFillBook } from 'react-icons/ai';
import { RiMailFill, RiPriceTag3Line, RiShutDownLine, RiExchangeFill, RiRestartLine } from 'react-icons/ri';
import socket, { NEW_CHAT_MESSAGE_EVENT, ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import { newMessageNotification } from '@utils/notifications';
import DashboardMenu from '@components/DashboardMenu';
import SearchPicker from '@components/SearchPicker';
import TagsModal from '@components/TagsModal';
import AlertModal from '@components/AlertModal';
import LoadingSpinner from '@components/LoadingSpinner';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import { AttendantInfo, ChatInfo } from './components/Header';
import BackButton from './components/BackButton';
import Messages from './components/Messages';
import InputMessage from './components/InputMessage';
import GetEmailModal from './components/GetEmailModal';
import ConfirmReactivationModal from './components/ConfirmReactivationModal';
import MediaModal from './components/MediaModal';
import styles from './styles.module.css';
import QRModal from './components/QRModal';

interface ChatParams {
  protocol: string;
}

const REGEX_EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Chat() {
  const { protocol } = useParams<ChatParams>();
  const {
    attendance,
    messages,
    getAttendanceInfo,
    finishChat,
    sendMessage,
    sendTemplateMessage,
    addMessage,
    setMessages,
    sendEmail,
    setEmailSuccess,
    emailSuccess,
    reactivationMessage,
    error,
    setError,
    transferAttendance,
    quickReplies,
    getQuickReplies
  } = useModel();

  const defaultMedia = { media_content: '', message_type: 'TEXT' } as Media;

  const { loadingSendEmail } = useLoading();

  const [showTags, setShowTags] = useState(false);
  const [isAttending, setIsAttending] = useState(false);
  const [platform, setPlatform] = useState('');
  const [open, setOpen] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [reactivation, setReactivation] = useState('');
  const [reactivationError, setReactivationError] = useState('');
  const [media, setMedia] = useState<Media>(defaultMedia);
  const [sameChat, setSameChat] = useState(false);
  const [newAttendant, setNewAttendant] = useState('');
  const [QRSuggestion, setQRSuggestion] = useState('');
  const [QRModalOpen, setQRModalOpen] = useState(false);

  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, restartCountMessages, current } = Attendance.useModel();
  const firstUpdate = useRef(true);

  useEffect(() => {
    getAttendanceInfo(protocol);
  }, [getAttendanceInfo, protocol]);

  useEffect(() => {
    if (!firstUpdate.current && !sameChat) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
    if (sameChat == true) setSameChat(false);
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (attendance) {
      setPlatform(attendance.platform);
      setIsAttending(!attendance.closed_at);
      setSameChat(true);
      restartCountMessages(attendance.user_id);
      socket.emit('room', attendance.user_id);
      // eslint-disable-next-line no-console
      console.log('room emit');
    }
  }, [attendance]);

  useEffect(()=>{
    if (!firstUpdate.current)
      transferAttendance({ attendant_id: newAttendant, protocol: attendance?.protocol || 0 });
  }, [newAttendant]);

  useEffect(() => {
    socket.on(NEW_CHAT_MESSAGE_EVENT, (message: Message) => {
      // eslint-disable-next-line no-console
      console.log('Message arrived', new Date().toISOString(), message);

      if (message.origin !== 'attendants') addMessage(message);
    });
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => {
      if (msg.user_id != attendance?.user_id){
        console.log(msg);
        getMessagesNotReadCounter(msg.user_id || '');
      }
    });

    return () => {
      socket.off(NEW_CHAT_MESSAGE_EVENT);
      socket.off(ONGOING_CHAT_MSG_EVENT);
      setMessages([]);
      localStorage.setItem(`${protocol}`, new Date().toLocaleString());
    };
  }, [addMessage, setMessages, protocol]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    getQuickReplies();
  }, []);

  const handleSendEmail = async () => {
    if (email) {
      // validaEmail
      if (email.match(REGEX_EMAIL_PATTERN)) {
        setOpen('');
        setEmail('');
        await sendEmail({
          protocol,
          email,
        });
      } else {
        setEmailError('email invalido');
      }

    } else if (attendance?.user_email) {
      await sendEmail({
        protocol,
        email: attendance.user_email,
      });
    } else {
      setOpen('ABRIR');
    }
  };

  const handleReactivation = async () => {
    if (reactivation) {
      setReactivation('');
      sendTemplateMessage(reactivationMessage);
    } else {
      setReactivation('ABRIR');
    }
  };

  const handleSetMedia = (type: string, content: string) => {
    const media_ = {
      message_type: type,
      media_content: content
    } as Media;
    setMedia(media_);
  };

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.backContainer}>
            <BackButton />
          </section>

          <section className={styles.infoContainer}>
            <AttendantInfo attendantName={attendance?.user_name || ''} attendantNumber={attendance?.user_id || ''} />
            <ChatInfo
              protocol={Number(protocol)}
              platform={platform}
              date={new Date(attendance?.opened_at || '').toLocaleString('pt-BR')}
            />
          </section>

          <section className={styles.chatContainer}>
            <Messages messages={messages} setMedia={handleSetMedia} />
            <InputMessage
              enabled={isAttending}
              onSubmit={(message) => sendMessage(message)}
              QRSuggestion={QRSuggestion}
            />
          </section>

          <div className={styles.options}>
            <button
              type="button"
              className={styles.optionItem}
              onClick={() => setQRModalOpen(true)}
            >
              <AiFillBook className={styles.icons} size={24} />
              <h3>Respostas Rápidas</h3>
            </button>

            <button
              type="button"
              className={styles.optionItem}
              onClick={handleSendEmail}
            >
              <RiMailFill className={styles.icons} size={24} />
              {loadingSendEmail ? <LoadingSpinner /> :
              <h3>Enviar por e-mail</h3>}
            </button>
            <button
              type="button"
              className={styles.optionItem}
              onClick={() => setShowTags(true)}
            >
              <RiPriceTag3Line className={styles.icons} size={24} />
              <h3>Tags</h3>
            </button>

            <div className={styles.searchAttendItem}>
              <RiExchangeFill className={styles.icons} size={24} />
              <SearchPicker
                mode="attendants"
                label="Transferir"
                onSelect={setNewAttendant}
                isChat={true}
              />
            </div>

            {
              reactivationMessage && reactivationMessage !== '' &&
              <button
                type="button"
                className={styles.reactivateItem}
                onClick={handleReactivation}
              >
                <RiRestartLine className={styles.icons} size={24} />
                <h3>Reativar</h3>
              </button>
            }
            
            <button
              type="button"
              className={styles.finishItem}
              onClick={() => finishChat(protocol)}
            >
              <RiShutDownLine className={styles.icons} size={24} />
              <h3>Finalizar</h3>
            </button>

          </div>
        </div>

        <TagsModal
          isOpen={showTags}
          onClose={() => setShowTags(false)}
          protocol={protocol}
        />

        <AlertModal
          isOpen={emailSuccess}
          onClose={() => setEmailSuccess(false)}
          success
          message="E-mail enviado com sucesso!"
        />

        <AlertModal
          isOpen={!!error}
          onClose={() => setError('')}
          message={error}
        />

        <GetEmailModal
          isOpen={!!open}
          message={open}
          onClose={() => {
            setOpen('');
            setEmail('');
          }}
          confirm={handleSendEmail}
          setEmail={setEmail}
          error={emailError}
          setError={setEmailError}
        />

        
        <ConfirmReactivationModal
          isOpen={!!reactivation}
          message={reactivationMessage}
          onClose={() => {
            setReactivation('');
          }}
          confirm={handleReactivation}
          error={reactivationError}
          setError={setReactivationError}
        />


        <MediaModal
          isOpen={!!media && !!media.media_content}
          onClose={() => {
            setMedia(defaultMedia);
          }}
          media={media}
        />

        <QRModal
          isOpen={QRModalOpen}
          onClose={() => {
            setQRModalOpen(false);
          }}
          quickReplies={quickReplies} 
          setQRSuggestion={setQRSuggestion}
        />
      </DashboardMenu>
    </div>
  );
}

export default Chat;