import { Team } from '@entities';
import { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import TeamCard from '@pages/Admin/ManagerView/components/TeamsTab/components/TeamCard';
import TeamModal from './components/TeamModal';
import styles from './styles.module.css';

interface TeamsTabProps {
  teams: Team[];
}

function TeamsTab({ teams }: TeamsTabProps) {
  const [showTeamsModal, setShowTeamsModal] = useState(false);

  const handleCreate = () => {
    setShowTeamsModal(true);
  };

  return (
    <>
      <div>
        <section className={styles.navBarTeam}>
          <h1>Todas as Equipes</h1>
          <button type="button" onClick={handleCreate}>
            <BsPlus size={35} />
            Nova Equipe
          </button>
        </section>
        <section className={styles.teamsContainer}>
          {teams.map((item) => (
            <TeamCard key={item.id} team={item} />
          ))}
        </section>
      </div>
      <TeamModal isOpen={showTeamsModal} onClose={() => setShowTeamsModal(false)} teams={teams} />
    </>
  );
}
export default TeamsTab;
