import { useEffect, useState } from 'react';
import { FaAngleDown, FaBuilding } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { limitStringSize } from '@utils/strings';
import { useLoading, useModel } from '@flux/models/pages/registerPage/hooks';
import Modal from '@components/Modal';
import SearchInput from '@components/SearchInput';
import LoadingLabel from '@components/LoadingLabel';
import { Company } from '@entities';
import styles from './styles.module.css';

export interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (selected: Company) => void;
}

function SearchModal({
  isOpen,
  onClose,
  onSelect,
}: SearchModalProps) {
  const { companies, getCompanies } = useModel();
  const [busca, setBusca] = useState('');
  const [showCompanies, setShowCompanies] = useState<Company[]>([]);
  const { getCompaniesLoading } = useLoading();

  useEffect(() => {
    if (busca) {
      setShowCompanies(
        companies.filter(
          (company: Company) =>
          company.fullname.toLocaleLowerCase()
          .includes(busca.toLocaleLowerCase())
        )
      );
    } else {
      setShowCompanies(companies);
    }
  }, [busca, companies]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    setShowCompanies(companies);
  }, [companies]);

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onClose()}>
      <header className={styles.header}>
        <h1>Empresas</h1>
        <button type="button" onClick={onClose}>
          <AiFillCloseCircle size={30} color="#999" />
        </button>
      </header>

      <div className={styles.inputWrapper}>
        <SearchInput
          containerWidth="100%"
          placeholder="Pesquise pelo nome..."
          onChange={(e) => {
            setBusca(e.target.value);
          }}
          value={busca}
        />
      </div>

      <div className={styles.items}>
        {getCompaniesLoading ? (
          <div className={styles.loading}>
            <LoadingLabel size="medium" />
          </div>
        ) : (
          showCompanies.map((item) => (
            <button
              onClick={() => {
                onSelect(item);
                onClose();
              }}
              type="button"
              key={Math.random()}
              className={styles.resultItem}
            >
              <p>
                <strong>Nome:</strong> {item.fullname}
              </p>
            </button>
          ))
        )}
      </div>
    </Modal>
  );
}

export interface SearchPickerProps {
  onSelect: (selected: Company) => void;
}

function SearchCompanies({ onSelect }: SearchPickerProps) {
  const [selected, setSelected] = useState<Company | null>(null);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FaBuilding size={15} />
      <button
        type="button"
        className={styles.container}
        onClick={() => setShowModal(true)}
      >
        <p>Empresa</p>

        <span className={styles.current}>
          <p>{selected ? limitStringSize(selected.company, 9) : 'Selecione'}</p>
          <FaAngleDown size={20} />
        </span>
      </button>

      <SearchModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSelect={(comp) => {
          setSelected(comp);
          onSelect(comp);
        }}
      />
    </>
  );
}

export default SearchCompanies;
