import styles from './FbQuickReplies.module.css';

interface Props {
  quick_replies: string[];
}

function QuickReply({ quick_replies }: Props) {
  return (
    <div className={styles.qrContainer}>
      {quick_replies!.map((qr: string) => (
        <span key={Math.random()} className={styles.qr}>
          {qr}
        </span>
      ))}
    </div>
  );
}

export default QuickReply;
