import { useRef } from 'react';
import { MdKeyboardArrowLeft as Larrow, MdKeyboardArrowRight as Rarrow } from 'react-icons/md';
import styles from './styles.module.css';

interface CarouselProps {
  children: React.ReactNode;
  className?: string;
}

function Carousel({ children, className = '' }: CarouselProps) {
  const elements = Array.isArray(children) ? children : [children];
  const carouselRef = useRef((null as unknown) as HTMLUListElement);

  function handleScroll(dir: 'left' | 'right') {
    if (!carouselRef?.current) return;

    const el = carouselRef.current;
    const once = el.scrollWidth / el.childElementCount;
    el.scrollBy({
      left: once * (dir === 'left' ? -1 : 1),
      behavior: 'smooth',
    });
  }

  return (
    <div className={styles.container}>
      {(elements.length > 1) &&
      <menu className={styles.scroller}>
        <button
          type='button'
          onClick={() => handleScroll('left')}
          aria-label='left'
          className={styles.left}
        >
          <Larrow />
        </button>

        <button
          type='button'
          onClick={() => handleScroll('right')}
          aria-label='right'
          className={styles.right}
        >
          <Rarrow />
        </button>
      </menu>}

      <ul
        className={`${styles.carousel} ${className}`}
        ref={carouselRef}
      >
        {children}
      </ul>
    </div>
  );
}

export default Carousel;
