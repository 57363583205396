import csv from 'csvtojson';

async function readFile(file: File): Promise<string> {
  if (file) {
    const content = await file.text();
    return content;
  }
  return '';
}

export async function csvToJson(csvFile: File){
  const json = await csv().fromString(await readFile(csvFile));
  return json;
}
