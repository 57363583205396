import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styles from './styles.module.css';

export interface LoadingLabelProps {
  size?: 'small' | 'medium' | 'large';
}

function LoadingLabel({ size = 'medium' }: LoadingLabelProps) {
  const { fontSize, iconSize } = loadingStyles[size];

  return (
    <div className={styles.container}>
      <AiOutlineLoading3Quarters
        className="spin"
        size={iconSize}
        color="#4a69bd"
      />
      <h1 style={{ fontSize }}>Carregando</h1>
    </div>
  );
}

const loadingStyles = {
  small: {
    fontSize: '14px',
    iconSize: 20,
  },
  medium: {
    fontSize: '22px',
    iconSize: 40,
  },
  large: {
    fontSize: '32px',
    iconSize: 60,
  },
};

export default LoadingLabel;
