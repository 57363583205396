import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { AdminPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const adminPageState = useSelector<RootState, AdminPageState>(
    (state) => state.adminPage
  );

  return {
    ...dispatch.adminPage,
    ...adminPageState,
  };
};

export const useLoading = () => {
  const loadingToogleAvailable = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.toogleAvailable
  );

  const loadingAcceptReject = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.resolveRequest
  );

  const loadingUpdateBots = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.updateBots
  );

  const loadingUpdateTeams = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.updateTeams
  );

  const loadingRemoveUser = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.removeUser
  );

  const loadingMakeManager = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.makeManager
  );

  const loadingMakeAdmin = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.makeAdmin
  );

  const loadingDeleteBot = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.deleteBot
  );

  const loadingDeactivateUser = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.deactivateUser
  );

  const loadingGetBots = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.getChatbots
  );

  const loadingGetUsers = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.getUsers
  );

  const loadingGetRequests = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.getRequests
  );

  const loadingGetCompanies = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.getCompanies
  );

  const loadingAddCompany = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.addCompany
  );

  const loadingAddBot = useSelector<RootState, boolean>(
    (state) => state.loading.effects.adminPage.addChatbot
  );

  return {
    loadingToogleAvailable,
    loadingAcceptReject,
    loadingUpdateBots,
    loadingUpdateTeams,
    loadingRemoveUser,
    loadingDeleteBot,
    loadingMakeManager,
    loadingMakeAdmin,
    loadingDeactivateUser,
    loadingGetBots,
    loadingGetUsers,
    loadingGetRequests,
    loadingGetCompanies,
    loadingAddCompany,
    loadingAddBot
  };
};
