import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import { FormEvent, useState } from 'react';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';

interface AddCompanyProps {
  callback: () => void;
}

function AddCompany({ callback }: AddCompanyProps) {

  const [nome, setNome] = useState('');
  const [sigla, setSigla] = useState('');

  const { addCompany } = AdminHook.useModel();
  const { loadingAddCompany } = AdminHook.useLoading();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (nome && sigla) {
      await addCompany({
        nome,
        sigla
      });
      callback();
    }
  };

  const handleSetSigla = (value: string) => {
    const last = value.slice(-1);
    if (/^[A-Z]/.test(last.toUpperCase()) || last === '') {
      setSigla(value.toUpperCase());
    }
  };

  return (
    <div className={styles.containerAddCompany}>
      <section>
        <div className={styles.formHeader}>
          <h1>Adicionar Empresa</h1>
        </div>
        <form className={styles.form}>
          <FormInput
            required
            type="text"
            placeholder="Nome da Empresa"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <FormInput
            required
            type="text"
            placeholder="Sigla da Empresa"
            value={sigla}
            onChange={(e) => handleSetSigla(e.target.value)}
            
          />

          <SubmitButton
            className={styles.submitButton}
            submit
            label="Adicionar"
            loading={loadingAddCompany}
            onClick={(e) => handleSubmit(e)}
          />
        </form>
      </section>
    </div>
  );
}

export default AddCompany;