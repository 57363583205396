import { useEffect, useState, useCallback, FormEvent, MouseEvent } from 'react';
import queryString from 'query-string';
import { AiFillGoogleCircle } from 'react-icons/ai';
import { BsFillLockFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { useHistory, Link, useLocation } from 'react-router-dom';
import * as Application from '@flux/models/application/hooks';
import { useModel, useLoading } from '@flux/models/pages/loginPage/hooks';
import logo from '@assets/logo.png';
import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import AlertModal from '@components/AlertModal';
import styles from './styles.module.css';

function Login() {
  const history = useHistory();
  const location = useLocation();

  const { user } = Application.useModel();
  const loading = useLoading();
  const {
    error,
    setError,
    success,
    handleGoogle,
    handleLogin,
    sendGoogleCode,
    registerIncomplete,
  } = useModel();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (success) {
      history.push(user?.type === 'administrator' ? '/admin' : '/atendimentos');
    }
  }, [success, history, user]);

  useEffect(() => {
    if (!registerIncomplete) {
      const searchParams = queryString.parse(location.search);
  
      if (searchParams.code) {
        sendGoogleCode(String(searchParams.code));
      }
    }
  }, [location.search, sendGoogleCode, registerIncomplete]);

  useEffect(() => {
    if (registerIncomplete) {
      history.push('cadastrar-google');
    }
  }, [registerIncomplete, history]);

  const submitLogin = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleLogin({ email, password });
    },
    [email, password, handleLogin]
  );

  const submitGoogle = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      handleGoogle();
    },
    [handleGoogle]
  );

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <section className={styles.sideImage}>
          <h1>Gerenciamento de atendimentos dos chatbots</h1>
        </section>
        <section>
          <div className={styles.formHeader}>
            <img src={logo} alt="Elife" />
            <h1>Inicie sua sessão</h1>
            <h2>Para entrar, informe os seus dados de acesso.</h2>
          </div>

          <form className={styles.form} onSubmit={submitLogin}>
            <FormInput
              type="text"
              name="email"
              placeholder="e-mail"
              icon={() => <MdEmail color="black" />}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
            <FormInput
              type="password"
              name="password"
              placeholder="Senha"
              icon={() => <BsFillLockFill color="black" />}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <p>
              Esqueceu a senha?{' '}
              <button type="button">
                <strong>
                  <Link to="/enviar-email">Redefinir senha</Link>
                </strong>
              </button>
            </p>

            <SubmitButton
              submit
              label="Entrar"
              className={styles.submitButton}
              loading={loading}
            />

            <SubmitButton
              label="Entrar com conta Google"
              iconSize={30}
              onClick={submitGoogle}
              backgroundColor="#c7c7c7"
              fontColor="#000"
              icon={AiFillGoogleCircle}
              className={styles.googleButton}
            />
            <p style={{ textAlign: 'center' }}>
              Ao clicar em “Entrar”, você concorda com os nossos{' '}
              <button type="button">
                <strong>
                  <a
                    href={process.env.REACT_APP_TERMOS_CONDICOES}
                    target="_blank"
                    rel="noreferrer"
                  >
                    termos e condições de uso
                  </a>
                </strong>
                .
              </button>
            </p>
            <h2>Ainda não tem uma conta?</h2>
            <SubmitButton
              label="Cadastrar"
              onClick={() => history.push('/cadastrar')}
              backgroundColor="#475884"
              fontColor="#fff"
              className={styles.registerButton}
            />
          </form>
        </section>
      </div>

      <AlertModal
        isOpen={!!error}
        message={error}
        onClose={() => setError('')}
      />
    </div>
  );
}

export default Login;
