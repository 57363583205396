import ReactDatePicker from 'react-datepicker';
import styles from './styles.module.css';

interface DatePickerProps {
  label: string;
  selected: Date | null;
  onSelect: (date: Date | null) => void;
}

function DatePicker({ label, selected, onSelect }: DatePickerProps) {
  return (
    <div className={styles.container}>
      <p>{label}</p>

      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        selected={selected}
        onChange={onSelect}
        isClearable
        placeholderText="Qualquer"
        popperPlacement="left"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
}

export default DatePicker;
