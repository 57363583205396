
import { Message } from '@entities';
import { FcDocument } from 'react-icons/fc';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
}

function DocumentMessage({ message }: MessageProps) {
  return (
    <>
      <div
        id={`doc-message${Math.random()}`}
        className={
          message.origin === 'user'
            ? styles.messageOrigin
            : styles.messageSender
        }
      >
        <button 
          type='button' 
          onClick={() => { 
            if (message.body?.media_content){
              const win = window.open();
              win!.document.write(`<iframe src="${message.body?.media_content}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
            } else window.open(message.body?.media_content || '', '_blank');
            }}
        >
          <FcDocument size={40} />
          {message.body?.content_caption ? <p style={{ color: 'white' }}> {message.body?.content_caption} </p> : <> </>} 
        </button>
      </div>    
    </>
  );
}

export default DocumentMessage;