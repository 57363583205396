import { InputHTMLAttributes, useState, FC } from 'react';
import { IconBaseProps } from 'react-icons';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styles from './styles.module.css';

export interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ComponentType<IconBaseProps>;
}

function FormInput({ icon: Icon, type, ...props }: FormInputProps) {
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.container}>
      {Icon && (
        <span>
          <Icon size={20} />
        </span>
      )}

      <input color="#737373" type={visible ? 'text' : type} {...props} />

      {type === 'password' && (
        <button type="button" onClick={() => setVisible(!visible)}>
          <Eye visible={visible} />
        </button>
      )}
    </div>
  );
}

interface EyeProps {
  visible: boolean;
}

const Eye: FC<EyeProps> = ({ visible }) => {
  if (visible) {
    return <AiOutlineEye size={20} color="black" />;
  }

  return <AiOutlineEyeInvisible size={20} color="black" />;
};

export default FormInput;
