import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { quickReplyState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const QuickReplyState = useSelector<RootState, quickReplyState>((state) => state.quickReplies);

  return {
    ...dispatch.quickReplies,
    ...QuickReplyState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.models.quickReplies
  );

  return loading;
};
