import { Chatbot } from '@entities';
import { useEffect, useState } from 'react';
import { BsSearch, BsPlus } from 'react-icons/bs';
import { ChatbotList } from '@pages/Admin/teamInfo';
import ChatBotCard from '@pages/Admin/ManagerView/components/ChatBotCard';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';

interface ChatbotsTabProps {
  chatbots: Chatbot[];
}

function ChatbotsTab({ chatbots }: ChatbotsTabProps) {
  const [searchTerms, setSearchTerms] = useState('');
  const [selected, setSelected] = useState<Chatbot | null>(null);
  const [showBots, setShowBots] = useState<Chatbot[]>([]);

  useEffect(() => {
    if (searchTerms) {
      setShowBots(
        chatbots.filter(
          (bot: Chatbot) =>
            bot.name
              .toLocaleLowerCase()
              .includes(searchTerms.toLocaleLowerCase()) ||
            bot.platform
              .toLocaleLowerCase()
              .includes(searchTerms.toLocaleLowerCase())
        )
      );
    } else {
      setShowBots(chatbots);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  useEffect(() => {
    setShowBots(chatbots);
  }, [chatbots]);

  return (
    <div>
      <section className={styles.navBarTeam}>
        <h1>Todos os Chatbots</h1>
        <button type="button">
          <BsPlus size={35} />
          Novo Bot
        </button>
        <div className={styles.searchInput}>
          <BsSearch size={20} color="grey" />
          <input
            placeholder="Pesquise por nome e plataformas..."
            color="#737373"
            type="text"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          />
        </div>
      </section>
      <section className={styles.chatbotsContainer}>
        {showBots.map((item) => (
          <ChatBotCard key={Math.random()} chatbot={item} />
        ))}
      </section>
    </div>
  );
}
export default ChatbotsTab;
