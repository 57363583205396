import { FormEvent } from 'react';
import Modal from '@components/Modal';
import trash from '@assets/trash.png';
import styles from './styles.module.css';

export interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  onAfterClose?: () => void;
  contentLabel?: string;
  success?: boolean;
  confirm: (e: FormEvent) => void;
}

function ConfirmDeleteModal({
  isOpen,
  onClose,
  message,
  success,
  confirm,
}: AlertModalProps) {
  return (
    <div className={styles.contentModal}>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className={styles.messageContainer}>
          <img src={trash} alt="Deletar" />
          <p>Tem certeza que deseja apagar permanentemente sua conta? </p>
          <br />
          <span>Você não poderá desfazer essa ação!</span>
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.cancel} type="button" onClick={onClose}>
            Cancelar
          </button>
          <button className={styles.delete} type="button" onClick={confirm}>
            Apagar conta
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmDeleteModal;
