import { IconType } from 'react-icons';
import { DateTime } from 'luxon';
import { RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import { GrLanguage } from 'react-icons/gr';
import { columnAlphabeticCompare, columnDateCompare } from '@utils/compare';
import { Attendance } from '@entities';
import { Column, LineItem } from '@components/Table';
import { PickerItem } from '@components/RegularPicker';
import LinkTo from './components/LinkTo';

type PlatformOption = 'whatsapp' | 'facebook' | 'web';

const ICONS: { [platform: string]: IconType } = {
  whatsapp: RiWhatsappFill,
  facebook: RiFacebookCircleFill,
  web: GrLanguage,
};

export const HISTORY_COLUMNS: Column[] = [
  { label: 'Ver', key: 'view' },
  {
    label: 'Cliente/Marca',
    key: 'client',
    compareFn: columnAlphabeticCompare,
    userTypes: ['administrator'],
  },
  {
    label: 'Atendente',
    key: 'attendant',
    compareFn: columnAlphabeticCompare,
    userTypes: ['manager', 'administrator'],
  },
  { label: 'Protocolo', key: 'protocol', compareFn: columnAlphabeticCompare },
  { label: 'Plataforma', key: 'platform' },
  { label: 'Usuário', key: 'user', compareFn: columnAlphabeticCompare },
  { 
    label: 'Data de abertura', 
    key: 'openDate', 
    compareFn: columnDateCompare,
  },
  {
    label: 'Data de fechamento',
    key: 'closeDate',
    compareFn: columnDateCompare,
  },
];

interface Filters {
  attendantName?: string;
  clientName?: string;
}

export function historyToLineItem(
  attendances: Attendance[],
  filters?: Filters
): LineItem[] {
  return attendances.map((item) => {
    const openDate = item?.opened_at ? new Date(item?.opened_at || '').toLocaleString('pt-BR') : 'Reativado';
    const closeDate = new Date(item?.closed_at || '').toLocaleString('pt-BR');
    const Icon = ICONS[item.platform as PlatformOption];

    return {
      view: <LinkTo to={`/atendimentos/${item.protocol}`} tags={item.tags} />,
      protocol: item.protocol,
      platform: <Icon size={20} />,
      openDate,
      closeDate,
      user: item.user_name,
      attendant: item.attendant_name || 'Cancelado',
      client: item.company || 'Desconhecido',
      tags: item.tags.map(tag => tag.name).join(' | '),
    };
  });
}

export const defaultPeriod = {
  getSince() {
    return DateTime.now().minus({ months: 1 }).startOf('day').toISO();
  },

  getUntil() {
    return DateTime.now().toISO();
  },
};

export const UNPICKED_ITEM: PickerItem = {
  label: 'Qualquer',
  value: '',
};

export const PLATFORM_OPTIONS: PickerItem[] = [
  { label: 'Web', value: 'web' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Facebook', value: 'facebook' },
];

export const CONTENT_OPTIONS: PickerItem[] = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '75',
    value: 75,
  },
  {
    label: '100',
    value: 100,
  },
];
