import { IconType } from 'react-icons';
import styles from './styles.module.css';

export interface CellProps {
  title: string;
  description: string;
  teams?: string[]
  icons?: IconType[];
  onClick: () => void;
  onlineStatus?: boolean;
  availabilityStatus?: boolean;
}

function Cell({ onClick, title, description, icons, onlineStatus, availabilityStatus, teams }: CellProps) {

  function getStatus() {
    if (availabilityStatus === true && onlineStatus === true) {
      return (<div title="Disponível" className={styles.statusOnline} />);
    }
    return (<div title="Indisponível" className={styles.statusOffline} />);
  }

  return (
    <button
      key={Math.random()}
      onClick={onClick}
      type="button"
      className={styles.cell}
    >
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>{title}</h1>
        {getStatus()}
      </section>

      <section style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <span>{teams?.join(' | ')}</span>
        <span>{description}</span>
        <div className={styles.icon}>
          {icons && icons.map((Icon) => (
            <Icon key={Math.random()} />
          ))}
        </div>
      </section>
    </button>
  );
}

export default Cell;
