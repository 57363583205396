import { ImSpinner9 } from 'react-icons/im';
import styles from './styles.module.css';

function Loading() {
  return (
    <div className={styles.loading}>
      <div className={styles.spin}>
        <ImSpinner9 className="spin" />
      </div>
    </div>
  );
}

export default Loading;
