import { GrLanguage } from 'react-icons/gr';
import { RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import { CgTrash, CgProfile } from 'react-icons/cg';
import { FiEdit2 } from 'react-icons/fi';
import { Chatbot } from '@entities';
import { formatDate } from '@utils/strings';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';

interface ChatBotCardProps {
  chatbot: Chatbot;
}

function ChatBotCard({ chatbot }: ChatBotCardProps) {
  const getIcon = () => {
    if (chatbot.platform === 'facebook')
      return <RiFacebookCircleFill size={20} />;
    if (chatbot.platform === 'whatsapp') return <RiWhatsappFill size={20} />;
    return <GrLanguage size={18} />;
  };

  const { removeBot } = useModel();

  const handleRemoveBot = () => {
    removeBot({
      bot: chatbot,
      updateCompany: '',
    });
  };

  return (
    <div className={styles.cardChatBot}>
      <section className={styles.cardHeader}>
        <h1>{chatbot.name}</h1>
        <FiEdit2 size={20} />
      </section>
      <section className={styles.cardBody}>
        <CgProfile size={50} />
        <div className={styles.line}>
          <span>URL</span>
          <h1>{chatbot.url}</h1>
        </div>
        <div className={styles.line}>
          <span>Data da Criação</span>
          <h1>{formatDate(chatbot.created_at)}</h1>
        </div>
        <div className={styles.line}>
          <h1 className={styles.plataform}>
            {getIcon()}
            {chatbot.platform}
          </h1>
        </div>
      </section>
      {/* <section className={styles.cardFooter}>
        <div className={styles.remove}>
          <CgTrash color="white" />
          <button type="button" onClick={handleRemoveBot}>
            Remover
          </button>
        </div>
      </section> */}
    </div>
  );
}

export default ChatBotCard;
