import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import api, { errorHandler } from '@services/api';

export interface quickReplyState {
  quickReplies: QuickReply[];
  error: string;
}

interface QuickReply{
  id: string;
  text: string;
  company: string;
  name: string;
}

export const quickReplies = createModel<RootModel>()({
  state: {
    quickReplies: [],
    error: '',
  } as quickReplyState,

  reducers: {
    setquickReplies(state, payload: QuickReply[]) {
      state.quickReplies = payload;
      return state;
    },

    setError(state, payload: string) {
      state.error = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async getquickReplies(payload, state) {
      const { setquickReplies, setError } = dispatch.quickReplies;
      const { user } = state.application;

      await errorHandler(async () => {
        const response = await api.get(`/quickReplies?company=${user?.company}`);
        console.log('Response');
        console.log(response.data);
        setquickReplies(response.data);
      }, setError);
    },

    async createquickReply(payload: { text: string, name: string }, state) {
      const { setError, getquickReplies } = dispatch.quickReplies;
      const { user } = state.application;

      if (!payload) {
        setError('Preencha o nome da Resposta rápida.');
        return;
      }

      await errorHandler(async () => {
        await api.post('/quickReplies/create', {
          text: payload.text,
          company: user?.company,
          name: payload.name
        });
        getquickReplies();
      }, setError);
    },

    async deleteQR(payload: string, state) {
      const { setError, getquickReplies } = dispatch.quickReplies;

      await errorHandler(async () => {
        await api.delete(`/quickReplies/${payload}`);
        getquickReplies();
      }, setError);
    },
  }),
});
