import { BsSearch } from 'react-icons/bs';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useModel, useLoading } from '@flux/models/tags/hooks';
import LoadingLabel from '@components/LoadingLabel';
import alert from '@assets/alert.png';
import TagLine from '../TagLine';
import styles from './styles.module.css';

export interface ManageViewProps {
  onCreate: () => void;
}

function ManageView({ onCreate }: ManageViewProps) {
  const { tags, deleteTag, updateTag } = useModel();
  const loading = useLoading();

  return (
    <div className={styles.container}>
      <div className={styles.searchInput}>
        <BsSearch size={20} />
        <input placeholder="Pesquise por tags..." />
      </div>

      <div className={styles.tags}>
        {!loading && tags.length === 0 && (
          <div className={styles.alert}>
            <img src={alert} alt="Alerta" />
            <h2>Não existem tags cadastradas.</h2>
          </div>
        )}
        {loading ? (
          <LoadingLabel size="medium" />
        ) : (
          tags.map((tag) => (
            <TagLine
              key={Math.random()}
              tag={tag}
              mode="edit"
              onEdit={(newTag) => {
                updateTag({ name: newTag.name, id: newTag.id });
              }}
              onDelete={deleteTag}
            />
          ))
        )}
      </div>

      <button className={styles.newTag} type="button" onClick={onCreate}>
        <span>Adicionar nova tag</span>
        <AiFillPlusCircle size={30} color="white" />
      </button>
    </div>
  );
}

export default ManageView;
