import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { HistoryPageState } from './entities';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const historyPageState = useSelector<RootState, HistoryPageState>(
    (state) => state.historyPage
  );

  return {
    ...dispatch.historyPage,
    ...historyPageState,
  };
};

export const useLoading = () => {
  const loadingHistory = useSelector<RootState, boolean>(
    (state) => state.loading.effects.historyPage.getHistory
  );

  const loadingSearch = useSelector<RootState, boolean>(
    (state) => state.loading.effects.historyPage.searchByName
  );

  return { loadingHistory, loadingSearch };
};
