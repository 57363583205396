import { RiSendPlaneFill } from 'react-icons/ri';
import { FiMessageCircle } from 'react-icons/fi';
import { ChangeEvent, FormEvent, useState } from 'react';
import styles from './styles.module.css';

export interface InputMessageProps {
  enabled: boolean;
  onSubmit: (value: string) => void;
}

function InputMessage({ enabled, onSubmit }: InputMessageProps) {
  const [message, setMessage] = useState('');

  const submitMessage = (e: FormEvent) => {
    e.preventDefault();
    if (message) onSubmit(message);
    setMessage('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setMessage(e.target.value);
  };

  return (
    <form className={styles.inputContainer} onSubmit={submitMessage}>
      <FiMessageCircle size={25} />
      <input
        disabled={!enabled}
        type="text"
        placeholder="Digite sua mensagem..."
        className={styles.inputMessage}
        onChange={handleChange}
        value={message}
      />
      <button type="button" className={styles.btnSendMessageContainer}>
        <RiSendPlaneFill
          className={styles.btnSendMessage}
          size={50}
          color="#4A69BD"
          onClick={submitMessage}
        />
      </button>
    </form>
  );
}

export default InputMessage;
