import Cell from '@pages/Admin/components/Cell';
import styles from './styles.module.css';

export interface CollaboratorsGradCellsProps<Item> {
  items: Item[];
  onSelect: (item: Item) => void;
  getTitle: (item: Item) => string;
  getDescription: (item: Item) => string;
  getOnlineStatus: (item: Item) => boolean | undefined;
  getAvailabilityStatus: (item: Item) => boolean | undefined;
  getTeams: (item: Item) => string[];
}

function CollaboratorsGradCells<Item>({
  items,
  getDescription,
  getTitle,
  onSelect,
  getOnlineStatus,
  getAvailabilityStatus,
  getTeams
}: CollaboratorsGradCellsProps<Item>) {
  return (
    <section className={styles.listItems}>
      {items.map((item) => (

        <Cell
          key={Math.random()}
          title={getTitle(item)}
          description={getDescription(item)}
          onClick={() => onSelect(item)}
          onlineStatus={getOnlineStatus(item)}
          availabilityStatus={getAvailabilityStatus(item)}
          teams={getTeams(item)}
        />
      ))}
    </section>
  );
}

export default CollaboratorsGradCells;
