import { RiUserFill, RiWhatsappFill } from 'react-icons/ri';
import styles from './styles.module.css';

interface ChatContactInfoProps {
  phone: string;
  name: string;
}

function ChatContactInfo({ phone, name }: ChatContactInfoProps) {
  return (
    <div className={styles.chatInfo}>
      <div className={styles.infoItem}>
        <p>Nome</p>
        <div className={styles.itemIcon}>
          <RiUserFill className={styles.icons} size={22} />
          <h2>{name}</h2>
        </div>
      </div>
      <div className={styles.infoItem}>
        <p>Telefone</p>
        <div className={styles.itemIcon}>
          <RiWhatsappFill className={styles.icons} size={22} />
          <h2>{phone}</h2>
        </div>
      </div>
    </div>
  );
}

export { ChatContactInfo };
