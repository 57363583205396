import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { User } from '@entities';
import api, { errorHandler } from '@services/api';
import clockImg from '@assets/clock.png';
import speedImg from '@assets/attendance/speed.png';
import {
  StatisticsPageState,
  GetStatisticsPayload,
  StatsAverage,
  StatsInfo,
  ChartInfo,
  StatsChart,
  ResponseChartInfo,
} from './entities';

export const statisticsPage = createModel<RootModel>()({
  state: {
    error: '',
    getStatisticsPayload: null,
    statsAverages: [],
    statsInfos: [],
    statsChart: [],
    chartInfos: [],
  } as StatisticsPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setGetStatisticsPayload(state, payload: GetStatisticsPayload) {
      state.getStatisticsPayload = payload;
      return state;
    },

    setStatsAverages(state, payload: StatsAverage[]) {
      state.statsAverages = payload;
      return state;
    },

    setStatsInfos(state, payload: StatsInfo[]) {
      state.statsInfos = payload;
      return state;
    },

    setStatsChart(state, payload: StatsChart[]) {
      state.statsChart = payload;
      return state;
    },

    setChartInfos(state, payload: ChartInfo[]) {
      state.chartInfos = payload;
      return state;
    },
  },

  effects: (dispatch) => ({

    async getStatisticsAverage(payload, state) {
      const {
        setError,
        setStatsAverages
      } = dispatch.statisticsPage;
      const { user } = state.application;
      const { getStatisticsPayload } = state.statisticsPage;

      if (!getStatisticsPayload) return;

      const  urlAverageAtt = getStatisticsUrl(getStatisticsPayload, user, 'label', 'average_attendance');
      const  urlAverageWait = getStatisticsUrl(getStatisticsPayload, user, 'label', 'average_waiting');
      await errorHandler(async () => {
        const average_attendance = (await api.get(urlAverageAtt)).data;
        const average_waiting = (await api.get(urlAverageWait)).data;
        const statsAverages = [
          {
            title: 'Tempo médio de atendimento',
            description: '(minutos)',
            value: average_attendance,
            img: clockImg,
          },
          {
            title: 'Tempo médio de espera',
            description: '(minutos)',
            value: average_waiting,
            img: speedImg,
          },
        ];
        setStatsAverages(statsAverages);
      }, setError);
    },

    async getStatisticsInfos(payload, state) {
      const {
        setError,
        setStatsInfos
      } = dispatch.statisticsPage;
      const { user } = state.application;
      const { getStatisticsPayload } = state.statisticsPage;

      if (!getStatisticsPayload) return;

      const  urlRequestsAtt = getStatisticsUrl(getStatisticsPayload, user, 'label', 'attendance_requests');
      const  urlOnGoingAtt = getStatisticsUrl(getStatisticsPayload, user, 'label', 'ongoing_attendances');
      const  urlMessagesNumber = getStatisticsUrl(getStatisticsPayload, user, 'label', 'users_messages_number');
      const  urlUsersNumber = getStatisticsUrl(getStatisticsPayload, user, 'label', 'users_number');
      await errorHandler(async () => {
        const attendance_requests = (await api.get(urlRequestsAtt)).data;
        const ongoing_attendances = (await api.get(urlOnGoingAtt)).data;
        const users_messages_number = (await api.get(urlMessagesNumber)).data;
        const users_number = (await api.get(urlUsersNumber)).data;
        const statsInfos = [
          {
            title: 'Pedidos de atendimento',
            quantity: attendance_requests,
          },
          {
            title: 'Atendimentos em andamento',
            quantity: ongoing_attendances,
          },
          {
            title: 'Total de mensagens enviadas pelos usuários',
            quantity: users_messages_number,
          },
          {
            title: 'Quantidade de usuários',
            quantity: users_number,
          },
        ];
        setStatsInfos(statsInfos);
      }, setError);
    },

    async getStatisticsCharts(payload, state) {
      const {
        setError,
        setStatsChart
      } = dispatch.statisticsPage;
      const { user } = state.application;
      const { getStatisticsPayload } = state.statisticsPage;

      if (!getStatisticsPayload) return;

      const  urlAbandonRate = getStatisticsUrl(getStatisticsPayload, user, 'label', 'abandon_rate');
      const  urlFirstResolution = getStatisticsUrl(getStatisticsPayload, user, 'label', 'first_resolution_calls');
      await errorHandler(async () => {
        const abandon_rate = (await api.get(urlAbandonRate)).data;
        const first_resolution_calls = (await api.get(urlFirstResolution)).data;
        const statsChart = [
          {
            title: 'Taxa de abandono',
            value: `${abandon_rate || 0}`,
          },
          {
            title: 'First call resolution',
            description: '(Por dia)',
            value: first_resolution_calls,
          },
        ];
        setStatsChart(statsChart);
      }, setError);
    },

    async getChartsInfos(payload, state) {
      const {
        setError,
        setChartInfos
      } = dispatch.statisticsPage;
      const { user } = state.application;
      const { getStatisticsPayload } = state.statisticsPage;

      if (!getStatisticsPayload) return;

      const  urlGraphValues = getStatisticsUrl(getStatisticsPayload, user, 'label', 'graph_values');
      await errorHandler(async () => {
        const graph_values = (await api.get(urlGraphValues)).data;
        const filledGraphValues = fillAndMapGraphValues(graph_values);
        setChartInfos(filledGraphValues);
      }, setError);
    },

    async getStatistics(payload, state) {
      const {
        setError,
        setChartInfos,
        setStatsChart,
        setStatsAverages,
        setStatsInfos,
      } = dispatch.statisticsPage;
      const { user } = state.application;
      const { getStatisticsPayload } = state.statisticsPage;

      if (!getStatisticsPayload) return;

      const url = getStatisticsUrl(getStatisticsPayload, user, 'get');

      await errorHandler(async () => {
        const response = await api.get(url);
        const {
          abandon_rate,
          attendance_requests,
          average_attendance,
          average_waiting,
          first_resolution_calls,
          graph_values,
          ongoing_attendances,
          users_messages_number,
          users_number,
        } = response.data;

        const statsInfos = [
          {
            title: 'Pedidos de atendimento',
            quantity: attendance_requests,
          },
          {
            title: 'Atendimentos em andamento',
            quantity: ongoing_attendances,
          },
          {
            title: 'Total de mensagens enviadas pelos usuários',
            quantity: users_messages_number,
          },
          {
            title: 'Quantidade de usuários',
            quantity: users_number,
          },
        ];
        setStatsInfos(statsInfos);

        const statsAverages = [
          {
            title: 'Tempo médio de atendimento',
            description: '(minutos)',
            value: average_attendance,
            img: clockImg,
          },
          {
            title: 'Tempo médio de espera',
            description: '(minutos)',
            value: average_waiting,
            img: speedImg,
          },
        ];
        setStatsAverages(statsAverages);

        const statsChart = [
          {
            title: 'Taxa de abandono',
            value: `${abandon_rate || 0}`,
          },
          {
            title: 'First call resolution',
            description: '(Por dia)',
            value: first_resolution_calls,
          },
        ];
        setStatsChart(statsChart);

        const filledGraphValues = fillAndMapGraphValues(graph_values);
        setChartInfos(filledGraphValues);
      }, setError);
    },

    async exportStatistics(payload, state) {
      const { setError } = dispatch.statisticsPage;
      const { user } = state.application;
      const { getStatisticsPayload } = state.statisticsPage;
      console.log('getStatisticsPayload', getStatisticsPayload);

      if (!getStatisticsPayload) {
        console.log('getStatisticsExport Return');
        return;
      }

      const url = getStatisticsUrl(getStatisticsPayload, user, 'export');

      await errorHandler(async () => {
        const response = await api.get(url);
        const { link } = response.data;

        window.open(link, '_blank');
      }, setError);
    },
  }),
});

function getStatisticsUrl(
  payload: GetStatisticsPayload,
  user: User | null,
  mode: 'get' | 'export' | 'label',
  label?: 'average_attendance' | 'average_waiting' | 'completed_attendances' | 'attendance_requests' | 'users_number' | 'ongoing_attendances' | 'users_messages_number' | 'abandon_rate' | 'first_resolution_calls' |  'graph_values'
) {
  const {
    since,
    until,
    attendant_id,
    client,
    opened_at,
    closed_at,
    platform,
    protocol,
    team,
    tag,
  } = payload;

  let route;
  switch (mode) {
    case 'label':
      route = 'statistics/label';
      break;
    case 'export':
      route = 'statistics-export';
      break;
    case 'get':
      route = 'statistics';
      break;
    default:
      route = 'statistics';
      break;
  }

  const url = new URL(`${process.env.REACT_APP_API}/attendances/${route}`);
  url.searchParams.append('since', since);
  url.searchParams.append('until', until);
  if (mode === 'label') {
    url.searchParams.append('label', label as string);
  }

  if (user?.type === 'attendant') {
    url.searchParams.append('attendant_id', user.id);
  } else if (user && attendant_id) {
    url.searchParams.append('attendant_id', attendant_id);
  }

  if (protocol) {
    url.searchParams.append('protocol', protocol);
  }

  if (client) {
    url.searchParams.append('client', client);
  }

  if (platform) {
    url.searchParams.append('platform', platform);
  }

  if (opened_at) {
    url.searchParams.append('opened_at', opened_at);
  }

  if (closed_at) {
    url.searchParams.append('closed_at', closed_at);
  }

  if (team) {
    url.searchParams.append('team_id', team);
  }

  if (tag) {
    url.searchParams.append('tag_name', tag);
  }

  return url.href;
}

function fillAndMapGraphValues(data: ResponseChartInfo[]): ChartInfo[] {
  const mappedResponses: ChartInfo[] = data.map((item: ResponseChartInfo) => ({
    name: `${item.hora === 0 ? '24' : item.hora}h`,
    value: item.count,
  }));

  const chartInfos: ChartInfo[] = [];
  for (let i = 2; i <= 24; i += 2) {
    const currentHourIndex = mappedResponses.findIndex(
      (item) => item.name === `${i}h`
    );

    if (currentHourIndex !== -1) {
      chartInfos.push(mappedResponses[currentHourIndex]);
    } else {
      chartInfos.push({
        name: `${i}h`,
        value: 0,
      });
    }
  }

  return chartInfos;
}
