import { isValidPhoneNumber } from 'libphonenumber-js';

export function filterPhone(phone: string) {
  let phoneNumber = getValidDigits(phone);

  if (!phoneNumber) return '';

  if (!hasDDICode(phone)) {
    phoneNumber = `+55${phoneNumber}`;
  }

  if (verify9(phoneNumber)) {
    phoneNumber = phoneNumber.slice(0, 5) + phoneNumber.slice(6);
  }

  return phoneNumber;
}

export function verifyPhone(phone: string) {
  return isValidPhoneNumber(phone);
}

function hasDDICode(phone: string){
  return phone[0] === '+';
}

function verify9(phone: string){
  const ddi = phone.slice(0, 3);
  return ddi === '+55' && phone.length === 14;
}

function getValidDigits(phone: string) {
  let validDigits = '';

  for (let i = 0; i < phone.length; i++) {
    if ((i === 0 && phone[i] === '+') || (phone[i] >= '0' && phone[i] <= '9')) {
      validDigits += phone[i];
    }
  }

  return validDigits;
}
