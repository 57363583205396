import LoadingSpinner from '@components/LoadingSpinner';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts';
import styles from './styles.module.css';

export interface ChartItemProps {
  name: string;
  value: number;
}
export interface ChartProps {
  data: ChartItemProps[];
  loading?: boolean
}

function Chart({ data, loading, ...props }: ChartProps) {
  if (loading) {
    return (
      <ResponsiveContainer width="100%" height="95%">
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      </ResponsiveContainer>
    );
  }
  return (
    <ResponsiveContainer width="100%" height="95%">
      <BarChart
        width={150}
        height={40}
        data={data}
        margin={{ top: 30, bottom: 10, right: 10 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3" stroke="#000000" />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar dataKey="value" barSize={35} fill="#4A69BD">
          <LabelList dataKey="value" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Chart;
