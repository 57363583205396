import { ReactNode, useState, useCallback, useEffect } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import * as Application from '@flux/models/application/hooks';
import LoadingLabel from '@components/LoadingLabel';
import styles from './styles.module.css';

export type Value = string | number | ReactNode;
export type Column = {
  label: string;
  key: string;
  compareFn?: (a: Value, b: Value, ascending: boolean) => number;
  userTypes?: ('administrator' | 'manager' | 'attendant')[];
};
export type LineItem = { [column: string]: Value };

export interface TableLineProps {
  lineItem: LineItem;
  columns: Column[];
}

function TableLine({ lineItem, columns }: TableLineProps) {
  const { user } = Application.useModel();

  const mapToTd = () =>
    columns.map((column) => {
      if (user && column.userTypes && !column.userTypes.includes(user.type))
        return undefined;

      return <td key={Math.random()}>{lineItem[column.key]}</td>;
    });

  return <tr className={styles.tableLine}>{mapToTd()}</tr>;
}

export interface TableHeadProps {
  columns: Column[];
  selectedColumn: number | null;
  toggleColumn: (index: number | null) => void;
  ascending: boolean;
}

function TableHead({ columns, selectedColumn, toggleColumn, ascending }: TableHeadProps) {
  const { user } = Application.useModel();

  const mapToTh = () =>
    columns.map((column, index) => {
      if (user && column.userTypes && !column.userTypes.includes(user.type))
        return undefined;

      return (
        <th key={Math.random()}>
          {column.compareFn ? (
            <div className={styles.sortLabel}>
              <button type="button" onClick={() => toggleColumn(index)}>
                {column.label}
                {selectedColumn === index && ascending ? <FaAngleUp /> : <FaAngleDown />}
              </button>
              {selectedColumn === index ? 
                <button type="button" onClick={() => toggleColumn(null)}>
                  <AiOutlineCloseCircle />
                </button> : null}
            </div>
          ) : (
            column.label
          )}
        </th>
      );
    });

  return <tr>{mapToTh()}</tr>;
}

export interface TableProps {
  title: string;
  columns: Column[];
  data: LineItem[];
  topRightItem?: ReactNode;
  width?: string;
  height?: string;
  loading?: boolean;
  downRightItem?: ReactNode;
}

function Table({
  title,
  columns,
  data,
  topRightItem,
  width = '800px',
  height = '40vh',
  loading,
  downRightItem
}: TableProps) {
  const [lineItems, setLineItems] = useState<LineItem[]>(data.slice());
  const [original, setOriginal] = useState<LineItem[]>(data.slice());
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);
  const [ascending, setAscending] = useState(true);

  const toggleColumn = useCallback(
    (index: number | null) => {
      if (!index) {
        setSelectedColumn(null);
        setLineItems(original);
        return;
      }

      const newSelectedColumn = index;

      setSelectedColumn(index);
      setAscending(!ascending);
      
      if (newSelectedColumn) {
        setAscending(!ascending);
        const sortedItems = lineItems.slice();

        sortedItems.sort((a, b) => {
          const currentColumn = columns[newSelectedColumn];
          if (!currentColumn.compareFn) return 0;

          const aValue = a[currentColumn.key];
          const bValue = b[currentColumn.key];

          return currentColumn.compareFn(aValue, bValue, ascending);
        });

        setLineItems(sortedItems);
      } else {
        setLineItems(original);
      }
    },
    [selectedColumn, lineItems, original, columns]
  );

  useEffect(() => {
    setLineItems(data.slice());
    setOriginal(data.slice());
    setSelectedColumn(null);
  }, [data]);

  return (
    <article style={{ width }} className={styles.tableContainer}>
      <header className={styles.tableHeader}>
        <h2>{title}</h2>

        {topRightItem}
      </header>

      {loading ? (
        <div className={styles.loadingContainer}>
          <LoadingLabel size="large" />
        </div>
      ) : (
        <div style={{ height }} className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <TableHead
                columns={columns}
                selectedColumn={selectedColumn}
                toggleColumn={toggleColumn}
                ascending={ascending}
              />
            </thead>

            <tbody>
              {lineItems.map((item) => (
                <TableLine
                  key={Math.random()}
                  lineItem={item}
                  columns={columns}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}

      {
        downRightItem && 
        <footer className={styles.tableFooter}>
          {downRightItem}
        </footer>
      }
    </article>
  );
}

export default Table;
