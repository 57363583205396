import { useEffect } from 'react';
import styles from './styles.module.css';

export interface ErrorMessageProps {
  message: string;
  setError: Function;
}

function ErrorMessage({ message, setError }: ErrorMessageProps) {
  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, 3000);
  }, [setError]);

  return (
    <div className={styles.messageContainer}>
      <h2>{message}</h2>
    </div>
  );
}

export default ErrorMessage;
