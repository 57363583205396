import { User } from '@entities';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { ImSpinner9 } from 'react-icons/im';
import { formatDate } from '@utils/strings';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';

const MAX_LEN_NAME = 32;
const MAX_LEN_EMAIL = 30;
const MAX_LEN_COMPANY = 15;

interface RequestLineProps {
  collaborator: User;
  origem: string;
}

const Loading = () => (
  <>
    <ImSpinner9 className="spin" />
  </>
);

function RequestLine({ collaborator, origem }: RequestLineProps) {
  const { resolveRequest } = AdminHook.useModel();
  const { loadingAcceptReject } = AdminHook.useLoading();

  const handleAcceptRequest = () => {
    resolveRequest({
      user: collaborator,
      can_login_request: true,
      origem
    });
  };

  const handleRejecttRequest = () => {
    resolveRequest({
      user: collaborator,
      can_login_request: false,
      origem
    });
  };

  let lineStyle = styles.requestLine;
  if (
    collaborator.name.length > MAX_LEN_NAME ||
    collaborator.email.length > MAX_LEN_EMAIL ||
    collaborator.company.length > MAX_LEN_COMPANY
  ) {
    lineStyle = styles.requestCard;
  }

  return (
    <div className={lineStyle}>
      <div className={styles.row}>
        <span>Nome Completo</span>
        <h1>{collaborator.name}</h1>
      </div>
      <div className={styles.row}>
        <span>e-mail</span>
        <h1>{collaborator.email}</h1>
      </div>
      <div className={styles.row}>
        <span>Empresa</span>
        <h1>{collaborator.company}</h1>
      </div>
      <div className={styles.row}>
        <span>Data de Solicitação</span>
        <h1>{formatDate(collaborator.created_at)}</h1>
      </div>
      {loadingAcceptReject ? 
        <Loading />
      : (
        <div className={styles.buttons}>
          <button
            className={styles.accept}
            type="button"
            onClick={handleAcceptRequest}
          >
            <AiOutlineCheck color="white" />{' '}
          </button>
          <button
            className={styles.reject}
            type="button"
            onClick={handleRejecttRequest}
          >
            <AiOutlineClose color="white" />{' '}
          </button>
        </div>
      )}
    </div>
  );
}
export default RequestLine;
