/* eslint-disable jsx-a11y/media-has-caption */
import Modal from '@components/Modal';
import { Media } from '@entities';
import styles from './styles.module.css';


export interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAfterClose?: () => void;
  media: Media;
}

function MediaModal({
  isOpen,
  onClose,
  media,
}: MediaModalProps) {

  const {
    media_content,
    message_type
  } = media;

  const isImage = message_type === 'IMAGE';
  const isVideo = message_type === 'VIDEO';
  const isAudio = message_type === 'AUDIO';
  
  return (
    <div className={styles.contentModal}>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className={styles.mediaContainer}>
          {isImage && <img src={media_content} alt="img" />}
          {isVideo && <video src={media_content} autoPlay controls />}
          {isAudio && 
          <div><video src={media_content} autoPlay controls /></div>}
        </div>
      </Modal>
    </div>
  );
}

export default MediaModal;
