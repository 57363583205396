import { GrLanguage, GrUserManager } from 'react-icons/gr';
import {
  RiFacebookCircleFill,
  RiWhatsappFill,
  RiShieldLine,
} from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { BiPowerOff } from 'react-icons/bi';
import { FiEdit2 } from 'react-icons/fi';
import { AiOutlineCheck } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { ImSpinner9 } from 'react-icons/im';
import { Chatbot, User } from '@entities';
import EditCollaboratorBotsButton from '@components/EditCollaboratorBotsButton';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import { useLoading } from '@flux/models/pages/adminPage/hooks';
import LoadingSpinner from '@components/LoadingSpinner';
import { getAccess } from '@utils/strings';
import styles from './styles.module.css';

interface CollaboratorCardProps {
  collaborator: User;
  setEditBots: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | null;
  loading: boolean;
  setCollaborator?: (item: User | null) => void;
  setEditTeams?: React.Dispatch<React.SetStateAction<boolean>>;
}

const mapTypes = {
  attendant: 'Atendente',
  administrator: 'Administrador',
  manager: 'Gerente',
};

const Loading = () => (
  <>
    <ImSpinner9 className="spin" />
  </>
);

function CollaboratorCardAdmin({
  collaborator,
  setEditBots,
  user,
  loading,
  setCollaborator,
  setEditTeams
}: CollaboratorCardProps) {
  const {
    error,
    chatbots,
    getChatbots,
    makeAdmin,
    makeManager,
    makeAttendant,
    deactivateUser
  } = AdminHook.useModel();

  const {
    loadingMakeAdmin,
    loadingMakeManager,
    loadingDeactivateUser
  } = AdminHook.useLoading();

  useEffect(() => {
    let company = '';
    if (user?.type === 'manager') {
      company = user?.company;
    }
    getChatbots(company);
  }, [collaborator, getChatbots, user?.company, user?.type]);

  const handleMakeAttendant = async () => {
    await makeAttendant({
      user: collaborator,
      origem: 'administrator'
    });
    if (!error) {
      setCollaborator && setCollaborator(null);
    } else {
      console.log('error make attendant', error);
    }
  };

  const handleMakeManager = async () => {
    await makeManager({
      user: collaborator,
      origem: 'administrator'
    });
    if (!error) {
      setCollaborator && setCollaborator(null);
    } else {
      console.log('error make manager', error);
    }
  };

  const handleMakeAdmin = async () => {
    await makeAdmin(collaborator);
    if (!error) {
      setCollaborator && setCollaborator(null);
    } else {
      console.log('error make manager', error);
    }
  };

  const handleDeactivateUser = async () => {
    await deactivateUser(collaborator);
    if (!error) {
      setCollaborator && setCollaborator(null);
    } else {
      console.log('error make manager', error);
    }
  };

  const getPlataformas = () => {
    const plataformas = {
      web: false,
      facebook: false,
      whatsapp: false,
    };
    const userBots = chatbots.filter((bot: Chatbot) =>
    collaborator.chatbots.includes(bot.id));
    // eslint-disable-next-line array-callback-return
    userBots.map((bot: Chatbot) => {
      if (bot.platform === 'web') plataformas.web = true;
      else if (bot.platform === 'facebook') plataformas.facebook = true;
      else plataformas.whatsapp = true;
    });
    const icons = [];
    if (plataformas.web)
      icons.push(
        <h1 key={Math.random()}>
          <GrLanguage size={18} /> Web
        </h1>
      );
    if (plataformas.facebook)
      icons.push(
        <h1 key={Math.random()}>
          <RiFacebookCircleFill size={20} /> Facebook
        </h1>
      );
    if (plataformas.whatsapp)
      icons.push(
        <h1 key={Math.random()}>
          <RiWhatsappFill size={20} /> Whatsapp
        </h1>
      );
    return icons;
  };

  return (
    <div className={styles.cardColaborator}>
      <section className={styles.cardHeader}>
        <h1>{collaborator.name}</h1>
        <span>Perfil: {mapTypes[collaborator.type]}</span>
      </section>
      <section className={styles.cardBody}>
        <div className={styles.line}>
          <span>Cliente/Marca</span>
          <h1>{collaborator.company}</h1>
        </div>
        <div className={styles.line}>
          <span>e-mail</span>
          <h1>{collaborator.email}</h1>
        </div>
        <div className={styles.line}>
          <span>Útlimo acesso</span>
          <h1>{getAccess(collaborator.last_access)}</h1>
        </div>
        <div className={styles.line}>
          <span>Plataformas</span>
          <div className={styles.plataforms}>{getPlataformas()}</div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.editBots}>
            <FiEdit2 style={{ color: 'white', marginRight: '5px' }} />
            <button onClick={() => setEditBots(true)} type="button">
              Editar acesso aos chatbots
            </button>
          </div>
          <div className={styles.editColab}>
            <div className={styles.remove}>
              <BiPowerOff style={{ color: 'white', marginRight: '5px' }} />
              <button type="button" onClick={handleDeactivateUser}>
                { loadingDeactivateUser ? <LoadingSpinner /> : 'Desativar' }
              </button>
            </div>
            {
            collaborator.type !== 'administrator' &&
            <div className={styles.makeAdmin}>
              <RiShieldLine style={{ color: 'white', marginRight: '5px' }} />
              <button type="button" onClick={handleMakeAdmin}>
                { loadingMakeAdmin ? <LoadingSpinner /> :  'Tornar Admin' }
              </button>
            </div>
            }
            {
            collaborator.type !== 'manager' &&
            <div className={styles.makeManager}>
              <GrUserManager style={{ color: 'white', marginRight: '5px' }} />
              <button type="button" onClick={handleMakeManager}>
                { loadingMakeManager ? <LoadingSpinner /> :  'Tornar Gerente' }
              </button>
            </div>
            }
            {
            collaborator.type !== 'attendant' &&
            <div className={styles.makeManager}>
              <GrUserManager style={{ color: 'white', marginRight: '5px' }} />
              <button type="button" onClick={handleMakeAttendant}>
                { loadingMakeManager ? <LoadingSpinner /> :  'Tornar Atendente' }
              </button>
            </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

function CollaboratorCardManager({
  collaborator,
  setEditBots,
  user,
  loading,
  setCollaborator,
  setEditTeams
}: CollaboratorCardProps) {
  const [availableBox, setAvailableBox] = useState(false);

  const {
    error,
    toogleAvailable,
    removeUser,
    chatbots,
    getChatbots,
    makeManager,
    makeAttendant
  } = AdminHook.useModel();
  const { loadingRemoveUser, loadingMakeManager } = useLoading();

  useEffect(() => {
    setAvailableBox(collaborator.available);
    let company = '';
    if (user?.type === 'manager') {
      company = user?.company;
    }
    getChatbots(company);
  }, [collaborator, getChatbots, user?.company, user?.type]);

  const setAvailabe = (colab: User) => {
    const company = user?.company || '';
    const available = !availableBox;
    const { email } = collaborator;
    toogleAvailable({ company, available, email });
    if (!error) {
      setAvailableBox(available);
    }
  };

  const handleRemoveUser = async () => {
    await removeUser({
      user: collaborator,
      updateCompany: '',
    });
    if (!error) {
      setCollaborator && setCollaborator(null);
    }
  };

  const handleMakeAttendant = async () => {
    await makeAttendant({
      user: collaborator,
      origem: 'manager'
    });
    if (!error) {
      setCollaborator && setCollaborator(null);
    } else {
      console.log('error make attendant', error);
    }
  };

  const handleMakeManager = async () => {
    await makeManager({
      user: collaborator,
      origem: 'manager'
    });
    if (!error) {
      setCollaborator && setCollaborator(null);
    }
  };

  const getPlataformas = () => {
    const plataformas = {
      web: false,
      facebook: false,
      whatsapp: false,
    };
    const userBots = chatbots.filter((bot: Chatbot) =>
    collaborator.chatbots.includes(bot.id));
    // eslint-disable-next-line array-callback-return
    userBots.map((bot) => {
      if (bot.platform === 'web') plataformas.web = true;
      else if (bot.platform === 'facebook') plataformas.facebook = true;
      else plataformas.whatsapp = true;
    });
    const icons = [];
    if (plataformas.web)
      icons.push(<GrLanguage size={18} key={Math.random()} />);
    if (plataformas.facebook)
      icons.push(<RiFacebookCircleFill size={20} key={Math.random()} />);
    if (plataformas.whatsapp)
      icons.push(<RiWhatsappFill size={20} key={Math.random()} />);
    return icons;
  };

  return (
    <div className={styles.cardColaborator}>
      <section className={styles.cardHeader}>
        <h1>{collaborator.name}</h1>
        <span>Perfil: {mapTypes[collaborator.type]}</span>
      </section>
      <section className={styles.cardBody}>
        <CgProfile size={100} />
        <div className={styles.line}>
          <span>Cliente/Marca</span>
          <div className={styles.managerClientBrands}>
            <h1>{collaborator.company}</h1>
            <div className={styles.managerPlataforms}>{getPlataformas()}</div>
          </div>
        </div>
        <div className={styles.line}>
          <span>e-mail</span>
          <h1>{collaborator.email}</h1>
        </div>
        <div className={styles.line}>
          <span>Útlimo acesso</span>
          <h1>{getAccess(collaborator.last_access)}</h1>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                className={
                  availableBox
                    ? styles.attendantAvailable
                    : styles.attendantUnavailable
                }
              >
                <button
                  type="button"
                  onClick={() => setAvailabe(collaborator)}
                  className={styles.ghostButton}
                >
                  <AiOutlineCheck />
                </button>
              </div>
              <h1>Disponível/Indisponível</h1>
            </div>
          </div>
        )}
        <div className={styles.managerButtons}>
          <EditCollaboratorBotsButton onClick={() => setEditBots(true)} />
          <button
            className={styles.editBots}
            type="button"
            onClick={() => setEditTeams ? setEditTeams(true) : null}
          >
            <FiEdit2 style={{ color: 'white', marginRight: '5px' }} />
            <span>
              Editar acesso às equipes
            </span>
          </button>
          <div className={styles.remove}>
            <BiPowerOff style={{ color: 'white', marginRight: '5px' }} />
            <button type="button" onClick={handleRemoveUser}>
              {loadingRemoveUser ? <Loading /> : 'Remover'}
            </button>
          </div>
          {
          collaborator.type === 'attendant' &&
            <div className={styles.makeManager}>
              <GrUserManager style={{ color: 'white', marginRight: '5px' }} />
              <button type="button" onClick={handleMakeManager}>
                { loadingMakeManager ? <Loading /> : 'Tornar Gerente'}
              </button>
            </div>
          }
          {
          collaborator.type === 'manager' &&
            <div className={styles.makeManager}>
              <GrUserManager style={{ color: 'white', marginRight: '5px' }} />
              <button type="button" onClick={handleMakeAttendant}>
                { loadingMakeManager ? <LoadingSpinner /> :  'Tornar Atendente' }
              </button>
            </div>
          }
        </div>
      </section>
    </div>
  );
}

export { CollaboratorCardAdmin, CollaboratorCardManager };
