import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { ApplicationState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const applicationState = useSelector<RootState, ApplicationState>(
    (state) => state.application
  );

  return {
    ...applicationState,
    ...dispatch.application,
  };
};
