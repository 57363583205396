import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import api, { errorHandler } from '@services/api';
import { Tag } from '@entities/index';

export interface TagsState {
  tags: Tag[];
  attendanceTags: Tag[];
  error: string;
}

interface UpdateTagPayload {
  id: string;
  name: string;
}

export const tags = createModel<RootModel>()({
  state: {
    tags: [],
    attendanceTags: [],
    error: '',
  } as TagsState,

  reducers: {
    setTags(state, payload: Tag[]) {
      state.tags = payload;
      return state;
    },

    setAttendanceTags(state, payload: Tag[]) {
      state.attendanceTags = payload;
      return state;
    },

    setError(state, payload: string) {
      state.error = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async getTags(payload, state) {
      const { setTags, setError } = dispatch.tags;
      const { user } = state.application;

      await errorHandler(async () => {
        const response = await api.get(`/tags?company=${user?.company}`);
        setTags(response.data);
      }, setError);
    },

    async getAttendanceTags(payload: string, state) {
      const { setAttendanceTags, setError } = dispatch.tags;
      const { user } = state.application;

      await errorHandler(async () => {
        const response = await api.get(
          `/attendances/${payload}/tags?company=${user?.company}`
        );
        setAttendanceTags(response.data);
      }, setError);
    },

    async createTag(payload: string, state) {
      const { setError, getTags } = dispatch.tags;
      const { user } = state.application;

      if (!payload) {
        setError('Preencha o nome da tag.');
        return;
      }

      await errorHandler(async () => {
        await api.post('/tags/create', {
          name: payload,
          company: user?.company,
          userEmail: user?.email,
        });
        getTags();
      }, setError);
    },

    async updateTag(payload: UpdateTagPayload, state) {
      const { setError, getTags } = dispatch.tags;
      const { id, name } = payload;
      const { user } = state.application;

      if (!name) {
        setError('Preencha o nome da tag.');
        return;
      }

      if (!id) {
        setError('Preencha o id da tag.');
        return;
      }

      await errorHandler(async () => {
        await api.put(`/tags/${id}`, { name, userEmail: user?.email });
        getTags();
      }, setError);
    },

    async deleteTag(payload: string, state) {
      const { setError, getTags } = dispatch.tags;

      await errorHandler(async () => {
        await api.delete(`/tags/${payload}`);
        getTags();
      }, setError);
    },

    async addAttendanceTag(payload: string, state) {
      const { setError, getAttendanceTags } = dispatch.tags;
      const { attendance } = state.chatPage;

      await errorHandler(async () => {
        await api.put(`/attendances/${attendance?.protocol}/tags`, {
          tag_id: payload,
        });
        getAttendanceTags(String(attendance?.protocol));
      }, setError);
    },

    async deleteAttendanceTag(payload: string, state) {
      const { setError, getAttendanceTags } = dispatch.tags;
      const { attendance } = state.chatPage;

      await errorHandler(async () => {
        await api.delete(
          `/attendances/${attendance?.protocol}/tags/${payload}`
        );
        getAttendanceTags(String(attendance?.protocol));
      }, setError);
    },
  }),
});
