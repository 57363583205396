import { useEffect, useState } from 'react';
import { RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import { GrLanguage } from 'react-icons/gr';
import SearchInput from '@components/SearchInput';
import {
  CollaboratorCardAdmin,
  CollaboratorCardManager,
} from '@pages/Admin/components/CollaboratorCard';
import socket, { UPDATE_ONLINE_STATUS_EVENT } from '@services/socket';
import EditCollaboratorBots from '@pages/Admin/components/EditCollaboratorBots';
import EditCollaboratorTeams from '@pages/Admin/components/EditCollaboratorTeams';
import PeriodPicker from '@components/PeriodPicker';
import { useLoading } from '@flux/models/pages/adminPage/hooks';
import { Team, User } from '@entities';
import { getAccess } from '@utils/strings';
import CollaboratorsGradCells from '@pages/Admin/components/CollaboratorsGradCells';
import styles from './styles.module.css';

interface CollaboratorsTabProps {
  user: User | null;
  collaborators: User[];
  teams: Team[]
}

function CollaboratorsTab({ user, collaborators, teams }: CollaboratorsTabProps) {
  const [searchTerms, setSearchTerms] = useState('');
  const [selected, setSelected] = useState<User | null>(null);
  const [editBots, setEditBots] = useState(false);
  const [editTeams, setEditTeams] = useState(false);
  const [showCollaborators, setShowCollaborators] = useState<User[]>([]);
  const { loadingToogleAvailable } = useLoading();
  const [since, setSince] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);

  const filterBySearch = (term: string, colab: User) =>
    colab.name.toLocaleLowerCase().includes(searchTerms.toLocaleLowerCase()) ||
    colab.company.toLocaleLowerCase().includes(searchTerms.toLocaleLowerCase());

  const filetByPeriod = (colab: User) => {
    if (since && until) {
      return (
        new Date(colab.last_access) >= since &&
        new Date(colab.last_access) <= until
      );
    }
    return null;
  };

  useEffect(() => {
    if (searchTerms && since && until) {
      const filtered = collaborators
        .filter((colab: User) => filterBySearch(searchTerms, colab))
        .filter((colab: User) => filetByPeriod(colab));
      setShowCollaborators(filtered);
    } else if (searchTerms) {
      const filtered = collaborators.filter((colab: User) =>
        filterBySearch(searchTerms, colab)
      );
      setShowCollaborators(filtered);
    } else if (since && until) {
      const filtered = collaborators.filter((colab: User) =>
        filetByPeriod(colab)
      );
      setShowCollaborators(filtered);
    } else {
      setShowCollaborators(collaborators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms, since, until]);

  useEffect(() => {
    setShowCollaborators(collaborators);
  }, [collaborators]);

  useEffect(() => {
    socket.on(UPDATE_ONLINE_STATUS_EVENT, ({ userId, online }) => {
      const index = showCollaborators.findIndex(
        element => element.id === userId);

      if (index !== -1) {
        const newObj = { ...showCollaborators[index] };
        newObj.online = online;
        showCollaborators.splice(index, 1);
        setShowCollaborators([...showCollaborators, newObj]);
      }
    });

    return () => {
      socket.off(UPDATE_ONLINE_STATUS_EVENT);
    };
  }, [showCollaborators]);

  const toggleSelected = (item: User) => {
    setSelected(selected?.id === item.id ? null : item);
  };

  const getTeams = (item: User): string[] => {
    const teamsNames: string[] = teams.filter(team => item.teams?.includes(team.id)).map(team => team.name);
    return teamsNames;
  };

  if (editBots && selected) {
    return (
      <EditCollaboratorBots collaborator={selected} />
    );
  }

  if (editTeams && selected) {
    return (
      <EditCollaboratorTeams collaborator={selected} />
    );
  }

  return (
    <div>
      <section className={styles.navBarTeam}>
        <h1>Colaboradores</h1>
        <SearchInput
          placeholder="Pesquise por nome e plataformas..."
          type="text"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />

        <PeriodPicker
          onSelect={(period) => {
            setSince(period.since);
            setUntil(period.until);
          }}
        />
      </section>

      <section className={styles.colaboratorsContainer}>
        <CollaboratorsGradCells
          items={showCollaborators}
          onSelect={toggleSelected}
          getTitle={(item) => item.name}
          getDescription={(item) => `Acesso: ${getAccess(item.last_access)}`}
          getOnlineStatus={(item) => (item.online === true)}
          getAvailabilityStatus={(item) => (item.available === true)}
          getTeams={getTeams}
        />

        {selected && (
          <section className={styles.infoCollaborator}>
            {user?.type === 'administrator' ? (
              <CollaboratorCardAdmin
                collaborator={selected}
                setCollaborator={setSelected}
                setEditBots={setEditBots}
                user={user}
                loading={loadingToogleAvailable}
              />
            ) : (
              <CollaboratorCardManager
                collaborator={selected}
                setCollaborator={setSelected}
                setEditBots={setEditBots}
                setEditTeams={setEditTeams}
                user={user}
                loading={loadingToogleAvailable}
              />
            )}
          </section>
        )}
      </section>
    </div>
  );
}

const getIcons = (collaborator: User) => {
  const platforms = {
    web: false,
    facebook: false,
    whatsapp: false,
  };

  const chatbots = collaborator.chatbots || [];

  // chatbots.forEach((item) => {
  //   if (item.platform === 'web') platforms.web = true;
  //   else if (item.platform === 'facebook') platforms.facebook = true;
  //   else platforms.whatsapp = true;
  // });

  const icons = [];
  if (platforms.web) icons.push(GrLanguage);
  if (platforms.facebook) icons.push(RiFacebookCircleFill);
  if (platforms.whatsapp) icons.push(RiWhatsappFill);
  return icons;
};

export default CollaboratorsTab;
