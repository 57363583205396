import { AiOutlineInfoCircle } from 'react-icons/ai';
import styles from './styles.module.css';

export interface CurrentViewProps {
  title: string;
}

function CurrentView({ title }: CurrentViewProps) {
  return (
    <div className={styles.content}>
      <div className={styles.view}>
        <AiOutlineInfoCircle size={35} />
        <h2>{title}</h2>
      </div>
    </div>
  );
}

export default CurrentView;
