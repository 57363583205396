/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Message, Media } from '@entities';
import { useModel } from '@flux/models/pages/chatContactPage/hooks';
import ConfirmReactivationModal from '@pages/Chat/components/ConfirmReactivationModal';
import { RiRestartLine } from 'react-icons/ri';
import socket, { NEW_CHAT_MESSAGE_EVENT, ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import DashboardMenu from '@components/DashboardMenu';
import AlertModal from '@components/AlertModal';
import { newMessageNotification } from '@utils/notifications';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import { ChatContactInfo } from './components/Header';
import BackButton from './components/BackButton';
import Messages from './components/Messages';
import InputMessage from './components/InputMessage';
import MediaModal from './components/MediaModal';
import styles from './styles.module.css';

interface ChatContactParams {
  id: string;
}

function ChatContact() {
  const { id } = useParams<ChatContactParams>();
  const {
    contact,
    messages,
    getContactInfo,
    sendMessage,
    addMessage,
    setMessages,
    reactivationMessage,
    error,
    setError,
  } = useModel();

  const defaultMedia = { media_content: '', message_type: 'TEXT' } as Media;

  const [reactivation, setReactivation] = useState('');
  const [reactivationError, setReactivationError] = useState('');
  const [media, setMedia] = useState<Media>(defaultMedia);

  const [sameChat, setSameChat] = useState(false);

  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, restartCountMessages, current } = Attendance.useModel();
  const firstUpdate = useRef(true);

  useEffect(() => {
    getContactInfo(id);
  }, [getContactInfo, id]);

  useEffect(() => {
    if (!firstUpdate.current && !sameChat) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
    if (sameChat == true) setSameChat(false);
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (contact) {
      setSameChat(true);
      socket.emit('room', `whatsapp:+55${contact.phone}`);
      // eslint-disable-next-line no-console
      console.log('room emit');
    }
  }, [contact]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  useEffect(() => {
    socket.on(NEW_CHAT_MESSAGE_EVENT, (message: Message) => {
      // eslint-disable-next-line no-console
      console.log('Message arrived', new Date().toISOString(), message);

      if (message.origin !== 'attendants') addMessage(message);
    });
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => {
      if (msg.user_id != `whatsapp:+55${contact?.phone}`){
        console.log(msg);
        getMessagesNotReadCounter(msg.user_id || '');
      }
    });

    return () => {
      socket.off(NEW_CHAT_MESSAGE_EVENT);
      socket.off(ONGOING_CHAT_MSG_EVENT);
      setMessages([]);
      localStorage.setItem(`contact-${id}`, new Date().toLocaleString());
    };
  }, [addMessage, id, setMessages]);

  const handleReactivation = async () => {
    if (reactivation) {
      setReactivation('');
      sendMessage({ message: reactivationMessage, bot_id: contact?.bot_id || '' });
    } else {
      setReactivation('ABRIR');
    }
  };

  const handleSetMedia = (type: string, content: string) => {
    const media_ = {
      message_type: type,
      media_content: content
    } as Media;
    setMedia(media_);
  };

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.backContainer}>
            <BackButton />
          </section>

          <section className={styles.infoContainer}>
            <ChatContactInfo
              phone={contact?.phone || ''}
              name={contact?.name || ''}
            />
          </section>

          <section className={styles.chatContainer}>
            <Messages messages={messages} setMedia={handleSetMedia} />
            <InputMessage
              enabled={true}
              onSubmit={(message) => sendMessage({ message, bot_id: contact?.bot_id || '' })}
            />
          </section>

          <div className={styles.options}>
            {
              reactivationMessage && reactivationMessage !== '' &&
                <button
                  type="button"
                  className={styles.reactivateItem}
                  onClick={handleReactivation}
                >
                  <RiRestartLine className={styles.icons} size={24} />
                  <h3>Reativar</h3>
                </button>
            }
          </div>
        </div>


        <AlertModal
          isOpen={!!error}
          onClose={() => setError('')}
          message={error}
        />


        <ConfirmReactivationModal
          isOpen={!!reactivation}
          message={reactivationMessage}
          onClose={() => {
            setReactivation('');
          }}
          confirm={handleReactivation}
          error={reactivationError}
          setError={setReactivationError}
        />


        <MediaModal
          isOpen={!!media && !!media.media_content}
          onClose={() => {
            setMedia(defaultMedia);
          }}
          media={media}
        />
      </DashboardMenu>
    </div>
  );
}

export default ChatContact;
