import { MdEdit, MdDone } from 'react-icons/md';
import { useState } from 'react';
import styles from './styles.module.css';

export interface EditableLabelProps {
  label: string;
  value?: string;
  onSubmit: (value: unknown) => void;
  mask?: (value: string) => string;
}

function EditableLabel({ label, value = '', onSubmit, mask }: EditableLabelProps) {
  const [toggle, setToggle] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [cleanValue, setCleanValue] = useState(value);

  const handleClick = () => {
    if (toggle) onSubmit(cleanValue);
    setToggle(!toggle);
  };

  const clearValue = (_value: string) => _value.replace(/[^\d]/g, '');

  const handleChange = (changeValue: string) => {
    const _cleanValue = clearValue(changeValue);
    const _maskedValue = mask ? mask(_cleanValue) : _cleanValue;
    setCurrentValue(_maskedValue);
    setCleanValue(_cleanValue);
  };
  return (
    <div className={styles.container}>
      <p>{label}</p>
      <div className={styles.current}>
        <input
          placeholder="Qualquer"
          value={currentValue}
          disabled={!toggle}
          onChange={(e) => handleChange(e.target.value)}
        />

        <button
          className={styles.btn}
          type="button"
          onClick={() => handleClick()}
        >
          {!toggle ? (
            <MdEdit size={18} style={{ color: 'black' }} />
          ) : (
            <MdDone size={18} style={{ color: 'black' }} />
          )}
        </button>
      </div>
    </div>
  );
}

export default EditableLabel;
