import { useEffect, useRef } from 'react';
import { Message } from '@entities';
import AudioMessage from './components/AudioMessage';
import ImageMessage from './components/ImageMessage';
import TextMessage from './components/TextMessage';
import VideoMessage from './components/VideoMessage';
import DocumentMessage from './components/DocumentMessage';
import FbMediaMessage from './components/FbMediaMessage';
import FbGenericMessage from './components/FbGenericMessage';
import FbButtonMessage from './components/FbButtonMessage';
import FbTextMessage from './components/FbTextMessage';
import styles from './styles.module.css';

interface MessageProps {
  messages: Message[];
  setMedia: (type: string, content: string) => void;
}

function Messages({ messages, setMedia }: MessageProps) {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  // return (
  //   <div className={styles.messages}>
  //     {messages.map((payload) => (
  //       <div
  //         key={Math.random()}
  //         className={
  //           payload.origin === 'user'
  //             ? styles.messageOrigin
  //             : styles.messageSender
  //         }
  //       >
  //         <p>{payload.message}</p>
  //       </div>
  //     ))}
  //     <div ref={messagesEndRef} />
  //   </div>
  // );

  const getMessage = (message: Message) => {
    const { body } = message;

    if (!body) {
      return <TextMessage key={Math.random()} message={message} />;
    }

    if (message.platform === 'facebook') {
      const payload = message.body?.payload || null;

      if (payload && payload.template_type === 'generic') {
        return <FbGenericMessage
          key={message.created_at}
          message={message}
        />;
      }

      if (payload && payload.template_type === 'media') {
        return <FbMediaMessage
          key={message.created_at}
          message={message}
          setMedia={setMedia}
        />;
      }

      if (payload && payload.template_type === 'button') {
        return <FbButtonMessage
          key={message.created_at}
          message={message}
        />;
      }

      if (!payload && message.body?.quick_replies) {
        return <FbTextMessage
          key={message.created_at}
          message={message}
        />;
      }

      return <TextMessage key={Math.random()} message={message} />;
    }

    const { message_type } = body;

    switch (message_type) {
      case 'AUDIO':
        return <AudioMessage 
          key={Math.random()} 
          message={message} 
          setMedia={setMedia} 
        />;
      case 'IMAGE':
        return <ImageMessage 
          key={Math.random()} 
          message={message} 
          setMedia={setMedia} 
        />;
      case 'VIDEO':
        return <VideoMessage 
          key={Math.random()} 
          message={message} 
          setMedia={setMedia} 
        />;
      case 'DOCUMENT':
        return <DocumentMessage 
          key={Math.random()} 
          message={message}  
        />;
      default:
        return <TextMessage key={Math.random()} message={message} />;
    }
  };

  return (
    <div className={styles.messages}>
      {messages.map((payload) => getMessage(payload))}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default Messages;
