import { AiFillCloseCircle } from 'react-icons/ai';
import Modal from '@components/Modal';
import { Contact } from '@entities';
import { useLoading } from '@flux/models/pages/contactsPage/hooks';
import { ImSpinner9 } from 'react-icons/im';
import ImportView from '@pages/Contacts/components/ContactModal/components/ImportView';
import CreateView from './components/CreateView';
import RemoveView from './components/RemoveView';
import UpdateView from './components/UpdateView';
import styles from './styles.module.css';

export type ViewType = 'create' | 'update' | 'remove' | 'import';

export interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  contacts: Contact[];
  selectedContact?: Contact
  type?: ViewType;
}

function ContactModal({ isOpen, onClose, contacts, type = 'create', selectedContact }: ContactModalProps) {
  const { loadingImportingContacts, loadingCreateContacts } = useLoading();

  const viewComponent = {
    create: <CreateView contacts={contacts} onClose={onClose} />,
    import: <ImportView contacts={contacts} onClose={onClose} />,
    update: <UpdateView contacts={contacts} onClose={onClose} selectedContact={selectedContact} />,
    remove: <RemoveView contacts={contacts} onClose={onClose} selectedContact={selectedContact} />,
  };

  const Loading = () => (
    <div className={styles.loading}>
      <div className={styles.spin}>
        <ImSpinner9 className="spin" />
      </div>
    </div>
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      {isOpen && (loadingImportingContacts || loadingCreateContacts) && <Loading />}
      <header className={styles.header}>
        <h1>Contatos</h1>
        <button type="button" onClick={onClose}>
          <AiFillCloseCircle size={30} color="#999" />
        </button>
      </header>

      {viewComponent[type]}
    </Modal>
  );
}

export default ContactModal;
