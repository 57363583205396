import { useEffect, useState, useCallback, MouseEvent, useMemo, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import * as Application from '@flux/models/application/hooks';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import DashboardMenu from '@components/DashboardMenu';
import photo from '@assets/person.png';
import SubmitButton from '@components/SubmitButton';
import ProfileInfo from '@components/ProfileInfo';
import { useHistory } from 'react-router-dom';
import { useModel } from '@flux/models/pages/profilePage/hooks';
import { formatDate } from '@utils/strings';
import { Message } from '@entities';
import { newMessageNotification } from '@utils/notifications';
import ProfileInput from './components/ProfileInput';
import styles from './styles.module.css';

function EditProfile() {
  const { user } = Application.useModel();
  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();

  const history = useHistory();

  const { updateProfile, updateAvatar } = useModel();

  useEffect(() => {
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, [user]);

  const [nameValue, setNameValue] = useState(user?.name);
  const [emailValue, setEmailValue] = useState(user?.email);
  const [companyValue, setCompanyValue] = useState(user?.company);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const firstUpdate = useRef(true);

  const preview = useMemo(
    () => (profileImage ? URL.createObjectURL(profileImage) : null),
    [profileImage]
  );

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  const handleUptade = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      updateProfile({
        newName: nameValue,
        newEmail: emailValue,
        newCompany: companyValue,
      });
      updateAvatar(profileImage);
      history.push('/perfil');
    },
    [nameValue, emailValue, profileImage, companyValue]
  );

  const getRole = (role: string) => {
    if (role === 'manager') return 'Gerente';
    return 'Atendente';
  };

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Meu perfil</h1>
              <p>Visualize seus dados e edite informações</p>
            </div>

            <div className={styles.lastAccess}>
              <h1>Último acesso</h1>
              <p>
                {user?.last_access ?
                  formatDate(user?.last_access || '').substring(0, 10) :
                  formatDate(user?.created_at || '').substring(0, 10)}
              </p>
            </div>
          </section>

          <section className={styles.profileData}>
            <div className={styles.photoSection}>
              <label
                htmlFor="avatar"
                style={{
                  backgroundImage: `url(${preview})`,
                  backgroundSize: '370px 370px',
                }}
                className={preview ? styles.profileAvatar : ''}
              >
                <div className={styles.photoButton}>
                  {/* @ts-ignore: Object is possibly 'null'. */}
                  <input
                    type="file"
                    id="avatar"
                    onChange={(event) => {
                      if (event.target.files) {
                        setProfileImage(event.target.files[0]);
                      }
                    }}
                  />
                </div>
                <img
                  className={styles.profileAvatar}
                  src={user?.avatar_url || photo}
                  alt="Gerente"
                />
              </label>
            </div>

            <div className={styles.data}>
              <ProfileInput
                dataLabel="Nome completo"
                placeholder={user?.name}
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
              />

              <ProfileInput
                dataLabel="E-mail"
                placeholder={user?.email}
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />

              <ProfileInput
                dataLabel="Empresa"
                placeholder={user?.company}
                value={companyValue}
                backgroundColor="#BCBCBC"
                onChange={(e) => setCompanyValue(e.target.value)}
                disabled
              />

              <div className={styles.block}>
                <ProfileInfo
                  dataLabel="Cargo"
                  data={getRole(user?.type || '')}
                  width="95%"
                  backgroundColor="#BCBCBC"
                  justifyContent="center"
                  paddingLeft="0"
                />

                <ProfileInfo
                  dataLabel="Cadastro"
                  data={formatDate(user?.created_at || '').substring(0, 10)}
                  width="95%"
                  backgroundColor="#BCBCBC"
                  justifyContent="center"
                  paddingLeft="0"
                  marginLeft="3%"
                />
              </div>
              <div className={styles.button}>
                <SubmitButton
                  label="Confirmar Alterações"
                  onClick={handleUptade}
                  backgroundColor="#F8270A"
                />
              </div>
            </div>
          </section>
        </div>
      </DashboardMenu>
    </div>
  );
}

export default EditProfile;
