import { GrLanguage } from 'react-icons/gr';
import { RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import { CgTrash } from 'react-icons/cg';
import { Chatbot } from '@entities';
import LoadingSpinner from '@components/LoadingSpinner';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';


interface ChatBotCardProps {
  chatbot: Chatbot;
  setChatbot: (item: Chatbot | null) => void;
}

function ChatBotCard({ chatbot, setChatbot }: ChatBotCardProps) {

  const { error, deleteBot } = AdminHook.useModel();
  const { loadingDeleteBot } = AdminHook.useLoading();

  const getIcon = () => {
    if (chatbot.platform === 'facebook')
      return <RiFacebookCircleFill size={20} />;
    if (chatbot.platform === 'whatsapp') return <RiWhatsappFill size={20} />;
    return <GrLanguage size={18} />;
  };

  const handleDeleteBot = async () => {
    await deleteBot(chatbot);
    if (!error) {
      setChatbot && setChatbot(null);
    } else {
      console.log('error delete bot', error);
    }
  };

  return (
    <div className={styles.cardColaborator}>
      <section className={styles.cardHeader}>
        <h1>{chatbot.name}</h1>
      </section>
      <section className={styles.cardBody}>
        <div className={styles.line}>
          <span>ID</span>
          <h1>{chatbot.id}</h1>
        </div>
        <div className={styles.line}>
          <span>url</span>
          <h1>{chatbot.url}</h1>
        </div>
        <div className={styles.line}>
          <span>Plataforma</span>
          <h1 className={styles.plataform}>
            {getIcon()}
            {chatbot.platform}
          </h1>
        </div>
        <div className={styles.line}>
          <span>Data de criação</span>
          <h1>{chatbot.created_at}</h1>
        </div>
        
        <div className={styles.remove}>
          <CgTrash color="white" />
          <button type="button" onClick={handleDeleteBot}>
            {loadingDeleteBot ? <LoadingSpinner /> : 'Excluir Chatbot'}
          </button>
        </div>
      </section>
    </div>
  );
}

export default ChatBotCard;
