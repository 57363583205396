import { ChangeEvent, useEffect, useState } from 'react';
import { ImSpinner9 } from 'react-icons/im';
import { User, Team, TaskTeam } from '@entities';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import { useLoading } from '@flux/models/pages/adminPage/hooks';
import { useModel } from '@flux/models/application/hooks';
import styles from './styles.module.css';

interface EditCollaboratorTeamsProps {
  collaborator: User;
}

interface CellTeamProps {
  team: Team;
  onChange: (id: string) => void;
  selected: string[];
}

function CellTeam({ team, onChange, selected }: CellTeamProps) {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(team.id);
  };

  return (
    <div className={styles.cellTeam}>
      <section className={styles.teamInfo}>
        <div className={styles.teamInfoLine}>
          <h1>{team.name}</h1>
        </div>
        <div className={styles.teamInfoLine}>
          <span>ID: {team.id}</span>
        </div>
      </section>
      <section>
        <div className={styles.checkOpen}>
          <input
            type="checkbox"
            id={team.id}
            defaultChecked={selected.includes(team.id)}
            onChange={handleCheckboxChange}
          />
        </div>
      </section>
    </div>
  );
}

const Loading = () => (
  <>
    <ImSpinner9 className="spin" />
  </>
);

function EditCollaboratorTeams({ collaborator }: EditCollaboratorTeamsProps) {
  const { user } = useModel();
  const {
    teams,
    getTeams,
    updateTeams
  } = AdminHook.useModel();
  const { loadingUpdateTeams } = useLoading();
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    let company = '';
    if (user?.type === 'manager') {
      company = user?.company;
    }
    getTeams(company);
  }, [getTeams, user]);

  useEffect(() => {
    setSelected(collaborator.teams || []);
  }, [collaborator]);

  const updateList = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(e => e !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSave = async () => {
    localStorage.setItem('tabAdmin', 'colaboradores');
    const tasks: TaskTeam[] = [];
    teams.forEach(team => {
      if (!collaborator.teams) {
        collaborator.teams = [];
      }

      if (
        collaborator.teams.includes(team.id) &&
        !selected.includes(team.id)) {
        tasks.push({ id_team: team.id, operation: 'delete' });
      } else if (
        !collaborator.teams.includes(team.id) &&
        selected.includes(team.id)) {
        tasks.push({ id_team: team.id, operation: 'put' });
      }
    });
    if (tasks) {
      await updateTeams({
        user: collaborator,
        tasks
      });
    }
    window.location.reload();
  };

  const handleCancel = () => {
    localStorage.setItem('tabAdmin', 'colaboradores');
    window.location.reload();
  };

  return (
    <div className={styles.content}>
      <div className={styles.contentHeader}>
        <h1>Agente: {collaborator.name}</h1>
      </div>
      <div className={styles.contentBody}>
        <div className={styles.teamsList}>
          {teams.map((team) => (
            <CellTeam
              key={Math.random()}
              team={team}
              onChange={updateList}
              selected={selected}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          <button className={styles.save} type="button" onClick={handleSave}>
            {loadingUpdateTeams ? <Loading /> : 'Salvar'}
          </button>
          <button className={styles.cancel} type="button" onClick={handleCancel}>Cancelar</button>
        </div>
      </div>
    </div>
  );
}
export default EditCollaboratorTeams;
