import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Attendance, User } from '@entities';
import api, { errorHandler } from '@services/api';
import {
  AttendantResult,
  GetHistoryPayload,
  HistoryPageState,
  Result,
  SearchByNamePayload,
  UserResult,
} from './entities';

export const historyPage = createModel<RootModel>()({
  state: {
    error: '',
    attendances: [],
    results: [],
    getHistoryPayload: null,
    hasNextPage: false,
  } as HistoryPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setAttendances(state, payload: Attendance[]) {
      state.attendances = payload;
      return state;
    },

    setResults(state, payload: Result[]) {
      state.results = payload;
      return state;
    },

    setGetHistoryPayload(state, payload: GetHistoryPayload) {
      state.getHistoryPayload = payload;
      return state;
    },

    setHasNextPage(state, payload: boolean) {
      state.hasNextPage = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async getHistory(payload, state) {
      const { setError, setAttendances, setHasNextPage } = dispatch.historyPage;
      const { user } = state.application;
      const { getHistoryPayload } = state.historyPage;

      if (!getHistoryPayload) return;

      const url = getHistoryUrl(getHistoryPayload, user, 'get');

      await errorHandler(async () => {
        const response = await api.get(url);
        const { attendances, hasNext } = response.data;

        setAttendances(attendances);
        setHasNextPage(hasNext);
      }, setError);
    },

    async exportHistory(payload, state) {
      const { setError } = dispatch.historyPage;
      const { user } = state.application;
      const { getHistoryPayload } = state.historyPage;

      if (!getHistoryPayload) return;

      const url = getHistoryUrl(getHistoryPayload, user, 'export');

      await errorHandler(async () => {
        const response = await api.get(url);
        const { link } = response.data;

        window.open(link, '_blank');
      }, setError);
    },

    async searchByName(payload: SearchByNamePayload, state) {
      const { name, mode } = payload;
      const { setError, setResults } = dispatch.historyPage;

      await errorHandler(async () => {
        const response = await api.get(`/users?name=${name}`);
        const results = response.data.results[mode];

        if (mode === 'attendants') {
          setResults(
            results.map((item: AttendantResult) => {
              const hasAttendances = item.attendances.length > 0;
              const lastProtocol = hasAttendances
                ? item.attendances[0].protocol
                : undefined;
              const lastClosedAt = hasAttendances
                ? item.attendances[0].closed_at
                : undefined;

              return {
                id: item.id,
                name: item.name,
                lastProtocol,
                lastClosedAt,
              };
            })
          );
        } else {
          setResults(
            results.map((item: UserResult) => ({
              id: item._id,
              name: item.user_name,
              lastProtocol: item.protocol,
              lastClosedAt: item.closed_at,
            }))
          );
        }
      }, setError);
    },
  }),
});

function getHistoryUrl(
  payload: GetHistoryPayload,
  user: User | null,
  mode: 'get' | 'export'
) {
  const {
    page = 1,
    since,
    contentSize = 25,
    until,
    attendant_id,
    client,
    opened_at,
    closed_at,
    platform,
    protocol,
    user_id,
    tag_name,
    phone_number,
    team
  } = payload;

  const route = mode === 'get' ? 'history' : 'history-export';
  const url = new URL(`${process.env.REACT_APP_API}/attendances/${route}`);
  url.searchParams.append('since', since);
  url.searchParams.append('until', until);

  if (mode === 'get') {
    const skip = (page - 1) * contentSize;
    url.searchParams.append('skip', String(skip));
    url.searchParams.append('limit', String(contentSize));
  }

  if (user?.type === 'attendant') {
    url.searchParams.append('attendant_id', user.id);
  } else if (user && attendant_id) {
    url.searchParams.append('attendant_id', attendant_id);
  }

  if (protocol) {
    url.searchParams.append('protocol', protocol);
  }

  if (client) {
    url.searchParams.append('client', client);
  }

  if (platform) {
    url.searchParams.append('platform', platform);
  }

  if (user_id) {
    url.searchParams.append('user_id', user_id);
  }

  if (opened_at) {
    url.searchParams.append('opened_at', opened_at);
  }

  if (closed_at) {
    url.searchParams.append('closed_at', closed_at);
  }

  if (tag_name) {
    url.searchParams.append('tag_name', tag_name);
  }

  if (phone_number) {
    url.searchParams.append('phone_number', phone_number);
  }

  if (team) {
    url.searchParams.append('team_id', team);
  }

  return url.href;
}
