export function formatNumber(number: number) {
  return number.toLocaleString('pt-BR', { maximumFractionDigits: 2 });
}

export function formatDate(date: string) {
  return new Date(date).toLocaleString('pt-BR');
}

export function dateLabel(date: string) {
  const date_ = formatDate(date).split(' ');
  const day = date_[0];
  const hour = date_[1];
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const today_ = formatDate(today.toISOString()).split(' ')[0];
  const yesterday_ = formatDate(yesterday.toISOString()).split(' ')[0];

  if (day === today_) {
    return `Hoje - ${hour}`;
  } if (day === yesterday_) {
    return `Ontem - ${hour}`;
  }
  return `${day} - ${hour}`;
}

export const getAccess = (access: string) => {
  if (access) return formatDate(access);
  return '---';
};

export function limitStringSize(string: string, size: number) {
  if (string.length > size) return `${string.slice(0, size)}...`;

  return string;
}

export function phoneFormat(phoneString: string) {

  if (!phoneString.includes(':')) {
    return phoneString;
  }
  
  const phone = phoneString.split(':')[1];
  let resultPhone = `${phone.substr(0, 3)} (${phone.substr(3, 2)}) `;
  let count = 0;

  for (let i = 5; i < phone.length; i++) {
    if (phone[i] >= '0' && phone[i] <= '9') {
      count += 1;
      if (count == 5) {
        count = 0;
        resultPhone += '-';
      }
    } else {
      count = 0;
    }
    resultPhone += phone[i];
  }

  return resultPhone;
}

