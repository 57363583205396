import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { User } from '@entities';
import api, { errorHandler } from '@services/api';

export interface LoginPageState {
  error: string;
  success: boolean;
  registerIncomplete: boolean;
}

interface HandleLoginPayload {
  email: string;
  password: string;
}

export const loginPage = createModel<RootModel>()({
  state: {
    error: '',
    success: false,
    registerIncomplete: false
  } as LoginPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setSuccess(state, payload: boolean) {
      state.success = payload;
      return state;
    },

    setRegisterIncomplete(state, payload: boolean) {
      state.registerIncomplete = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async handleLogin(payload: HandleLoginPayload, state) {
      const { setLogged } = dispatch.application;
      const { setSuccess, setError } = dispatch.loginPage;
      const { email, password } = payload;

      await errorHandler(async () => {
        const response = await api.post('/sessions', {
          email,
          password,
        });
        const { user, token } = response.data;

        const newUser: User = {
          ...user,
          login_type: user.create_type,
        };

        setLogged({ token, user: newUser });
        setSuccess(true);
      }, setError);
    },

    async handleGoogle(payload, state) {
      const { setError } = dispatch.loginPage;

      await errorHandler(async () => {
        const response = await api.get('/sessions/google-login');
        const { url } = response.data;

        window.open(url, '_self');
      }, setError);
    },

    async sendGoogleCode(payload: string, state) {
      const { setLogged } = dispatch.application;
      const { 
        setSuccess, 
        setError, 
        setRegisterIncomplete 
      } = dispatch.loginPage;

      await errorHandler(async () => {
        const response = await api.get(`/sessions/google-info?code=${payload}`);
        const { user, token } = response.data;
        const newUser: User = {
          ...user,
          avatar_url: user.picture,
          login_type: 'google',
          type: 'attendant',
        };

        if (!token) {
          setRegisterIncomplete(true);
          setLogged({ token, user: newUser });
        } else {
          setLogged({ token, user: newUser });
          setSuccess(true);
        }

      }, setError);
    },
  }),
});
