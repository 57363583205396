import { InputHTMLAttributes, useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import styles from './styles.module.css';

export interface SeachInputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerWidth?: string;
}

function SearchInput({
  onClick,
  value,
  containerWidth = '33%',
  ...props
}: SeachInputProps) {
  const [inputSelected, setInputSelected] = useState(false);

  useEffect(() => {
    setInputSelected(!!value);
  }, [value]);

  return (
    <div
      className={`${styles.searchInput} ${
        inputSelected ? styles.selectedInput : ''
      }`}
      style={{ width: containerWidth }}
    >
      <BsSearch size={20} />
      <input {...props} />
    </div>
  );
}

export default SearchInput;
