import { ImBin, ImPencil } from 'react-icons/im';
import { useState } from 'react';
import ContactModal, { ViewType } from '@pages/Contacts/components/ContactModal';
import { Contact } from '@entities';
import styles from './styles.module.css';

export interface ContactOptionsProps {
  contacts: Contact[];
  selectedContact: Contact
}

function ContactOptions({ contacts, selectedContact }: ContactOptionsProps) {

  const [showContactModal, setShowContactModal] = useState(false);
  const [modalType, setModalType] = useState<ViewType>('update');

  const handleUpdate = () => {
    setModalType('update');
    setShowContactModal(true);
  };

  const handleRemove = () => {
    setModalType('remove');
    setShowContactModal(true);
  };

  return (
    <>
      <ContactModal
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        contacts={contacts}
        type={modalType}
        selectedContact={selectedContact}
      />
      <div className={styles.contactOptionsContainer}>
        <div>
          <ImPencil className={styles.iconPencil} size={20} color="#4a69bd" onClick={handleUpdate} />
          <ImBin className={styles.iconBin} size={20} color="#4a69bd" onClick={handleRemove} />
        </div>
      </div>
    </>
  );
}

export default ContactOptions;
