import { useEffect, useState } from 'react';
import { BsChatDots, BsPersonFill } from 'react-icons/bs';
import { MdPersonAdd } from 'react-icons/md';
import { FaBuilding } from 'react-icons/fa';
import TabSwitch, { TabItem } from '@components/TabSwitch';
import ChatbotsTab from '@pages/Admin/AdminView/components/ChatbotsTab';
import CollaboratorsTab from '@pages/Admin/components/CollaboratorsTab';
import CompaniesTab from '@pages/Admin/AdminView/components/CompaniesTab';
import RequestsTab from '@pages/Admin/components/RequestsTab';
import { Chatbot, Company, Team, User } from '@entities';
import styles from './styles.module.css';

interface AdminProps {
  chatbots: Chatbot[];
  collaborators: User[];
  requests: User[];
  companies: Company[];
  user: User | null;
  teams: Team[]
}

function Admin({ chatbots, collaborators, 
  requests, companies, user, teams }: AdminProps) {
  const [tabSelected, setTabSelected] = useState('chatbots');
  
  const getTabComponent = () => {
    if (tabSelected === 'chatbots') {
      return <ChatbotsTab chatbots={chatbots} />;
    }
    if (tabSelected === 'colaboradores') {
      return <CollaboratorsTab collaborators={collaborators} user={user} teams={teams} />;
    }
    if (tabSelected === 'solicitacoes') {
      return <RequestsTab requests={requests} origem={user?.type || ''} />;
    }
    return <CompaniesTab companies={companies} />;
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1>Painel Administrativo</h1>
          <p>Gerencie os seus chatbots e colaboradores</p>
        </div>
        <TabSwitch onSelect={setTabSelected} options={TAB_OPTIONS} />
      </div>
      <div>{getTabComponent()}</div>
    </section>
  );
}

export default Admin;

const TAB_OPTIONS: TabItem[] = [
  {
    label: 'Chatbots',
    value: 'chatbots',
    icon: BsChatDots,
  },
  {
    label: 'Colaboradores',
    value: 'colaboradores',
    icon: BsPersonFill,
  },
  {
    label: 'Solicitações',
    value: 'solicitacoes',
    icon: MdPersonAdd,
  },
  {
    label: 'Empresas',
    value: 'empresas',
    icon: FaBuilding,
  },
];
