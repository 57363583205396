import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Attendance, Message } from '@entities';
import api, { errorHandler } from '@services/api';

export interface AttendancePageState {
  error: string;
  available: boolean;
  averageAttendance: number;
  averageWaiting: number;
  completedAttendances: number;
  pending: Attendance[];
  current: Attendance[];
  messagesNotReadCounter: { [index:string]:number };
  lastMsgSender: string;
  lastMsgSenderName: string;
  pendingInterval?: NodeJS.Timer;
  inactiveAttendances: Attendance[];
  unrespondedAttendances: Attendance[];
  skip: number;
  hasNextPage: boolean;
  limit: number;
  order: number;
}

export const attendancePage = createModel<RootModel>()({
  state: {
    error: '',
    available: false,
    averageAttendance: 0,
    averageWaiting: 0,
    completedAttendances: 0,
    pending: [],
    current: [],
    messagesNotReadCounter:{},
    lastMsgSender:'',
    lastMsgSenderName: '',
    pendingInterval: undefined,
    inactiveAttendances: [],
    unrespondedAttendances: [],
    hasNextPage: false,
    limit: 100,
    skip: 0,
    order: -1,
  } as AttendancePageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setAvailable(state, payload: boolean) {
      state.available = payload;
      return state;
    },

    setCurrent(state, payload: Attendance[]) {
      state.current = payload;
      return state;
    },

    setPending(state, payload: Attendance[]) {
      state.pending = payload;
      return state;
    },

    setAverageAttendance(state, payload: number) {
      state.averageAttendance = payload;
      return state;
    },

    setAverageWaiting(state, payload: number) {
      state.averageWaiting = payload;
      return state;
    },

    setCompletedAttendances(state, payload: number) {
      state.completedAttendances = payload;
      return state;
    },

    setMessagesNotReadCounter(state, id:string, payload:number) {
      // eslint-disable-next-line no-console
      console.log('uiuiui');
      // eslint-disable-next-line no-console
      console.log(payload);
      const { ...newCounter } = state.messagesNotReadCounter;
      newCounter[id] = payload;
      state.messagesNotReadCounter = newCounter;
      return state;
    },

    setLastMsgSender(state, payload: string) {
      state.lastMsgSender = payload;
      return state;
    },

    setLastMsgSenderName(state, payload: string){
      state.lastMsgSenderName = state.current.find(attend=>attend.user_id == payload)?.user_name || payload;
      return state;
    },

    setPendingInterval(state, payload: NodeJS.Timer) {
      if (state.pendingInterval !== undefined) {
        clearInterval(state.pendingInterval);
      }
      state.pendingInterval = payload;
      return state;
    },
    // next page para paginação
    setHasNextPage(state, payload: boolean) {
      state.hasNextPage = payload;
      return state;
    },

    setInactiveAttendances(state, payload: Attendance[]) {
      state.inactiveAttendances = payload;
      return state;
    },

    setUnrespondedAttendances(state, payload: Attendance[]) {
      state.unrespondedAttendances = payload;
      return state;
    },

    setSkip(state, payload: number) {
      state.skip = payload;
      return state;
    },

    setOrder(state, payload: number) {
      state.order = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async toggleAvailability(payload, state) {
      const { available } = state.attendancePage;
      const { setAvailable, setError } = dispatch.attendancePage;

      await errorHandler(async () => {
        await api.put('/attendant/status', {
          available: !available,
        });
        setAvailable(!available);
      }, setError);
    },

    async getMessagesNotReadCounter(payload:string, state) {
      const { messagesNotReadCounter } = state.attendancePage;
      const { setMessagesNotReadCounter, setLastMsgSender, setLastMsgSenderName } = dispatch.attendancePage;
      const count = (messagesNotReadCounter[payload] || 0) + 1;
      setLastMsgSender(payload);
      setLastMsgSenderName(payload);
      setMessagesNotReadCounter(payload, count);
    },

    async restartCountMessages(payload: string, state){
      const { setMessagesNotReadCounter } = dispatch.attendancePage;
      setMessagesNotReadCounter(payload, 0);
    },

    async getAvailability() {
      const { setAvailable, setError } = dispatch.attendancePage;

      await errorHandler(async () => {
        const response = await api.get('/profile');
        const { available } = response.data;

        setAvailable(available);
      }, setError);
    },

    async getAttendances(payload, state) {
      const { setCurrent, setPending, setError, setHasNextPage } = dispatch.attendancePage;
      const { limit, skip, order } = state.attendancePage;
      
      await errorHandler(async () => {
        const response = await api.get(`/attendances?limit=${limit}&skip=${skip}&order=${order}`);
        const { 
          peding, 
          current,
          hasNext
        } = response.data;

        setPending(peding);
        setHasNextPage(hasNext);
        setCurrent(current.filter((att: Attendance) => !att.closed_at));
      }, setError);
    },

    // Usuários inativos
    async getInactiveUsers(payload: { user_id: string, client: string } ) {
      const { setInactiveAttendances, setError } = dispatch.attendancePage;

      await errorHandler(async () => {
        const response = await api.get(`/attendances/inactive-attendances?user_id=${payload.user_id}&client=${payload.client}`);
        const attendances = response.data;

        setInactiveAttendances(attendances);
      }, setError);
    },

    // Usuários inativos
    async getUnrespondedUsers(payload: { user_id: string, client: string } ) {
      const { setUnrespondedAttendances, setError } = dispatch.attendancePage;

      await errorHandler(async () => {
        const response = await api.get(`/attendances/unresponded-attendances?user_id=${payload.user_id}&client=${payload.client}`);
        const attendances = response.data;

        console.log('unresponded users', response);
        setUnrespondedAttendances(attendances);
      }, setError);
    },

    async updateInactiveUsers(payload: { user_id: string, bot_id: string, client: string } ) {
      const { setError } = dispatch.attendancePage;

      await errorHandler(async () => {
        const response = await api.post(`/attendances/user-message?user_id=${payload.user_id}&bot_id=${payload.bot_id}&client=${payload.client}`);
      }, setError);
    },

    async getAnalytics() {
      const {
        setAverageAttendance,
        setAverageWaiting,
        setCompletedAttendances,
        setError,
      } = dispatch.attendancePage;

      await errorHandler(async () => {
        const response = await api.get('/attendances/analytics');
        const {
          average_attendance,
          average_waiting,
          completed_attendances,
        } = response.data;

        setAverageAttendance(average_attendance);
        setAverageWaiting(average_waiting);
        setCompletedAttendances(completed_attendances);
      }, setError);
    },

    async openAttendence(payload: number) {
      const { setError } = dispatch.attendancePage;

      await errorHandler(async () => {
        await api.get(`/attendances/open?protocol=${payload}`);
      }, setError);
    },
  }),
});
