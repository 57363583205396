import { useState, useEffect, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Message } from '@entities';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import { useModel, useLoading } from '@flux/models/pages/contactsPage/hooks';
import DashboardMenu from '@components/DashboardMenu';
import Table from '@components/Table';
import RegularPicker from '@components/RegularPicker';
import ErrorMessage from '@components/ErrorMessage';
import { newMessageNotification } from '@utils/notifications';
import ContactModal from '@pages/Contacts/components/ContactModal';
import EditableLabel from '@components/EditableLabel';
import optionsImg from '@assets/history/options.png';
import Paginator from './components/Paginator';

import styles from './styles.module.css';
import {
  CONTENT_OPTIONS,
  CONTACT_COLUMNS,
  contactsToLineItem,
} from './tableInfos';

function Contacts() {
  const {
    getContacts,
    contacts,
    setGetContactPayload,
    hasNextPage,
    error,
    setError
  } = useModel();

  const { loadingContacts } = useLoading();
  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();

  const [contentSize, setContentSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [phone_number, setPhone] = useState('');
  const [showContactModal, setShowContactModal] = useState(false);
  const [showImportContactModal, setShowImportContactModal] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    setGetContactPayload({
      contentSize,
      page: currentPage,
      phone_number
    });
    getContacts();
  }, [contentSize, currentPage, getContacts, phone_number, setGetContactPayload]);

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, []);

  return (
    <div className="app-container">
      {error && <ErrorMessage message={error} setError={setError} />}
      <ContactModal isOpen={showContactModal} onClose={() => setShowContactModal(false)} contacts={contacts} />
      <ContactModal isOpen={showImportContactModal} onClose={() => setShowImportContactModal(false)} contacts={contacts} type="import" />
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Contatos</h1>
              <p>Veja seus contatos</p>
            </div>
            <div>
              <button type="button" className={styles.addContactButton} onClick={() => setShowContactModal(true)}>Adicionar Contato</button>
              <button type="button" className={styles.importContactButton} onClick={() => setShowImportContactModal(true)}>Importar Contatos</button>
            </div>
          </section>

          <section className={styles.tableContainer}>
            <Table
              width="80%"
              height="80vh"
              title="Seus contatos"
              columns={CONTACT_COLUMNS}
              data={contactsToLineItem(contacts)}
              loading={loadingContacts}
              topRightItem={
                <div className={styles.tableOptions}>
                  <img src={optionsImg} alt="Itens por página" />
                  <RegularPicker
                    label="Itens por página"
                    onSelect={(selected) => setContentSize(Number(selected))}
                    options={CONTENT_OPTIONS}
                  />
                </div>
              }
            />

            <div className={styles.filtersContainer}>
              <div>
                <EditableLabel
                  label="Número de telefone"
                  onSubmit={(value) => setPhone(String(value))}
                />
              </div>

              <Paginator
                current={currentPage}
                hasNext={hasNextPage}
                nextPage={() => setCurrentPage(currentPage + 1)}
                previousPage={() => {
                  if (currentPage > 1) setCurrentPage(currentPage - 1);
                }}
              />
            </div>
          </section>
        </div>
      </DashboardMenu>
    </div>
  );
}

export default Contacts;
