import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import api, { errorHandler } from '@services/api';

export interface SendEmailPageState {
  error: string;
  success: boolean;
}

interface SendEmailPayload {
  email: string;
}

export const sendEmailPage = createModel<RootModel>()({
  state: {
    error: '',
    success: false,
  } as SendEmailPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setSuccess(state, payload: boolean) {
      state.success = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async sendEmail(payload: SendEmailPayload, state) {
      const { setError, setSuccess } = dispatch.sendEmailPage;

      await errorHandler(async () => {
        await api.post('/password/forgot', payload);
        setSuccess(true);
      }, setError);
    },
  }),
});
