import { DateTime } from 'luxon';

export type PeriodLabel =
  | 'Hoje'
  | 'Ontem'
  | 'Semana atual'
  | 'Última semana'
  | 'Mês atual'
  | 'Último mês'
  | 'Intervalo personalizado';

export interface Period {
  since: Date;
  until: Date;
}

type GetPeriodsFunction = () => { [label: string]: Period };

export const getPeriods: GetPeriodsFunction = () => ({
  today: {
    since: DateTime.now().startOf('day').toJSDate(),
    until: DateTime.now().toJSDate(),
  },

  yesterday: {
    since: DateTime.now().minus({ days: 1 }).startOf('day').toJSDate(),
    until: DateTime.now().minus({ days: 1 }).endOf('day').toJSDate(),
  },

  currentWeek: {
    since: DateTime.now().minus({ days: 6 }).startOf('day').toJSDate(),
    until: DateTime.now().toJSDate(),
  },

  lastWeek: {
    since: DateTime.now().minus({ days: 13 }).startOf('day').toJSDate(),
    until: DateTime.now().minus({ weeks: 1 }).endOf('day').toJSDate(),
  },

  currentMonth: {
    since: DateTime.now().minus({ months: 1 }).startOf('day').toJSDate(),
    until: DateTime.now().toJSDate(),
  },

  lastMonth: {
    since: DateTime.now().minus({ months: 2 }).startOf('day').toJSDate(),
    until: DateTime.now()
      .minus({ months: 1 })
      .minus({ days: 1 })
      .endOf('day')
      .toJSDate(),
  },
});
