import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Message, ContactToImport } from '@entities';
import api, { errorHandler } from '@services/api';
import socket, {
  SEND_TEMPLATE_PAYLOAD,
} from '@services/socket';

export interface BulkMessagingPageState {
  error: string;
}

export const bulkMessagingPage = createModel<RootModel>()({
  state: {
    error: '',
  } as BulkMessagingPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async bulkMessaging(payload: { message: string, contact: ContactToImport, bot_id: string, company: string, postback: string, positusCode?: string }, state) {
      const { setError } = dispatch.bulkMessagingPage;
      let plataformId = '';

      await errorHandler(async () => {
        const res = await api.get(`/bots/bot?bot_id=${payload.bot_id}`);
        plataformId = res.data.plataform_id;
      }, setError);

      if (payload.contact.telefone && plataformId) {
        const newMessage = createBulkMessage(payload.message, payload.contact.telefone, payload.bot_id, payload.company, plataformId, payload.positusCode, payload.contact );
        socket.emit(SEND_TEMPLATE_PAYLOAD, newMessage, payload.postback );
        return true;
      }

      return false;
    },

    async getBotMessageTemplates(payload: { bot_id:string }, state):Promise<{ title: string, text: string, postback: string, code?: string }[]>{
      const { setError } = dispatch.bulkMessagingPage;
      let templates: { title: string, text: string, postback: string, code?: string }[] = [];

      await errorHandler(async () => {
        const res = await api.get(`/bots/bot?bot_id=${payload.bot_id}`);
        // eslint-disable-next-line no-console
        console.log(res.data);
        templates = res.data.message_templates || [];
      }, setError);
      // eslint-disable-next-line no-console
      console.log('fim da função');
      return templates; 
    }
  }),
});

const createBulkMessage = (content: string, phone: string, bot_id: string, company: string, plataformId: string, templateCode?: string, contactData?: object): Message => ({
  platform_id: plataformId,
  user_id: `whatsapp:${phone}`,
  platform: 'whatsapp',
  message: content,
  created_at: new Date().toISOString(),
  origin: 'attendants',
  client: company || '',
  attendant_name: '',
  bot_id,
  templateCode,
  contactData
});
