import { ContactToImport } from '@entities';
import styles from './styles.module.css';

interface CorrectViewProps {
  contacts?: ContactToImport[],
  message: string
}

function CorrectView({ contacts, message }: CorrectViewProps) {
  return (
    <div className={styles.container}>
      <div className={styles.contacts}>
        {(!contacts || contacts.length === 0) && (<h2 className={styles.emptyMessage}>Nenhum Contato</h2>)}
        {contacts?.map((contact, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.contact} key={i}>
            <div className={styles.data}>
              <span>{contact.telefone} - {contact.nome}</span>
            </div>
            <div className={styles.message}>
              <span><b>Mensagem:</b></span>
              <p>{message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CorrectView;
