import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { ChatContactPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const chatContactPageState = useSelector<RootState, ChatContactPageState>(
    (state) => state.chatContactPage
  );

  return {
    ...dispatch.chatContactPage,
    ...chatContactPageState,
  };
};

export const useLoading = () => {
  const loadingReactivationMessage = useSelector<RootState, boolean>(
    (state) => state.loading.effects.chatContactPage.getReactivationMessage
  );

  return { loadingReactivationMessage };

};
