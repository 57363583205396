/* eslint-disable jsx-a11y/media-has-caption */
import { Message, Facebook } from '@entities';
import { dateLabel } from '@utils/strings';
import QuickReplies from '../FbQuickReplies';
import Carousel from '../Carousel';
import styles from './styles.module.css';

interface MediaProps {
  element: Facebook.FbMediaElement;
  setMedia: MessageProps['setMedia'];
}

// Facebook's Media Template message.
function Media({ element, setMedia }: MediaProps) {
  const isImage = element?.media_type === 'image';
  const isVideo = element?.media_type === 'video';
  const hasButtons = !!element?.buttons.length;

  return (
    <div className={styles.media}>
      <button 
        type='button'
        onClick={() => {
          setMedia(element.media_type.toUpperCase() || '', element.url || '');
        }}
      >
        {isImage && <img src={element.url || ''} alt='IMG' />}
        {isVideo && <video src={element.url || ''} controls />}
      </button>

      {hasButtons && element.buttons.map((b) => (
        <button
          className={styles.cta}
          key={JSON.stringify(b)}
          type='button'
          onClick={() => ('url' in b) && window.open(b.url, '_blank')}
          disabled={b.type === 'postback'}
        >
          {b.title}
        </button>
      ))}
    </div>
  );
}

interface MessageProps {
  message: Message;
  setMedia: (type: string, content: string) => void;
}

function FbMediaMessage({ message, setMedia }: MessageProps) {
  const payload = message.body!.payload as Facebook.FbMediaTemplate;
  const source = message.origin === 'user' ? 'messageOrigin' : 'messageSender';

  return (
    <div className={styles[source]}>
      <Carousel>
        {payload.elements.map((el) => (
          <li key={JSON.stringify(el)}>
            <Media element={el} setMedia={setMedia} />
          </li>
        ))}
      </Carousel>

      {message.body?.quick_replies
        && <QuickReplies quick_replies={message.body.quick_replies} />}
      <span>{dateLabel(message.created_at)}</span>
    </div>
  );
}

export default FbMediaMessage;
