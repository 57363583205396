import { useState, useCallback, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';
import logo from '@assets/logo.png';
import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import AlertModal from '@components/AlertModal';
import { useModel, useLoading } from '@flux/models/pages/sendEmailPage/hooks';
import styles from './styles.module.css';

function SendEmail() {
  const { success, error, setError, setSuccess, sendEmail } = useModel();
  const loading = useLoading();

  const [email, setEmail] = useState('');

  const handleEmail = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      sendEmail({
        email,
      });
    },
    [email, sendEmail]
  );

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <section className={styles.sideImage}>
          <h1>Gerenciamento de atendimentos dos chatbots</h1>
        </section>
        <section>
          <div className={styles.formHeader}>
            <img src={logo} alt="Elife" />
            <h1>Recupere sua senha</h1>
            <h2>
              Preencha o campo com o seu endereço de e-mail cadastrado e siga o
              passo a passo do e-mail enviado.
            </h2>
          </div>
          <form className={styles.form} onSubmit={handleEmail}>
            <FormInput
              type="email"
              name="email"
              placeholder="e-mail cadastrado"
              icon={() => <MdEmail color="black" />}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <SubmitButton
              submit
              label="Enviar e-mail"
              className={styles.submitButton}
              loading={loading}
            />

            <h2>Deseja fazer login?</h2>
            <Link to="/login">
              <strong>Iniciar sessão</strong>
            </Link>
          </form>
        </section>
      </div>

      <AlertModal
        isOpen={!!error}
        message={error}
        onClose={() => setError('')}
      />
      <AlertModal
        success
        isOpen={success}
        message="Verifique a sua caixa de entrada do e-mail, em instantes receberá o nosso e-mail com o link para redefinição da senha."
        onClose={() => setSuccess(false)}
      />
    </div>
  );
}

export default SendEmail;
