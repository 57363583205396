import { FormEvent, useState } from 'react';
import Modal from '@components/Modal';
import FormInput from '@components/FormInput';
import styles from './styles.module.css';

export interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  onAfterClose?: () => void;
  contentLabel?: string;
  success?: boolean;
  confirm: (e: FormEvent) => void;
  setEmail: (e: string) => void;
  error: string;
  setError: (e: string) => void;
}

function GetEmailModal({
  isOpen,
  onClose,
  message,
  success,
  confirm,
  setEmail,
  error,
  setError
}: AlertModalProps) {

  return (
    <div className={styles.contentModal}>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className={styles.messageContainer}>
          <p>Entre com o e-mail do usuário </p>
          <FormInput 
            type="email" 
            onChange={(e) => {
              setError('');
              setEmail(e.target.value);
            }} 
          />
          {error && <span>{error}</span>}
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.cancel} type="button" onClick={onClose}>
            Cancelar
          </button>
          <button className={styles.delete} type="button" onClick={confirm}>
            Enviar Email
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default GetEmailModal;
