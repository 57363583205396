import { ReactNode, MouseEvent, KeyboardEvent } from 'react';
import styles from './styles.module.css';

export interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  id?: string;
  children?: ReactNode;
}

function Modal({
  id = String(Math.random()),
  isOpen,
  onRequestClose,
  children,
}: ModalProps) {
  const handleOutsideClick = (e: MouseEvent) => {
    if ((e.target as HTMLElement).id === id) onRequestClose();
  };

  const handleKeyboardPress = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === '27') onRequestClose();
  };

  if (!isOpen) return <></>;

  return (
    <div
      id={id}
      role="button"
      tabIndex={0}
      className={styles.backdrop}
      onKeyPress={handleKeyboardPress}
      onClick={handleOutsideClick}
    >
      <div className={styles.container}>{children}</div>
    </div>
  );
}

export default Modal;
