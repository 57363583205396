import { Company } from '@entities';
import { formatDate } from '@utils/strings';
import styles from './styles.module.css';


interface CompanyCardProps {
  company: Company;
  setCompany: (item: Company | null) => void;
}

function ChatBotCard({ company, setCompany }: CompanyCardProps) {

  return (
    <div className={styles.cardCompany}>
      <section className={styles.cardHeader}>
        <h1>{company.fullname}</h1>
      </section>
      <section className={styles.cardBody}>
        <div className={styles.line}>
          <span>ID</span>
          <h1>{company.id}</h1>
        </div>
        <div className={styles.line}>
          <span>Data de criação</span>
          <h1>{formatDate(company.created_at)}</h1>
        </div>
        
      </section>
    </div>
  );
}

export default ChatBotCard;
