import { Message, Facebook } from '@entities';
import { dateLabel } from '@utils/strings';
import QuickReplies from '../FbQuickReplies';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
}

// Facebook's Button Template message.
function FbButtonMessage({ message }: MessageProps) {
  const payload = message.body!.payload as Facebook.FbButtonTemplate;
  const source = message.origin === 'user' ? 'messageOrigin' : 'messageSender';

  return (
    <div className={`${styles[source]} ${styles.template}`}>
      <p>{payload.text}</p>

      <menu className={styles.menu}>
        {payload.buttons.map((el) => (
          <button
            key={JSON.stringify(el)}
            type='button'
            onClick={() => ('url' in el) && window.open(el.url, '_blank')}
            disabled={el.type === 'postback'}
          >
            {el.title}
          </button>
        ))}
      </menu>

      {message.body?.quick_replies
        && <QuickReplies quick_replies={message.body.quick_replies} />}
      <span>{dateLabel(message.created_at)}</span>
    </div>
  );
}

export default FbButtonMessage;
