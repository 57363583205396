import { useState, useEffect } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useModel } from '@flux/models/tags/hooks';
import Modal from '@components/Modal';
import { Tag } from '@entities';
import ManageView from './components/ManageView';
import CreateView from './components/CreateView';
import AddView from './components/AddView';
import FilterView from './components/FilterView';
import styles from './styles.module.css';

export interface TagsModalProps {
  isOpen: boolean;
  onClose: () => void;
  protocol?: string;
  viewType?: string;
  selectTags?: {
    tags: string[],
    setTags: (newList: string[]) => void;
  }
  setTags?: (tags: string) => void;
}

type ViewType = 'manage' | 'create' | 'add' | 'filter';

function TagsModal({ isOpen, onClose, protocol, viewType, selectTags, setTags }: TagsModalProps) {
  const [view, setView] = useState<ViewType>(protocol ? 'add' : 'manage');
  const { getTags, getAttendanceTags } = useModel();

  useEffect(() => {
    if (viewType === 'filter') {
      getTags();
      setView('filter');
    }
    if (isOpen) {
      getTags();
      if (protocol) {
        getAttendanceTags(protocol);
      }
    }
  }, [isOpen, getTags, protocol, getAttendanceTags]);

  const viewComponent = {
    manage: <ManageView onCreate={() => setView('create')} />,
    create: <CreateView onBack={() => setView('manage')} />,
    add: <AddView />,
    filter: <FilterView 
      selectedTags={selectTags?.tags || []} 
      setSelectedTags={selectTags?.setTags || (() => null)} 
      setTags={setTags || (() => null)}
    />
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <header className={styles.header}>
        <h1>Tags</h1>
        <button type="button" onClick={onClose}>
          <AiFillCloseCircle size={30} color="#999" />
        </button>
      </header>

      {viewComponent[view]}
    </Modal>
  );
}

export default TagsModal;
