import { ChangeEvent, useEffect, useState, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Message, ContactToImport, ContactWithErrorInterface } from '@entities';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import { useModel, useLoading } from '@flux/models/pages/bulkMessagingPage/hooks';
import { useModel as useModelApplication } from '@flux/models/application/hooks';
import DashboardMenu from '@components/DashboardMenu';
import AlertModal from '@components/AlertModal';
import ConfirmModal from '@components/ConfirmModal';
import ResultModal, { ViewType } from '@pages/BulkMessaging/components/ResultModal';
import Loading from '@pages/BulkMessaging/components/Loading';
import { newMessageNotification } from '@utils/notifications';
import { csvToJson } from '@utils/csvUtils';
import { filterPhone, verifyPhone } from '@utils/phoneUtil';
import { AiFillPlusCircle } from 'react-icons/ai';
import { RiRobotLine } from 'react-icons/all';

import styles from './styles.module.css';

function BulkMessaging() {
  const {
    bulkMessaging,
    getBotMessageTemplates
  } = useModel();

  const { loadingBulkingMessage } = useLoading();

  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();
  const firstUpdate = useRef(true);

  const { user } = useModelApplication();

  const { getChatbots, chatbots } = AdminHook.useModel();
  const [selectedBot, setSelectedBot] = useState('');
  const [message, setMessage] = useState('');
  const [postback, setPostback] = useState('');
  const [positusCode, setPositusCode] = useState('');
  const [contactsToSend, setContactsToSend] = useState<ContactToImport[]>([]);
  const [contactsWithError, setContactsWithError] = useState<ContactWithErrorInterface[]>([]);
  const [success, setSuccess] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [templates, setTemplates] = useState<{ title: string, text: string, postback: string, code?: string | undefined }[]>([]);
  const [showResultModal, setShowResultModal] = useState<ViewType | undefined>(undefined);

  const company = user ? user.company : '';

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, []);

  useEffect(() => {
    getChatbots(user?.company || '');
  }, [getChatbots, user?.company]);

  useEffect(() => {
    if (chatbots.length > 0) {
      setSelectedBot(chatbots[0].id);
    }
  }, [chatbots]);

  useEffect(() => {
    if (templates.length > 0) {
      setMessage(templates[0].text);
      setPostback(templates[0].postback);
      templates[0].code ? setPositusCode(templates[0].code) : setPositusCode('');
    }
  }, [templates]);

  useEffect(() => {
    async function handleTemplates() {
      const messages = await getBotMessageTemplates({ bot_id: selectedBot });
      // eslint-disable-next-line no-console
      console.log(messages);
      setTemplates(messages);
    }
    if (selectedBot) {
      handleTemplates();
    }
  }, [selectedBot]);

  // TODO: Handle success and error
  async function handleBulk() {
    const promisesToAwait = [];
    const regex = /{{(.*?)}}/g;
    const positusCodeJson = positusCode !== '' ? JSON.parse(positusCode) : {};
    // verificando se tem o código da requisição positus junto
    if (positusCode === ''){
      for (const contact of contactsToSend) {
        const parameters = message.match(regex);
        let messageToSend = message;
        if (parameters) {
          const paramList: Array<string> = Array.from(new Set(parameters)).map(el => el.substring(2, el.length - 2));
  
          paramList.map(param => {
            messageToSend = messageToSend.replaceAll(`{{${param}}}`, contact[param]);
          });
          
        }
        promisesToAwait.push(bulkMessaging({ message:messageToSend, contact, company, bot_id: selectedBot || '', postback }));
        // TODO: Historico de mensagens enviadas com sucesso
      }
    } else {
      for (const contact of contactsToSend) {
        // enviar parâmetros junto
        promisesToAwait.push(bulkMessaging({ message, contact, company, bot_id: selectedBot || '', postback, positusCode: positusCodeJson }));
        // TODO: Historico de mensagens enviadas com sucesso
      }
    }
    await Promise.all(promisesToAwait);
    setMessage('');
    setPostback('');
    setPositusCode('');
    setSuccess('Mensagens Disparadas com Sucesso!');
    window.location.reload();
  }

  async function handleFile(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file.name.endsWith('.csv')) {
        const contactsFromFile = await csvToJson(file);
        // @ts-ignore
        contactValidator(contactsFromFile);
      }
    }
  }

  function contactValidator(contactList: Array<ContactToImport>) {
    const correctContacts: Array<ContactToImport> = [];
    const errorContacts: Array<ContactWithErrorInterface> = [];
    const properties: Array<string> = Object.keys(contactList[0]);
    contactList.forEach((element: ContactToImport, index) => {
      if (element.telefone && element.nome) {
        const telefone = filterPhone(element.telefone);
        if (verifyPhone(telefone) && verifyParameters(element, properties)) {
          correctContacts.push(element);
          return;
        }
      }
      errorContacts.push({ line: index + 2, data: element });
    });
    // @ts-ignore
    setContactsToSend(correctContacts);
    // @ts-ignore
    setContactsWithError(errorContacts);
  }

  function verifyParameters(contact: ContactToImport, properties: Array<string>) {
    let res = true;
    properties.forEach(element => {
      if (!contact[element]) res = false;
    });
    return res;
  }

  async function handleChangeBot(e: ChangeEvent<HTMLSelectElement>) {
    // eslint-disable-next-line no-console
    console.log('chamou o handlechangebot');
    // eslint-disable-next-line no-console
    console.log(e.target.value);
    setSelectedBot(e.target.value);
    // eslint-disable-next-line no-console
    console.log('vai chamar as mensagens');
  }

  return (
    <div className="app-container">
      {loadingBulkingMessage && <Loading />}
      <AlertModal
        isOpen={!!success}
        message={success}
        onClose={() => setSuccess('')}
        success={true}
      />
      <ConfirmModal
        isOpen={confirm}
        onConfirm={() => {
          handleBulk();
          setConfirm(false);
        }}
        onClose={() => setConfirm(false)}
        message={`Tem certeza que deseja disparar ${contactsToSend.length} mensagens?`}
      />
      <ResultModal
        isOpen={!!showResultModal}
        onClose={() => setShowResultModal(undefined)}
        contactsToSend={contactsToSend}
        contactsWithError={contactsWithError}
        message={message}
        type={showResultModal}
      />
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Disparo de Mensagens</h1>
              <p>Selecione um arquivo csv com as colunas "nome" e "telefone".</p>
            </div>
          </section>

          <section className={styles.container}>
            <form
              className={styles.container}
              onSubmit={(e) => {
                e.preventDefault();
                setConfirm(true);
              }}
            >
              <div className={styles.input}>
                <input
                  type="file"
                  accept=".csv"
                  onChange={event => handleFile(event)}
                />
              </div>

              <div className={styles.input}>
                <RiRobotLine size={20} />
                <select
                  value={selectedBot}
                  onChange={e => handleChangeBot(e)}
                  required={true}
                >
                  {chatbots.map(bot =>
                    <option key={bot.id} value={bot.id}>{bot.name}</option>
                  )}
                </select>
              </div>

              <div className={styles.input}>
                <RiRobotLine size={20} />
                <select
                  value={message}
                  onChange={e => {
                    setMessage(e.target.value);
                    const index = e.target.selectedIndex;
                    const optionElement = e.target.children[index];
                    setPostback(optionElement.id);
                    setPositusCode(optionElement.getAttribute('data-code') || '');
                  }}
                  required={true}
                >
                  {templates.map(template =>
                    <option data-code={template.code || ''} key={template.postback} value={template.text} id={template.postback}>{template.title}</option>
                  )}
                </select>
              </div>

              <div className={styles.message}>
                <textarea
                  placeholder={message}
                  value={message}
                  required={true}
                  disabled={true}
                />
              </div>

              <div className={styles.contactResult}>
                <button
                  type='button'
                  className={styles.contactResultCorrect}
                  onClick={() => setShowResultModal('correct')}
                >
                  Corretos: {contactsToSend.length}
                </button>
                <button
                  type='button'
                  className={styles.contactResultIncorrect}
                  onClick={() => setShowResultModal('invalid')}
                >
                  Inválidos: {contactsWithError.length}
                </button>
              </div>
              {templates.length &&
                <button className={styles.sendMessageButton} type="submit">
                  <span>Enviar Mensagem</span>
                  <AiFillPlusCircle size={30} color="white" />
                </button>}
            </form>
          </section>
        </div>
      </DashboardMenu>
    </div>
  );
}

export default BulkMessaging;
