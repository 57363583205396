import alert from '@assets/alert.png';
import styles from './styles.module.css';

export interface PlaceholderProps {
  description: string;
}

function Placeholder({ description }: PlaceholderProps) {
  return (
    <div className={styles.content}>
      <div className={styles.placeholderContainer}>
        <img src={alert} />
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Placeholder;
