import DashboardMenu from '@components/DashboardMenu';
import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import AlertModal from '@components/AlertModal';
import {
  useModel,
  useLoading,
} from '@flux/models/pages/SettingsOptionsPage/hooks';
import { passwordChanged } from '@utils/messages.json';
import * as Application from '@flux/models/application/hooks';
import { Redirect } from 'react-router-dom';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import styles from './styles.module.css';

function getLoginType() {
  const stateJSON = localStorage.getItem('persist:root');
  if (stateJSON) {
    const state = JSON.parse(stateJSON);
    const application = JSON.parse(state.application);

    return application.user.create_type;
  }
  return 'standard';
}

function SettingsOptions() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState('');
  const loading = useLoading();
  const {
    error,
    setError,
    successChangePassword,
    successDeleteAccount,
    handleChangePassword,
    handleDeleteAccount,
    authorized,
    setAuthorized,
  } = useModel();
  const { clearData } = Application.useModel();

  const handleSubmitChangePass = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleChangePassword({ newPassword, oldPassword, confirmNewPassword });
    },
    [newPassword, confirmNewPassword, oldPassword, handleChangePassword]
  );

  const handleOpenModal = async (e: FormEvent) => {
    e.preventDefault();
    setOpen('Aberto');
  };

  const handleSubmitDeleteAcc = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleDeleteAccount({
        password: getLoginType() === 'google' ? 'google-login' : password,
      });
      setOpen('');
    },
    [password, handleDeleteAccount, setOpen]
  );

  useEffect(() => {
    if (successDeleteAccount) {
      clearData();
    }
  }, [successDeleteAccount, clearData]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Here, useEffect act as componentDidMount');
    return () => {
      setAuthorized(false);
    };
  }, [setAuthorized]);

  useEffect(() => {
    if (successChangePassword) setError(passwordChanged);
  }, [successChangePassword, setError]);

  if (!authorized) return <Redirect to="/configuracoes" />;
  return (
    <DashboardMenu>
      <div className={styles.content}>
        <section className={styles.infoContainer}>
          <div className={styles.header}>
            <h1>Configurações</h1>
            <p>
              Entre com seu e-mail associado e receba um código PIN
              <br />
              para habilitar a modificação das suas configurações de usuário.
            </p>
          </div>
        </section>

        <section className={styles.body}>
          {getLoginType() === 'standard' && (
            <form className={styles.form} onSubmit={handleSubmitChangePass}>
              <h1>Redefinir senha</h1>
              <h2>Senha atual</h2>
              <FormInput
                type="password"
                name="senhaAntiga"
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
                required
              />
              <h2>Nova senha</h2>
              <FormInput
                type="password"
                name="novaSenha"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                required
              />
              <h2>Confirme a senha</h2>
              <FormInput
                type="password"
                name="confirmNovaSenha"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
                required
              />

              <SubmitButton
                submit
                label="Confirmar"
                className={styles.submitButton}
                loading={loading.loadingChangePassword}
              />
            </form>
          )}
          <form className={styles.form} onSubmit={handleOpenModal}>
            <h1>Apagar conta</h1>
            {getLoginType() === 'standard' && (
              <div className={styles.deleteAccount}>
                <h2>Senha atual</h2>
                <FormInput
                  type="password"
                  name="senha"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </div>
            )}
            <SubmitButton
              submit
              label="Confirmar"
              className={styles.submitButton}
              backgroundColor="red"
              loading={loading.loadingDeleteAccount}
            />
          </form>
        </section>

        <ConfirmDeleteModal
          isOpen={!!open}
          message={open}
          onClose={() => setOpen('')}
          confirm={handleSubmitDeleteAcc}
        />
        <AlertModal
          isOpen={!!error}
          message={error}
          onClose={() => setError('')}
        />
      </div>
    </DashboardMenu>
  );
}
export default SettingsOptions;
