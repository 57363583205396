import LoadingSpinner from '@components/LoadingSpinner';
import styles from './styles.module.css';

export interface MetricProps {
  title: string;
  description?: string;
  value: string;
  img?: string;
  loading?: boolean
}

function Metric({ title, description, value, img, loading }: MetricProps) {
  if (loading) {
    return (
      <div className={styles.loadingSpinner}>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className={styles.metric}>
      {img ? <img src={img} alt={title} /> : null}

      <div>
        <h3>{title}</h3>
        {description ? <p>{description}</p> : null}
        <span>{value}</span>
      </div>
    </div>
  );
}

export default Metric;
