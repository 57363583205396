import { ChangeEvent, useEffect, useState } from 'react';
import { GrLanguage } from 'react-icons/gr';
import { RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import { ImSpinner9 } from 'react-icons/im';
import { Chatbot, User, Task } from '@entities';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import { useLoading } from '@flux/models/pages/adminPage/hooks';
import { useModel } from '@flux/models/application/hooks';
import styles from './styles.module.css';

interface EditCollaboratorBotsProps {
  collaborator: User;
}

interface CellBotProps {
  bot: Chatbot;
  onChange: (id: string) => void;
  selecteds: string[];
}

function CellBot({ bot, onChange, selecteds }: CellBotProps) {
  const getIcon = () => {
    if (bot.platform === 'facebook') return <RiFacebookCircleFill size={20} />;
    if (bot.platform === 'whatsapp') return <RiWhatsappFill size={20} />;
    return <GrLanguage size={18} />;
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(bot.id);
  };

  return (
    <div className={styles.cellBot}>
      <section className={styles.botInfo}>
        <div className={styles.botInfoLine}>
          <h1>{bot.name} {' '} {getIcon()}</h1>
        </div>
        <div className={styles.botInfoLine}>
          <span>ID: {bot.id}</span>
        </div>
      </section>
      <section>
        <div className={styles.checkOpen}>
          <input 
            type="checkbox" 
            id={bot.id} 
            defaultChecked={selecteds.includes(bot.id)}
            onChange={handleCheckboxChange}
          />
        </div>
      </section>
    </div>
  );
}

const Loading = () => (
  <>
    <ImSpinner9 className="spin" />
  </>
);

function EditCollaboratorBots({ collaborator }: EditCollaboratorBotsProps) {
  const { user } = useModel();
  const {
    chatbots,
    getChatbots,
    updateBots
  } = AdminHook.useModel();
  const { loadingUpdateBots } = useLoading();
  const [selecteds, setSelecteds] = useState<string[]>([]);
  
  useEffect(() => {
    let company = '';
    if (user?.type === 'manager') {
      company = user?.company;
    }
    getChatbots(company);
  }, [getChatbots, user]);

  useEffect(() => {
    setSelecteds(collaborator.chatbots);
  }, [collaborator]);

  const updateList = (id: string) => {
    if (selecteds.includes(id)) {
      setSelecteds(selecteds.filter(e => e !== id));
    } else {
      setSelecteds([...selecteds, id]);
    }
  };

  const handleSave = async () => {
    localStorage.setItem('tabAdmin', 'colaboradores');
    const tasks: Task[] = [];
    chatbots.forEach(bot => {
      if (
        collaborator.chatbots.includes(bot.id) &&
        !selecteds.includes(bot.id)) {
        tasks.push({ id_bot: bot.id, operation: 'delete' });
      } else if (
        !collaborator.chatbots.includes(bot.id) &&
        selecteds.includes(bot.id)) {
        tasks.push({ id_bot: bot.id, operation: 'put' });
      }
    });
    if (tasks) {
      await updateBots({
        user: collaborator,
        tasks
      });
    }
    window.location.reload();
  };

  const handleCancel = () => {
    localStorage.setItem('tabAdmin', 'colaboradores');
    window.location.reload();
  };

  return (
    <div className={styles.content}>
      <div className={styles.contentHeader}>
        <h1>Agente: {collaborator.name}</h1>
      </div>
      <div className={styles.contentBody}>
        <div className={styles.botsList}>
          {chatbots.map((bot) => (
            <CellBot
              key={Math.random()}
              bot={bot}
              onChange={updateList}
              selecteds={selecteds}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          <button className={styles.save} type="button" onClick={handleSave}>
            {loadingUpdateBots ? <Loading /> : 'Salvar'}
          </button>
          <button className={styles.cancel} type="button" onClick={handleCancel}>Cancelar</button>
        </div>
      </div>
    </div>
  );
}
export default EditCollaboratorBots;