import axios from 'axios';
import { connectionError } from '@utils/messages.json';
import axiosRetry from 'axios-retry';

export function getToken() {
  const stateJSON = localStorage.getItem('persist:root');
  
  if (stateJSON) {
    const state = JSON.parse(stateJSON);
    const application = JSON.parse(state.application);

    return application.token;
  }

  return null;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

axiosRetry(api, { retries: 3 });

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});

export async function errorHandler(
  callback: () => Promise<void>,
  setError: (message: string) => void
) {
  try {
    await callback();
  } catch (error) {
    if (
      error.response &&
      error.response.data.status === 'error' &&
      error.response.data.message
    ) {
      // eslint-disable-next-line no-console
      console.log('API Error', error.response.data);
      setError(error.response.data.message);
    } else {
      // eslint-disable-next-line no-console
      console.log('Some error', JSON.stringify(error));
      setError(connectionError);
    }
  }
}

export default api;
