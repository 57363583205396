import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Attendance, Message, Chatbot } from '@entities';
import api, { errorHandler } from '@services/api';
import socket, {
  SEND_CHAT_MESSAGE_EVENT,
  CHAT_DISCONNECT_EVENT,
} from '@services/socket';

export interface ChatPageState {
  error: string;
  attendance: Attendance | null;
  messages: Message[];
  emailSuccess: boolean;
  reactivationMessage: string;
  quickReplies: quickReply[];
}

export interface quickReply {
  id: string;
  company: string;
  text: string;
  name: string;
}

interface sendEmailPayload {
  protocol: string;
  email: string;
}

export const chatPage = createModel<RootModel>()({
  state: {
    error: '',
    attendance: null,
    messages: [],
    emailSuccess: false,
    reactivationMessage: '',
    quickReplies: []
  } as ChatPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setAttendance(state, payload: Attendance) {
      state.attendance = payload;
      return state;
    },

    setMessages(state, payload: Message[]) {
      state.messages = payload;
      return state;
    },

    addMessage(state, payload: Message) {
      state.messages.push(payload);
      return state;
    },

    setEmailSuccess(state, payload: boolean) {
      state.emailSuccess = payload;
      return state;
    },

    setReactivationMessage(state, payload: string) {
      state.reactivationMessage = payload;
      return state;
    },

    setQuickReplies(state, payload: quickReply[]) {
      state.quickReplies = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async getAttendanceInfo(payload: string, state) {
      const { 
        setError, 
        setAttendance, 
        setMessages, 
        getReactivationMessage,
      } = dispatch.chatPage;

      await errorHandler(async () => {
        const response = await api.get(`/attendances/open?protocol=${payload}`);
        const { messages, attendance } = response.data;
        await getReactivationMessage({
          bot_id: attendance.bot_id 
        }) ;
        setMessages(messages);
        setAttendance(attendance);
      }, setError);
    },

    async closeAttendance(payload: string) {
      const { setError } = dispatch.chatPage;
      await errorHandler(async () => {
        await api.put('/attendances/close', { protocol: payload });
      }, setError);
    },

    sendMessage(payload: string, state) {
      const { 
        addMessage, 
        setLastUserInteractionToNull
      } = dispatch.chatPage;
      const { attendance } = state.chatPage;
      if (attendance) {
        const newMessage = createMessage(payload, attendance);
        socket.emit(SEND_CHAT_MESSAGE_EVENT, newMessage);
        addMessage(newMessage);
        setLastUserInteractionToNull(attendance.protocol);
      }
    },

    sendTemplateMessage(payload: string, state) {
      const { addMessage } = dispatch.chatPage;
      const { attendance } = state.chatPage;
      if (attendance) {
        const newMessage = createTemplateMessage(payload, attendance);
        socket.emit(SEND_CHAT_MESSAGE_EVENT, newMessage);
        addMessage(newMessage);
      }
    },

    async finishChat(payload: string, state) {
      const { closeAttendance } = dispatch.chatPage;

      socket.emit(CHAT_DISCONNECT_EVENT);
      await closeAttendance(payload);
      window.open('/atendimentos', '_self');
    },

    async sendEmail(payload: sendEmailPayload, state) {
      const { setEmailSuccess, setError } = dispatch.chatPage;
      const { email, protocol } = payload;

      await errorHandler(async () => {
        await api.post('/attendances/sendemail', { email, protocol });

        setEmailSuccess(true);
      }, setError);
    },

    async transferAttendance(payload: { attendant_id: string, protocol: number }, state) {
      const { setError } = dispatch.chatPage;
      await errorHandler(async () => {
        await api.put('/attendances/transfer', payload);
        window.open('/atendimentos', '_self');
      }, setError);
    },

    async getReactivationMessage(payload: { bot_id: string }, state) {
      const { setError, setReactivationMessage } = dispatch.chatPage;
      const { bot_id } = payload;
      await errorHandler(async () => {
        const res = await api.get(`/bots/bot?bot_id=${bot_id}`);
        const bot = res.data;
        const message = bot.reactivationMessage || '';
        setReactivationMessage(message);
      }, setError);
    },

    async getQuickReplies(payload, state) {
      const { setQuickReplies, setError } = dispatch.chatPage;
      const { user } = state.application;

      await errorHandler(async () => {
        const res = await api.get(`/quickReplies?company=${user?.company}`);
        console.log(res.data);
        setQuickReplies(res.data);
      }, setError);
    },

    // apagando o horário da última mensagem do usuário
    async setLastUserInteractionToNull(protocol: number, state) {
      const { setError } = dispatch.chatPage;

      await errorHandler(async () => {
        const res = await api.post('/attendances/nullify-wait-time', { 
          protocol 
        });
      }, setError);
    }
  }),
});

const createMessage = (content: string, attendance: Attendance): Message => ({
  platform_id: attendance.platform_id,
  user_id: attendance.user_id,
  platform: attendance.platform,
  message: content,
  created_at: new Date().toISOString(),
  origin: 'attendants',
  client: attendance.company || attendance.client || '',
  attendant_name: attendance.attendant_name || '',
  bot_id: attendance.bot_id,
});

const createTemplateMessage = (content: string, attendance: Attendance): Message => ({
  platform_id: attendance.platform_id,
  user_id: attendance.user_id,
  platform: attendance.platform,
  message: content,
  created_at: new Date().toISOString(),
  origin: 'attendants',
  client: attendance.company || attendance.client || '',
  attendant_name: '',
  bot_id: attendance.bot_id,
});