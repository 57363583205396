import { Value } from '@components/Table';
import moment from 'moment';

export function columnAlphabeticCompare(a: Value, b: Value, ascending: boolean) {
  const aString = String(a);
  const bString = String(b);

  if (ascending) {
    return aString.localeCompare(bString);
  }
  return bString.localeCompare(aString);
}

export function columnDateCompare(a: Value, b: Value, ascending: boolean) {
  const aDate = moment(String(a), 'DD/MM/YYYY HH:mm:ss', true);
  const bDate = moment(String(b), 'DD/MM/YYYY HH:mm:ss', true);

  if (ascending) {
    return aDate.diff(bDate);
  }
  return bDate.diff(aDate);
}
