import { InputHTMLAttributes, useState, FC } from 'react';
import styles from './styles.module.css';

export interface ProfileInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  dataLabel: string;
  backgroundColor?: string;
  width?: string;
  justifyContent?: string;
  paddingLeft?: string;
}

function ProfileInput({
  dataLabel,
  backgroundColor,
  width,
  justifyContent,
  paddingLeft,
  ...props
}: ProfileInputProps) {
  return (
    <div className={styles.info}>
      <div className={styles.dataLabel}>{dataLabel}</div>
      <input
        style={{ backgroundColor, width, justifyContent, paddingLeft }}
        className={styles.data}
        {...props}
      />
    </div>
  );
}

export default ProfileInput;
