import styles from './styles.module.css';

export interface ProfileInfoProps {
  dataLabel: string;
  data: string | undefined;
  backgroundColor?: string;
  width?: string;
  justifyContent?: string;
  paddingLeft?: string;
  marginLeft?: string;
}

function ProfileInfo({
  dataLabel,
  data,
  backgroundColor,
  width,
  justifyContent,
  paddingLeft,
  marginLeft,
}: ProfileInfoProps) {
  return (
    <div className={styles.info}>
      <div style={{ marginLeft }} className={styles.dataLabel}>
        {dataLabel}
      </div>
      <div
        style={{
          backgroundColor,
          width,
          justifyContent,
          paddingLeft,
          marginLeft,
        }}
        className={styles.data}
      >
        {data}
      </div>
    </div>
  );
}

export default ProfileInfo;
