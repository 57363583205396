import { useState, useEffect, useCallback } from 'react';
import { AiFillCloseCircle, AiFillDelete, AiFillPlusCircle } from 'react-icons/ai';
import { useModel, useLoading } from '@flux/models/modals/QuickReplies/hooks';
import Modal from '@components/Modal';
import LoadingLabel from '@components/LoadingLabel';
import { quickReply } from '@flux/models/pages/chatPage';

import alert from '@assets/alert.png';
import styles from './styles.module.css';

export interface QuickRepliesProps {
  isOpen: boolean;
  onClose: () => void;
}

function QuickReplyModal({ isOpen, onClose }: QuickRepliesProps) {
  const { getquickReplies, quickReplies, createquickReply, deleteQR } = useModel();
  const Loading = useLoading();
  console.log(quickReplies);

  const [newQR, setNewQR] = useState({
    name: '',
    text: '',
  });
  const [QRs, setQRs] = useState<quickReply[]>([]);

  const fetchQRInfos = useCallback(() => {
    // eslint-disable-next-line no-console
    getquickReplies();
  }, [getquickReplies]);

  useEffect(() => {
    if (isOpen) fetchQRInfos();
  }, [isOpen]);

  useEffect(() => {
    setQRs(quickReplies);
  }, [quickReplies]);

  const handleNewQR = () => {
    if (newQR){
      createquickReply(newQR);
      setNewQR({
        name: '',
        text: ''
      });
    } else {
      window.alert('Preencha com um texto válido!');
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={styles.Container}>
        <header className={styles.header}>
          <h1>Respostas rápidas</h1>
          <button type="button" onClick={onClose}>
            <AiFillCloseCircle size={30} color="#999" />
          </button>
        </header>

        <div className={styles.QuickReplyList}>
          {
            !Loading && QRs.length === 0 && (
              <div className={styles.alert}>
                <img src={alert} alt="Alerta" />
                <h2>Não existem Respostas Rápidas cadastradas.</h2>
              </div>
            )
          } 
          {
            Loading ? (
              <LoadingLabel size="medium" />
            ) : (
              QRs.map( QR => (
                <div className={styles.QuickReplyContainer} key={QR.id}>
                  <div>
                    <p>{QR.name}</p>
                    <p className={styles.qrText}>{QR.text}</p>
                  </div>
                  <button type="button" onClick={() => deleteQR(QR.id)}>
                    <AiFillDelete size={20} color='#E62121' />
                  </button>
                </div>
            )))
          }
        </div>

        <div className={styles.Footer}>
          <input
            placeholder="Nome da nova resposta rápida"
            value={newQR.name}
            onChange={(e) => setNewQR((prevState) => ({ ...prevState, name: e.target.value }))}
          />
          <input
            placeholder="Texto da nova resposta rápida"
            value={newQR.text}
            onChange={(e) => setNewQR((prevState) => ({ ...prevState, text: e.target.value }))}
          />
          <button type="button" onClick={() => handleNewQR()}>
            <AiFillPlusCircle size={30} color="#4A69BD" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default QuickReplyModal;
