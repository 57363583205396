import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import persistPlugin from '@rematch/persist';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';
import storage from 'redux-persist/lib/storage';
import { models, RootModel } from '../models';

type FullModel = ExtraModelsFromLoading<RootModel>;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['application'],
};

export const store = init<RootModel, FullModel>({
  models,
  plugins: [immerPlugin(), loadingPlugin(), persistPlugin(persistConfig)],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
