import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import api, { errorHandler } from '@services/api';
import messages from '@utils/messages.json';

export interface RecoverPasswordPageState {
  error: string;
  success: boolean;
}

interface SendNewPasswordPayload {
  token: string;
  password: string;
  password_confirmation: string;
}

export const recoverPasswordPage = createModel<RootModel>()({
  state: {
    error: '',
    success: false,
  } as RecoverPasswordPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setSuccess(state, payload: boolean) {
      state.success = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async sendNewPassword(payload: SendNewPasswordPayload, state) {
      const { setError, setSuccess } = dispatch.recoverPasswordPage;
      const { token, password, password_confirmation } = payload;

      if (!token) {
        setError(messages.missingToken);
        return;
      }

      if (password !== password_confirmation) {
        setError(messages.incorrectPasswords);
        return;
      }

      await errorHandler(async () => {
        await api.post('/password/reset', payload);
        setSuccess(true);
      }, setError);
    },
  }),
});
