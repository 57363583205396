import { columnAlphabeticCompare } from '@utils/compare';
import { Column, LineItem } from '@components/Table';
import { PickerItem } from '@components/RegularPicker';
import { Contact } from '@entities';
import ContactOptions from '@pages/Contacts/components/ContactOptions';
import LinkTo from './components/LinkTo';

export const CONTACT_COLUMNS: Column[] = [
  { label: 'Chat', key: 'send' },
  { label: 'Nome', key: 'name', compareFn: columnAlphabeticCompare },
  { label: 'Telefone', key: 'phone', compareFn: columnAlphabeticCompare },
  { label: '', key: 'options' },
];

export function contactsToLineItem(
  contacts: Contact[]
): LineItem[] {
  return contacts.map((item) => ({
      send: <LinkTo to={`/contacts/${item.id}`} />,
      name: item.name,
      phone: item.phone,
      options: <ContactOptions contacts={contacts} selectedContact={item} />
    }));
}

export const CONTENT_OPTIONS: PickerItem[] = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '75',
    value: 75,
  },
  {
    label: '100',
    value: 100,
  },
];
