import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Team } from '@entities';
import TeamModal, { ViewType } from '@pages/Admin/ManagerView/components/TeamsTab/components/TeamModal';
import { useState } from 'react';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import styles from './styles.module.css';

interface TeamCardProps {
  team: Team;
}

function TeamCard({ team }: TeamCardProps) {
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [modalType, setModalType] = useState<ViewType>('update');

  const { teams } = useModel();

  const handleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleUpdate = () => {
    setModalType('update');
    setShowTeamsModal(true);
  };

  const handleRemove = () => {
    setModalType('remove');
    setShowTeamsModal(true);
  };

  return (
    <>
      <div className={styles.cardContainer}>
        <button className={styles.card} onClick={handleOptions} type="button">
          <h1 className={styles.cardText}>{team.name}</h1>
          <div>
            <FiEdit2 size={20} className={styles.options} />
          </div>
        </button>
        {showOptions && (
          <section className={styles.optionsContainer}>
            <FiEdit2 size={30} onClick={handleUpdate} className={styles.edit} />
            <FiTrash2 size={30} onClick={handleRemove} className={styles.remove} />
          </section>
        )}
      </div>
      <TeamModal
        isOpen={showTeamsModal}
        onClose={() => setShowTeamsModal(false)}
        teams={teams}
        type={modalType}
        selectedTeam={team}
      />
    </>
  );
}

export default TeamCard;
