import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Company } from '@entities';
import api, { errorHandler } from '@services/api';
import { missingValues, incorrectPasswords, minimumNumberOfCharactersInTheName } from '@utils/messages.json';

export interface RegisterPageState {
  error: string;
  success: boolean;
  companies: Company[];
}

interface HandleRegisterPayload {
  name: string;
  email: string;
  company_id: string;
  password: string;
  passwordConfirmation: string;
}

export const registerPage = createModel<RootModel>()({
  state: {
    error: '',
    success: false,
    companies: []
  } as RegisterPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setSuccess(state, payload: boolean) {
      state.success = payload;
      return state;
    },

    setCompanies(state, payload: Company[]) {
      state.companies = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async handleRegister(payload: HandleRegisterPayload, state) {
      const { setSuccess, setError } = dispatch.registerPage;
      const { name, email, 
        company_id, password, passwordConfirmation } = payload;

      if (!name || !email ||
         !company_id || !password || !passwordConfirmation) {
        setError(missingValues);
        return;
      }

      if (password !== passwordConfirmation) {
        setError(incorrectPasswords);
        return;
      }

      if (name.length < 10) {
        setError(minimumNumberOfCharactersInTheName);
        return;
      }

      await errorHandler(async () => {
        await api.post('/users', {
          name,
          email,
          company_id,
          password,
        });

        setSuccess(true);
      }, setError);
    },

    async getCompanies(state) {
      const { setSuccess, setError, setCompanies } = dispatch.registerPage;

      await errorHandler(async () => {
        const response = await api.get('/companies');
        setCompanies(response.data);
      }, setError); 
    },
  }),
});
