import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { getToken } from '@services/api';
import * as Application from '@flux/models/application/hooks';
import { useEffect } from 'react';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

function Route({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps) {
  const token = getToken();
  const {
    error,
    verifyToken,
    clearData,
  } = Application.useModel();

  useEffect(()=> {
    if (token) {
      verifyToken();
    }
  }, [isPrivate, token, verifyToken]);

  useEffect(() => {
    if (error === 'Invalid JWT token') clearData();
  }, [error, clearData]);

  if (isPrivate && !token)
    return (
      <ReactDOMRoute
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
    );

  if (!isPrivate && token) 
    return (
      <ReactDOMRoute
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/atendimentos',
              state: { from: location },
            }}
          />
        )}
      />
    );
  return <ReactDOMRoute {...rest} render={() => <Component />} />;
}

export default Route;
