import { useEffect, useState } from 'react';
import { CollaboratorsList } from '@pages/Admin/teamInfo';
import SearchInput from '@components/SearchInput';
import PeriodPicker from '@components/PeriodPicker';
import { User } from '@entities';
import RequestLine from './RequestLine';

import styles from './styles.module.css';

interface RequestsTabProps {
  requests: User[];
  origem: string;
}

function RequestsTab({ requests, origem }: RequestsTabProps) {
  const [searchTerms, setSearchTerms] = useState('');
  const [showRequests, setShowRequests] = useState<User[]>([]);
  const [since, setSince] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);

  const filterBySearch = (term: string, user: User) =>
    user.name.toLocaleLowerCase().includes(searchTerms.toLocaleLowerCase()) ||
    user.company.toLocaleLowerCase().includes(searchTerms.toLocaleLowerCase());

  const filetByPeriod = (user: User) => {
    if (since && until) {
      return (
        new Date(user.created_at) >= since && new Date(user.created_at) <= until
      );
    }
    return null;
  };

  useEffect(() => {
    setShowRequests(requests);
  }, [requests, setShowRequests]);

  useEffect(() => {
    if (searchTerms && since && until) {
      const filtered = requests
        .filter((user: User) => filterBySearch(searchTerms, user))
        .filter((user: User) => filetByPeriod(user));
      setShowRequests(filtered);
    } else if (searchTerms) {
      const filtered = requests.filter((user: User) =>
        filterBySearch(searchTerms, user)
      );
      setShowRequests(filtered);
    } else if (since && until) {
      const filtered = requests.filter((user: User) => filetByPeriod(user));
      setShowRequests(filtered);
    } else {
      setShowRequests(requests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms, since, until]);

  return (
    <div>
      <section className={styles.navBarTeam}>
        <h1>Aprovação de novos usuários</h1>
        <SearchInput
          placeholder="Pesquise por nome e empresa..."
          type="text"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />

        <PeriodPicker
          onSelect={(period) => {
            setSince(period.since);
            setUntil(period.until);
          }}
        />
      </section>
      <section className={styles.requestsContainer}>
        {showRequests.map((colab) => (
          <RequestLine 
            key={Math.random()} 
            collaborator={colab} 
            origem={origem} 
          />
        ))}
      </section>
    </div>
  );
}
export default RequestsTab;
