import { ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import { ImSpinner9 } from 'react-icons/im';
import styles from './styles.module.css';

export interface SubmitButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  loading?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  iconColor?: string;
  iconSize?: number;
  submit?: boolean;
  backgroundColor?: string;
  fontColor?: string;
}

function SubmitButton({
  label,
  icon: Icon,
  iconColor,
  iconSize,
  submit,
  backgroundColor,
  fontColor,
  loading,
  className,
  style,
  ...props
}: SubmitButtonProps) {
  const Content = () => (
    <>
      {Icon && <Icon size={iconSize || 25} color={iconColor || 'black'} />}
      {label}
    </>
  );

  const Loading = () => (
    <>
      <ImSpinner9 className="spin" />
      Carregando
    </>
  );

  return (
    <button
      {...props}
      type={submit ? 'submit' : 'button'}
      className={`${className} ${styles.button}`}
      style={{
        backgroundColor,
        color: fontColor,
        ...style,
      }}
    >
      {loading ? <Loading /> : <Content />}
    </button>
  );
}

export default SubmitButton;
