import { AiFillCloseCircle } from 'react-icons/ai';
import { Team } from '@entities';
import Modal from '@components/Modal';
import CreateView from './components/CreateView';
import RemoveView from './components/RemoveView';
import UpdateView from './components/UpdateView';
import styles from './styles.module.css';

export type ViewType = 'create' | 'update' | 'remove';

export interface TeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  teams: Team[];
  selectedTeam?: Team
  type?: ViewType;
}

function TeamModal({ isOpen, onClose, teams, type = 'create', selectedTeam }: TeamModalProps) {
  const viewComponent = {
    create: <CreateView teams={teams} onClose={onClose} />,
    update: <UpdateView teams={teams} onClose={onClose} selectedTeam={selectedTeam} />,
    remove: <RemoveView teams={teams} onClose={onClose} selectedTeam={selectedTeam} />,
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <header className={styles.header}>
        <h1>Equipes</h1>
        <button type="button" onClick={onClose}>
          <AiFillCloseCircle size={30} color="#999" />
        </button>
      </header>

      {viewComponent[type]}
    </Modal>
  );
}

export default TeamModal;
