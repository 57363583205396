import { FormEvent, useEffect } from 'react';
import Modal from '@components/Modal';
import LoadingLabel from '@components/LoadingLabel';
import styles from './styles.module.css';

export interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  onAfterClose?: () => void;
  contentLabel?: string;
  success?: boolean;
  confirm: (e: FormEvent) => void;
  error: string;
  setError: (e: string) => void;
}

function ConfirmReactivationModal({
  isOpen,
  onClose,
  message,
  success,
  confirm,
  error,
  setError,
}: AlertModalProps) {

  return (
    <div className={styles.contentModal}>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div>
          <div className={styles.messageContainer}>
            <p>Deseja enviar a seguinte mensagem? </p>
            <div className={styles.message}>
              <p>{message}</p>
            </div>
            {error && <span>{error}</span>}
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.cancel} type="button" onClick={onClose}>
              Cancelar
            </button>
            <button className={styles.delete} type="button" onClick={confirm}>
              Enviar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}


export default ConfirmReactivationModal;
