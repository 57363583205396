import { useState } from 'react';
import debounce from 'awesome-debounce-promise';
import { FaAngleDown } from 'react-icons/fa';
import { AiFillCloseCircle, AiOutlineClear } from 'react-icons/ai';
import { useModel, useLoading } from '@flux/models/pages/historyPage/hooks';
import Modal from '@components/Modal';
import SearchInput from '@components/SearchInput';
import LoadingLabel from '@components/LoadingLabel';
import { limitStringSize } from '@utils/strings';
import styles from './styles.module.css';

export interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (selected: string, selectedName: string) => void;
  label: string;
  mode: 'attendants' | 'users';
}

function SearchModal({
  isOpen,
  onClose,
  label,
  mode,
  onSelect,
}: SearchModalProps) {
  const { results, searchByName, setResults } = useModel();
  const searchDebounced = debounce(searchByName, 500);
  const { loadingSearch } = useLoading();

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onClose()}>
      <header className={styles.header}>
        <h1>{label}</h1>
        <button type="button" onClick={onClose}>
          <AiFillCloseCircle size={30} color="#999" />
        </button>
      </header>

      <div className={styles.inputWrapper}>
        <SearchInput
          containerWidth="100%"
          placeholder="Pesquise pelo nome..."
          onChange={(e) => {
            searchDebounced({
              name: e.target.value,
              mode,
            });
          }}
        />
      </div>

      <div className={styles.items}>
        {!loadingSearch && (
          <button
            onClick={() => {
              setResults([]);
              onSelect('', '');
              onClose();
            }}
            type="button"
            className={styles.clearSelection}
          >
            <AiOutlineClear size={20} />
            Limpar seleção
          </button>
        )}

        {loadingSearch ? (
          <div className={styles.loading}>
            <LoadingLabel size="medium" />
          </div>
        ) : (
          results.map((item) => (
            <button
              onClick={() => onSelect(item.id || '', item.name)}
              type="button"
              key={Math.random()}
              className={styles.resultItem}
            >
              <p>
                <strong>Nome:</strong> {item.name}
              </p>
              <p hidden={!item.lastProtocol}>
                <strong>Último protocolo:</strong> {item.lastProtocol}
              </p>
              <p hidden={!item.lastClosedAt}>
                <strong>Último fechamento:</strong> {item.lastClosedAt}
              </p>
            </button>
          ))
        )}
      </div>
    </Modal>
  );
}

export interface SearchPickerProps {
  label: string;
  onSelect: (selected: string, selectedName: string) => void;
  mode: 'attendants' | 'users';
  isChat?: boolean;
}

function SearchPicker({ label, onSelect, mode, isChat }: SearchPickerProps) {
  const [selected, setSelected] = useState('Qualquer');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        type="button"
        className={styles.container}
        onClick={() => setShowModal(true)}
      >
        {isChat ? 
          <span className={styles.current}>
            <p>{label}</p>
          </span>
          :
          <>
            <p>{label}</p>
            <span className={styles.current}>
              <p>{selected ? limitStringSize(selected, 9) : 'Qualquer'}</p>
              <FaAngleDown size={20} />
            </span>
          </>}
      </button>

      <SearchModal
        mode={mode}
        label={label}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSelect={(value, name) => {
          setSelected(value);
          onSelect(value, name);
        }}
      />
    </>
  );
}

export default SearchPicker;
