import { Link } from 'react-router-dom';
import { ImBubbles } from 'react-icons/im';
import styles from './styles.module.css';

export interface LinkToProps {
  to: string;
  onClick?: () => void;
}

function LinkTo({ to, onClick }: LinkToProps) {
  return (
    <Link onClick={onClick} className={styles.container} to={to}>
      <ImBubbles size={20} color="#4a69bd" />
    </Link>
  );
}

export default LinkTo;
