import { IconType } from 'react-icons';
import { RiExchangeFill, RiFacebookCircleFill, RiWhatsappFill } from 'react-icons/ri';
import { GrLanguage } from 'react-icons/gr';
import { BsPersonFill } from 'react-icons/bs';
import { Attendance, Team, User } from '@entities';
import { columnAlphabeticCompare, columnDateCompare } from '@utils/compare';
import { Column, LineItem } from '@components/Table';
import LinkTo from './components/LinkTo';

type PlatformOption = 'whatsapp' | 'facebook' | 'web';

const ICONS: { [platform: string]: IconType } = {
  whatsapp: RiWhatsappFill,
  facebook: RiFacebookCircleFill,
  web: GrLanguage,
};

const COLORS: { [platform: string]: string } = {
  whatsapp: 'green',
  facebook: 'blue',
  web: 'black',
};

export const PENDING_COLUMNS: Column[] = [
  { label: 'Entrar', key: 'enter' },
  { label: 'Protocolo', key: 'protocol', compareFn: columnAlphabeticCompare },
  { label: 'Plataforma', key: 'platform', compareFn: columnAlphabeticCompare },
  {
    label: 'Hora do atendimento',
    key: 'attendance',
    compareFn: columnDateCompare,
  },
  { label: 'Usuário', key: 'user', compareFn: columnAlphabeticCompare },
  { label: 'Equipe', key: 'team', compareFn: columnAlphabeticCompare }
];

export function pendingToLineItem(
  attendances: Attendance[],
  onEnter: (attendance: Attendance) => void,
  teams?: Team[]
): LineItem[] {
  return attendances.map((item) => {
    const attendanceDate = new Date(item.created_at).toLocaleString('pt-BR');
    const Icon = ICONS[item.platform as PlatformOption];
    const color = COLORS[item.platform as PlatformOption];
    const teamId = item.team_id;
    let teamName = '-';
    if (teamId && teams) {
      const teamIndex = teams.findIndex(e => e.id == teamId);
      if (teamIndex >= 0) {
        teamName = teams[teamIndex].name;
      }
    }

    return {
      protocol: item.protocol,
      platform: <Icon size={20} color={color} />,
      attendance: attendanceDate,
      user: item.user_name,
      enter: (
        <LinkTo
          onClick={() => onEnter(item)}
          to={`/atendimentos/${item.protocol}`}
        />
      ),
      team: teamName
    };
  });
}

export const CURRENT_COLUMNS: Column[] = [
  { label: 'Entrar', key: 'enter' },
  { label: 'Atendente', key: 'attendant' },
  { label: 'Plataforma', key: 'platform' },
  { label: 'Hora do atendimento', key: 'attendance' },
  { label: 'Usuário', key: 'user' },
  { label: 'Equipe', key: 'team' },
  { label: 'Protocolo', key: 'protocol' },
];

export function currentToLineItem(
  attendances: Attendance[],
  onEnter: (attendance: Attendance) => void,
  inactiveAttendances: Attendance[],
  messagesNotRead: { [index: string]: number },
  user: User,
  teams?: Team[]
): LineItem[] {
  const inactiveAttedancesID = inactiveAttendances.map(item => item.user_id);
  console.log('inactiveAttedancesID', inactiveAttedancesID);

  return attendances.map((item) => {
    const attendanceDate = new Date(item?.opened_at || '').toLocaleString(
      'pt-BR'
    );
    const Icon = ICONS[item.platform as PlatformOption];
    let color = '';

    if (inactiveAttedancesID.includes(item.user_id)) {
      color = COLORS[item.platform as PlatformOption];
    }

    if (messagesNotRead[item.user_id] && messagesNotRead[item.user_id] > 0) {
      color = COLORS[item.platform as PlatformOption];
    }

    const teamId = item.team_id;
    let teamName = '-';
    if (teamId && teams) {
      const teamIndex = teams.findIndex(e => e.id == teamId);
      if (teamIndex >= 0) {
        teamName = teams[teamIndex].name;
      }
    }

    const isThisAttendance = () =>  
      <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
        <LinkTo
          onClick={() => onEnter(item)}
          to={`/atendimentos/${item.protocol}`}
        />
        {
          item.attendant_id === user?.id && ( user?.type === 'administrator' || user?.type === 'manager' ) 
          &&
          <BsPersonFill size={20} color='#222222' aria-label='Você é o atendente' />
        }
      </div>;

    return {
      protocol: item.protocol,
      attendant: item.attendant_name,
      platform: <Icon size={20} color={color} />,
      attendance: attendanceDate,
      user: <UserName attendance={item} />,
      enter: isThisAttendance(),
      team: teamName
    };
  });
}

function UserName(props: { attendance: Attendance }) {
  const isTransferAttend = (attendance: Attendance) => attendance.tags.find(tag =>
    tag.company == 'roboter-assist' && tag.name == 'atendimento-transferido'
  );

  return (
    <div>
      { isTransferAttend(props.attendance) ?
        <p title='Esse atendimento foi transferido'>{props.attendance.user_name} <RiExchangeFill /></p>
      :
      props.attendance.user_name}
    </div>
  );
}

export const INACTIVE_COLUMNS: Column[] = [
  { label: 'Entrar', key: 'enter' },
  { label: 'Inativo', key: 'inactive' },
  { label: 'Plataforma', key: 'platform' },
  { label: 'Atendente', key: 'attendant' },
  { label: 'Equipe', key: 'team' },
  { label: 'Hora do atendimento', key: 'attendance' },
  { label: 'Usuário', key: 'user' },
  { label: 'Protocolo', key: 'protocol' },
];

export function inactiveToLineItem(
  attendances: Attendance[],
  onEnter: (attendance: Attendance) => void,
  teams?: Team[]
): LineItem[] {
  const filtered = attendances.filter(item => item.last_user_message_at);
  
  return filtered.map((item) => {
    const lastUserInteraction = new Date(item.last_user_message_at!).toLocaleString();

    const attendanceDate = new Date(item?.opened_at || '').toLocaleString(
      'pt-BR'
    );
    const Icon = ICONS[item.platform as PlatformOption];
    const color = COLORS[item.platform as PlatformOption];

    const teamId = item.team_id;
    let teamName = '-';
    if (teamId && teams) {
      const teamIndex = teams.findIndex(e => e.id == teamId);
      if (teamIndex >= 0) {
        teamName = teams[teamIndex].name;
      }
    }

    return {
      protocol: item.protocol,
      inactive: lastUserInteraction,
      platform: <Icon size={20} color={color} />,
      attendant: item.attendant_name,
      team: teamName,
      attendance: attendanceDate,
      user: <UserName attendance={item} />,
      enter: (
        <LinkTo
          onClick={() => onEnter(item)}
          to={`/atendimentos/${item.protocol}`}
        />
      ),
    };
  });
}