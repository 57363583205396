import { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import clock from '@assets/clock.png';
import PersonalizedModal from './components/PersonalizedModal';
import { PeriodLabel, getPeriods, Period } from './periods';
import styles from './styles.module.css';

export interface DatePickerProps {
  onSelect: (period: Period) => void;
}

function DatePicker({ onSelect }: DatePickerProps) {
  const [selected, setSelected] = useState<PeriodLabel>('Mês atual');
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <PersonalizedModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSelect={(period) => {
          onSelect(period);
          setSelected('Intervalo personalizado');
        }}
      />

      <div className={styles.header}>
        <img src={clock} alt="Período" />
        <h2>Período</h2>
      </div>

      <div className={styles.current}>
        <p>{selected}</p>
        <FaAngleDown color="#4a69bd" size={20} />
      </div>

      <ul className={styles.dropDownContent}>
        <li hidden={selected === 'Hoje'}>
          <button
            onClick={() => {
              setSelected('Hoje');
              onSelect(getPeriods().today);
            }}
            type="button"
          >
            Hoje
          </button>
        </li>
        <li hidden={selected === 'Ontem'}>
          <button
            onClick={() => {
              setSelected('Ontem');
              onSelect(getPeriods().yesterday);
            }}
            type="button"
          >
            Ontem
          </button>
        </li>
        <li hidden={selected === 'Semana atual'}>
          <button
            onClick={() => {
              setSelected('Semana atual');
              onSelect(getPeriods().currentWeek);
            }}
            type="button"
          >
            Semana atual
          </button>
        </li>
        <li hidden={selected === 'Última semana'}>
          <button
            onClick={() => {
              setSelected('Última semana');
              onSelect(getPeriods().lastWeek);
            }}
            type="button"
          >
            Última semana
          </button>
        </li>
        <li hidden={selected === 'Mês atual'}>
          <button
            onClick={() => {
              setSelected('Mês atual');
              onSelect(getPeriods().currentMonth);
            }}
            type="button"
          >
            Mês atual
          </button>
        </li>
        <li hidden={selected === 'Último mês'}>
          <button
            onClick={() => {
              setSelected('Último mês');
              onSelect(getPeriods().lastMonth);
            }}
            type="button"
          >
            Último mês
          </button>
        </li>
        <li>
          <button onClick={() => setShowModal(true)} type="button">
            Intervalo personalizado
          </button>
        </li>
      </ul>
    </div>
  );
}

export default DatePicker;
