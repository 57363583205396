import { AiFillPlusCircle } from 'react-icons/ai';
import { RiTeamFill } from 'react-icons/all';
import { FormEvent, useCallback, useState } from 'react';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import { useModel as useModelApplication } from '@flux/models/application/hooks';
import { Team } from '@entities';
import styles from './styles.module.css';

interface CreateViewProps {
  teams: Team[],
  onClose: Function
}

function CreateView({ teams, onClose }: CreateViewProps) {
  const [name, setName] = useState('');
  const { createTeam } = useModel();
  const { user } = useModelApplication();

  const company = user ? user.company : '';

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (name) {
        createTeam({ name, teams, company });
        onClose();
      }
    },
    [name, createTeam]
  );

  return (
    <div className={styles.container}>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.title}>
          <h1>Criar Equipe</h1>
        </div>

        <div className={styles.input}>
          <RiTeamFill size={20} />
          <input
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <button className={styles.newTeam} type="submit">
          <span>Adicionar Nova Equipe</span>
          <AiFillPlusCircle size={30} color="white" />
        </button>
      </form>
    </div>
  );
}

export default CreateView;
