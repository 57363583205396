
import { Message } from '@entities';
import { useEffect, useState } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
  setMedia: (type: string, content: string) => void;
}

function AudioMessage({ message, setMedia }: MessageProps) {
  const [show, setShow] = useState('play');
  const [audio] = useState(new Audio(message.body?.media_content || '')); 

  useEffect(() => {
    audio.onended = () => {
      setShow('play');
    };
  }, [audio, show]);

  return (
    <div
      id={`audio-message${Math.random()}`}
      className={
        message.origin === 'user'
          ? styles.messageOrigin
          : styles.messageSender
      }
    >
      <div>
        <button 
          type='button' 
          onClick={() => {
            setMedia(message.body?.message_type || '', message.body?.media_content || '');
          }}
        >
          <FaPlay size={30} color="white" />
          {message.body?.content_caption ? <p style={{ color: 'white' }}> {message.body?.content_caption} </p> : <> </>} 
        </button>
      </div>
      
    </div>
  );
}

export default AudioMessage;