import { AiFillPlusCircle } from 'react-icons/ai';
import { RiPhoneFill, RiRobotLine, RiTeamFill } from 'react-icons/all';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useModel } from '@flux/models/pages/contactsPage/hooks';
import { useModel as useModelApplication } from '@flux/models/application/hooks';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import { Contact } from '@entities';
import styles from './styles.module.css';

interface CreateViewProps {
  contacts: Contact[],
  onClose: Function
}

function CreateView({ contacts, onClose }: CreateViewProps) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const { createContact } = useModel();
  const { user } = useModelApplication();

  const { getChatbots, chatbots } = AdminHook.useModel();
  const [selectedBot, setSelectedBot] = useState('');

  useEffect(() => {
    getChatbots(user?.company || '');
  }, [getChatbots, user?.company]);

  useEffect(() => {
    if (chatbots.length > 0) {
      setSelectedBot(chatbots[0].id);
    }
  }, [chatbots]);

  const company = user ? user.company : '';

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (name && phone) {
        createContact({ name, phone, bot_id: selectedBot, company, contacts, onClose });
      }
    },
    [name, phone, createContact, company, contacts, onClose]
  );

  return (
    <div className={styles.container}>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.title}>
          <h1>Criar Contato</h1>
        </div>

        <div className={styles.input}>
          <RiTeamFill size={20} />
          <input
            placeholder="Nome"
            value={name}
            required={true}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className={styles.input}>
          <RiPhoneFill size={20} />
          <input
            placeholder="Telefone"
            value={phone}
            required={true}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className={styles.input}>
          <RiRobotLine size={20} />
          <select
            value={selectedBot}
            onChange={e => setSelectedBot(e.target.value)}
            required={true}
          >
            {chatbots.map(bot =>
              <option key={bot.id} value={bot.id}>{bot.name}</option>
            )}
          </select>
        </div>

        <button className={styles.newContactButton} type="submit">
          <span>Adicionar Novo Contato</span>
          <AiFillPlusCircle size={30} color="white" />
        </button>
      </form>
    </div>
  );
}

export default CreateView;
