import Metric, { MetricProps } from '@components/Metric';
import { formatNumber } from '@utils/strings';
import { useLoading } from '@flux/models/pages/statisticsPage/hooks';
import Info, { InfoProps } from '../Info/index';
import Chart, { ChartItemProps } from '../Chart/index';
import styles from './styles.module.css';

export interface StatsProps {
  statsInfos: InfoProps[];
  statsAverages: MetricProps[];
  statsChart: MetricProps[];
  data: ChartItemProps[];
}

function Stats({ statsInfos, statsAverages, statsChart, data }: StatsProps) {
  const { 
    averageAttendanceLoading,
    statisticsInfosLoading,
    statisticsChartsLoading,
    chartsInfosLoading
   } = useLoading();
  console.log(statsChart);
  return (
    <>
      <div className={styles.statsQty}>
        {statsInfos.map((info) => (
          <Info
            key={Math.random()}
            title={info.title}
            quantity={info.quantity}
            loading={statisticsInfosLoading}
          />
        ))}
      </div>

      <div className={styles.statsAverage}>
        {statsAverages.map((average) => (
          <Metric
            key={Math.random()}
            title={average.title}
            description={average.description}
            value={formatNumber(Number(average.value))}
            img={average.img}
            loading={averageAttendanceLoading}
          />
        ))}
      </div>

      <div className={styles.statsChart}>
        <div className={styles.chart}>
          <h1>Atendimentos a cada 2 horas do dia</h1>
          <Chart data={data} loading={chartsInfosLoading} />
        </div>
        <div className={styles.tax}>
          {statsChart.map((stats) => (
            <Metric
              key={Math.random()}
              title={stats.title}
              value={formatNumber(Number(stats.value))}
              description={stats.description}
              loading={statisticsChartsLoading}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Stats;
