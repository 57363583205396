import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import api, { errorHandler } from '@services/api';

export interface ProfilePageState {
  error: string;
  success: boolean;
}

interface HandleProfilePayload {
  newName: string | undefined;
  newEmail: string | undefined;
  newCompany: string | undefined;
}

export const profilePage = createModel<RootModel>()({
  state: {
    error: '',
    success: false,
  } as ProfilePageState,

  reducers: {
    setSuccess(state, payload: boolean) {
      state.success = payload;
      return state;
    },
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async updateProfile(payload: HandleProfilePayload, state) {
      const { setUser } = dispatch.application;
      const { setSuccess, setError } = dispatch.profilePage;
      const { newName, newEmail, newCompany } = payload;

      await errorHandler(async () => {
        await api.put('/profile', {
          name: newName,
          email: newEmail,
          company: newCompany,
        });

        const response = await api.get('/profile');
        const userUpdate = response.data;
        setUser(userUpdate);
        setSuccess(true);
      }, setError);
    },
    async updateAvatar(payload: File | null) {
      const { setUser } = dispatch.application;

      const { setSuccess, setError } = dispatch.profilePage;

      const data = new FormData();

      if (!payload) return;

      data.append('avatar', payload);

      await errorHandler(async () => {
        await api.patch('/users/avatar', data);

        const response = await api.get('/profile');
        const userUpdate = response.data;
        setUser(userUpdate);
        setSuccess(true);
      }, setError);
    },
  }),
});
