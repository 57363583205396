import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { connectionError } from '@utils/messages.json';
import api from '@services/api';

export async function errorHandler(
  callback: () => Promise<void>,
  setError: (message: string) => void
) {
  try {
    await callback();
  } catch (error) {
    if (error.response && error.response.data.message) {
      // eslint-disable-next-line no-console
      console.log('API Error', error.response.data);
      setError(error.response.data.message);
    } else if (error.response && error.response.data.Error) {
      setError(error.response.data.Error);
    } else {
      // eslint-disable-next-line no-console
      console.log('Some error', error);
      setError(connectionError);
    }
  }
}

export interface SettingsPageState {
  error: string;
  successEmail: boolean;
  successPIN: boolean;
}

interface HandleSendEmailPayload {
  email: string;
}

interface HandleVerifyPINPayload {
  pin: string;
}

export const settingsPage = createModel<RootModel>()({
  state: {
    error: '',
    successEmail: false,
    successPIN: false,
  } as SettingsPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setSuccessEmail(state, payload: boolean) {
      state.successEmail = payload;
      return state;
    },

    setSuccessPIN(state, payload: boolean) {
      state.successPIN = payload;
      return state;
    },
  },

  effects: (dispatch) => ({
    async handleSendEmail(payload: HandleSendEmailPayload, state) {
      const { setSuccessEmail, setError } = dispatch.settingsPage;
      const { email } = payload;

      await errorHandler(async () => {
        await api.post('/users/pin', {
          email,
        });
        setSuccessEmail(true);
      }, setError);
    },

    async handleVerifyPIN(payload: HandleVerifyPINPayload, state) {
      const { setSuccessPIN, setError } = dispatch.settingsPage;
      const { pin } = payload;

      if (pin.length === 6) {
        await errorHandler(async () => {
          // eslint-disable-next-line no-console
          console.log('called from pin', pin);
          await api.post('/users/check-pin', {
            pin: pin.toUpperCase(),
          });
          setSuccessPIN(true);
        }, setError);
      }
    },
  }),
});
