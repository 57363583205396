import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { StatisticsPageState } from './entities';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const statisticsPageState = useSelector<RootState, StatisticsPageState>(
    (state) => state.statisticsPage
  );

  return {
    ...dispatch.statisticsPage,
    ...statisticsPageState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.models.statisticsPage
  );

  const averageAttendanceLoading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.statisticsPage.getStatisticsAverage
  );

  const statisticsInfosLoading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.statisticsPage.getStatisticsInfos
  );

  const statisticsChartsLoading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.statisticsPage.getStatisticsCharts
  );

  const chartsInfosLoading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.statisticsPage.getChartsInfos
  );

  return { 
    loading, 
    averageAttendanceLoading, 
    statisticsInfosLoading, 
    statisticsChartsLoading,  
    chartsInfosLoading
  };
};
