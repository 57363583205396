
import { Message } from '@entities';
import { dateLabel } from '@utils/strings';
import styles from './styles.module.css';

interface MessageProps {
  message: Message;
}

function TextMessage({ message }: MessageProps) {
  const textFormatter = (text: string) => {
    const paragrahList = text.split('\n');
    return paragrahList.map(paragrah => <p key={Math.random()}>{paragrah}</p>);
  };
  return (
    <div
      className={
        message.origin === 'user'
          ? styles.messageOrigin
          : styles.messageSender
      }
      id={`text-message${Math.random()}`}
    >
      <p>{textFormatter(message.message)}</p>
      <span>{dateLabel(message.created_at)}</span>
    </div>
  );
}

export default TextMessage;