import { BrowserRouter } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import Routes from './Routes';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/global.css';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
