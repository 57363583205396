import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { TagsState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const tagsState = useSelector<RootState, TagsState>((state) => state.tags);

  return {
    ...dispatch.tags,
    ...tagsState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.models.tags
  );

  return loading;
};
