import Modal from '@components/Modal';
import styles from './styles.module.css';

export interface ConfirmModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  message: string;
}

function ConfirmModal({ isOpen, onConfirm, onClose, message }: ConfirmModalProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={styles.messageContainer}>
        <p>{message}</p>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancel} type="button" onClick={onClose}>
          Cancelar
        </button>
        <button className={styles.confirm} type="button" onClick={onConfirm}>
          Sim
        </button>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
