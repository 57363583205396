import { useState, useCallback, FormEvent } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { AiFillPlusCircle, AiTwotoneTags } from 'react-icons/ai';
import { useModel } from '@flux/models/tags/hooks';
import styles from './styles.module.css';

export interface CreateViewProps {
  onBack: () => void;
}

function CreateView({ onBack }: CreateViewProps) {
  const { createTag } = useModel();
  const [name, setName] = useState('');

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (name) createTag(name);
      onBack();
    },
    [name, createTag, onBack]
  );

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.title}>
        <button onClick={onBack} type="button">
          <IoIosArrowBack size={20} />
          Voltar
        </button>

        <h1>Criação de Tag</h1>
      </div>

      <div className={styles.input}>
        <AiTwotoneTags size={20} />
        <input
          placeholder="Nome da nova tag"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <button className={styles.newTag} type="submit">
        <span>Adicionar nova tag</span>
        <AiFillPlusCircle size={30} color="white" />
      </button>
    </form>
  );
}

export default CreateView;
