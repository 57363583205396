import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { BulkMessagingPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const bulkMessagingPageState = useSelector<RootState, BulkMessagingPageState>(
    (state) => state.bulkMessagingPage
  );

  return {
    ...dispatch.bulkMessagingPage,
    ...bulkMessagingPageState,
  };
};

export const useLoading = () => {
  const loadingBulkingMessage = useSelector<RootState, boolean>(
    (state) => state.loading.effects.bulkMessagingPage.bulkMessaging
  );

  return { loadingBulkingMessage };

};
