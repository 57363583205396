import DashboardMenu from '@components/DashboardMenu';
import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { newMessageNotification } from '@utils/notifications';
import { useModel, useLoading } from '@flux/models/pages/settingsPage/hooks';
import * as settingsOptionsHooks from '@flux/models/pages/SettingsOptionsPage/hooks';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import AlertModal from '@components/AlertModal';
import { emailSent } from '@utils/messages.json';
import { Message } from '@entities';
import styles from './styles.module.css';

function Settings() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const loading = useLoading();
  const {
    error,
    setError,
    successPIN,
    setSuccessPIN,
    handleSendEmail,
    handleVerifyPIN,
    successEmail,
    setSuccessEmail,
  } = useModel();
  const { setAuthorized } = settingsOptionsHooks.useModel();
  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();
  const firstUpdate = useRef(true);


  useEffect(() => {
    if (successPIN) {
      setAuthorized(true);
      setSuccessPIN(false);
      setSuccessEmail(false);
      history.push('/configuracoes/escolhas');
    }
  }, [successPIN, history, setAuthorized, setSuccessPIN, setSuccessEmail]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSendEmail({ email });
    },
    [email, handleSendEmail]
  );

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    handleVerifyPIN({ pin });
  }, [pin, handleVerifyPIN]);

  useEffect(() => {
    if (successEmail) setError(emailSent);
  }, [successEmail, setError]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, []);

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Configurações</h1>
              <p>
                Entre com seu e-mail associado e receba um código PIN<br />
                para habilitar a modificação das suas configurações de usuário.
              </p>
            </div>
          </section>

          <section className={styles.body}>
            <h1>E-mail</h1>
            <form className={styles.form} onSubmit={handleSubmit}>
              <FormInput
                type="text"
                name="email"
                placeholder="e-mail"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />

              <SubmitButton
                submit
                label="Enviar instruções"
                className={styles.submitButton}
                loading={loading.loadingSendEmail}
              />
            </form>
            <h2>Entre com o PIN</h2>
            <div className={styles.pinCode}>
              <input
                type="password"
                maxLength={6}
                onFocus={(e) => {
                  e.target.type = 'text';
                }}
                onBlur={(e) => {
                  e.target.type = 'password';
                }}
                className={styles.pinInput}
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
          </section>
          <AlertModal
            isOpen={!!error}
            message={error}
            onClose={() => setError('')}
          />
        </div>
      </DashboardMenu>
    </div>
  );
}
export default Settings;
