import { FaAngleLeft, FaAngleRight, FaSortDown, FaSortUp } from 'react-icons/fa';
import styles from './styles.module.css';

export interface PaginatorProps {
  current: number;
  hasNext: boolean;
  nextPage: () => void;
  previousPage: () => void;
  DescOrder: () => void;
  AscOrder: () => void;
}

function Paginator({ current, hasNext, nextPage, previousPage, DescOrder, AscOrder }: PaginatorProps) {
  return (
    <div style={{ background: 'transparent' }} className={styles.container}>

      <div className={styles.paginator}>
        <button type="button" onClick={previousPage} disabled={current === 1}>
          <FaAngleLeft size={18} color="black" />
        </button>

        <span>Página {current}</span>

        <button type="button" onClick={nextPage} disabled={!hasNext}>
          <FaAngleRight size={18} color="black" />
        </button>
      </div>

      <div className={styles.orderer}>
        <button type="button" onClick={DescOrder}>
          <FaSortDown size={20} color="black" style={{ marginTop: -10 }} />
        </button>

        <button type="button" onClick={AscOrder}>
          <FaSortUp size={20} color="black" style={{ marginTop: 10 }} />
        </button>
      </div>
    </div>
  );
}

export default Paginator;
