import { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { FaAngleDown } from 'react-icons/fa';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import * as Application from '@flux/models/application/hooks';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import { useModel } from '@flux/models/pages/statisticsPage/hooks';
import { useModel as tagModel } from '@flux/models/tags/hooks';
import { newMessageNotification } from '@utils/notifications';
import DashboardMenu from '@components/DashboardMenu';
import DatePicker from '@components/DatePicker';
import PeriodPicker from '@components/PeriodPicker';
import { Period } from '@components/PeriodPicker/periods';
import SearchPicker from '@components/SearchPicker';
import RegularPicker, { PickerItem } from '@components/RegularPicker';
import EditableLabel from '@components/EditableLabel';
import { Message } from '@entities';
import TagsModal from '@components/TagsModal';
import Header from './components/Header';
import Stats from './components/Stats';
import CurrentView from './components/CurrentView';
import Placeholder from './components/Placeholder';
import styles from './styles.module.css';


function Statistics() {
  const { user } = Application.useModel();
  const [selectedTags, setSelectedTags] = useState([] as PickerItem[]);
  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();
  const firstUpdate = useRef(true);


  const {
    setGetStatisticsPayload,
    chartInfos,
    statsAverages,
    statsChart,
    statsInfos,
    getStatisticsAverage,
    getStatisticsInfos,
    getStatisticsCharts,
    getChartsInfos
  } = useModel();

  const {
    teams,
    getTeams
  } = AdminHook.useModel();

  const [client, setClient] = useState('');
  const [attendant, setAttendant] = useState('');
  const [attendantName, setAttendantName] = useState('');
  const [protocol, setProtocol] = useState('');
  const [platform, setPlatform] = useState('');
  const [openingDate, setOpeningDate] = useState<Date | null>(null);
  const [closingDate, setClosingDate] = useState<Date | null>(null);
  const [since, setSince] = useState(defaultPeriod.getSince());
  const [until, setUntil] = useState(defaultPeriod.getUntil());
  const [team, setTeam] = useState('');
  const [showTags, setShowTags] = useState(false);
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const handlePeriod = (period: Period) => {
    const sinceString = period.since.toISOString();
    const untilString = period.until.toISOString();

    setSince(sinceString);
    setUntil(untilString);
  };

  useEffect(() => {
    setGetStatisticsPayload({
      since,
      until,
      attendant_id: attendant,
      client,
      closed_at: closingDate ? closingDate.toISOString() : '',
      opened_at: openingDate ? openingDate.toISOString() : '',
      platform,
      protocol,
      team,
      tag,
    });
    getStatisticsAverage();
    getStatisticsInfos();
    getStatisticsCharts();
    getChartsInfos();
  }, [
    client,
    attendant,
    protocol,
    platform,
    openingDate,
    closingDate,
    since,
    until,
    team,
    tag,
    setGetStatisticsPayload,
    getStatisticsAverage,
    getStatisticsInfos,
    getStatisticsCharts,
    getChartsInfos
  ]);

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      getTeams(user?.company || '');
    }
    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, []);

  const showStats = () =>
    user?.type === 'administrator' && client
      ? String(user?.type) !== 'attendant' || String(user?.type) !== 'manager'
      : user?.type === 'attendant' || user?.type === 'manager';

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.container} id="Statistics">
          <section className={styles.headerContainer}>
            <Header
              title="Estatísticas"
              description="Analise suas métricas de atendimento"
            />
            <PeriodPicker onSelect={handlePeriod} />
          </section>

          <section className={styles.dataContainer}>
            <div className={styles.data}>
              {showStats() ? (
                <>
                  {client && attendant && (
                    <CurrentView title={`${attendant} - ${client}`} />
                  )}

                  {client && !attendant && (
                    <CurrentView title={`Visão Geral - ${client}`} />
                  )}

                  {!client && attendant && (
                    <CurrentView title={`${attendantName}`} />
                  )}

                  {!client && !attendant && <CurrentView title="Visão Geral" />}

                  <Stats
                    statsInfos={statsInfos}
                    statsAverages={statsAverages}
                    statsChart={statsChart}
                    data={chartInfos}
                  />
                </>
              ) : (
                <Placeholder description="Selecione algum cliente/marca para que os dados sejam obtidos." />
              )}
            </div>

            <div className={styles.filters}>

              <div>
                <button
                  type="button"
                  className={styles.optionItem}
                  onClick={() => {
                    setShowTags(true);
                  }}
                >
                  <span className={styles.tagsLabel}>Tags do atendimento</span>
                  <span className={styles.tagsValue}>
                    <p>{tags.length > 0 ? 'Alterar' : 'Qualquer'}</p>
                    <FaAngleDown size={20} />
                  </span>
                </button>
                <TagsModal
                  isOpen={showTags}
                  onClose={() => setShowTags(false)}
                  viewType='filter'
                  selectTags={{ tags, setTags }}
                  setTags={setTag}
                />
              </div>
              {user?.type === 'administrator' && (
                <EditableLabel
                  label="Cliente/Marca"
                  onSubmit={(value) => setClient(String(value))}
                />
              )}

              {(user?.type === 'manager' || user?.type === 'administrator') && (
                <div>
                  <SearchPicker
                    mode="attendants"
                    label="Atendente"
                    onSelect={(id, name) => {
                      setAttendant(id);
                      setAttendantName(name);
                    }}
                  />
                </div>
              )}

              <EditableLabel
                label="Protocolo"
                onSubmit={(value) => setProtocol(String(value))}
              />

              <div>
                <RegularPicker
                  label="Plataforma"
                  options={PLATFORM_OPTIONS}
                  onSelect={(value) => setPlatform(String(value))}
                  unpicked={UNPICKED_ITEM}
                />
              </div>

              <div>
                <RegularPicker
                  label="Equipe"
                  options={teams.map(teamTemp => (
                    {
                      label: teamTemp.name,
                      value: teamTemp.id
                    }
                  ))}
                  onSelect={(selected) => setTeam(String(selected))}
                  unpicked={UNPICKED_ITEM}
                />
              </div>

              <div>
                <DatePicker
                  label="Data de abertura"
                  selected={openingDate}
                  onSelect={(value) => setOpeningDate(value)}
                />
              </div>

              <div>
                <DatePicker
                  label="Data de fechamento"
                  selected={closingDate}
                  onSelect={(value) => setClosingDate(value)}
                />
              </div>
            </div>
          </section>
        </div>
      </DashboardMenu>
    </div>
  );
}

export const defaultPeriod = {
  getSince() {
    return DateTime.now().minus({ months: 1 }).startOf('day').toISO();
  },

  getUntil() {
    return DateTime.now().toISO();
  },
};

const PLATFORM_OPTIONS: PickerItem[] = [
  { label: 'Web', value: 'web' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Facebook', value: 'facebook' },
];

const UNPICKED_ITEM = {
  label: 'Qualquer',
  value: '',
};

export default Statistics;