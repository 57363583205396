import { useState, useEffect, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import { FaAngleDown } from 'react-icons/fa';
import 'react-notifications-component/dist/theme.css';
import { Message, User, UserType } from '@entities';
import * as Application from '@flux/models/application/hooks';
import * as Attendance from '@flux/models/pages/attendancePage/hooks';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import socket, { ONGOING_CHAT_MSG_EVENT } from '@services/socket';
import { useModel, useLoading } from '@flux/models/pages/historyPage/hooks';
import { DateTime } from 'luxon';
import DashboardMenu from '@components/DashboardMenu';
import Table from '@components/Table';
import RegularPicker from '@components/RegularPicker';
import { newMessageNotification } from '@utils/notifications';
import PeriodPicker from '@components/PeriodPicker';
import DatePicker from '@components/DatePicker';
import EditableLabel from '@components/EditableLabel';
import { Period } from '@components/PeriodPicker/periods';
import SearchPicker from '@components/SearchPicker';
import optionsImg from '@assets/history/options.png';
import TagsModal from '@components/TagsModal';
import Paginator from './components/Paginator';

import styles from './styles.module.css';
import {
  PLATFORM_OPTIONS,
  CONTENT_OPTIONS,
  UNPICKED_ITEM,
  HISTORY_COLUMNS,
  historyToLineItem,
  defaultPeriod,
} from './tableInfos';

function History() {
  const {
    getHistory,
    setAttendances,
    attendances,
    setGetHistoryPayload,
    hasNextPage
  } = useModel();
  const { loadingHistory } = useLoading();
  const { user } = Application.useModel();
  const {
    teams,
    getTeams
  } = AdminHook.useModel();
  const { messagesNotReadCounter, getMessagesNotReadCounter, lastMsgSender, lastMsgSenderName, current } = Attendance.useModel();

  const [contentSize, setContentSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [client, setClient] = useState('');
  const [attendant, setAttendant] = useState('');
  const [botUser, setBotUser] = useState('');
  const [protocol, setProtocol] = useState('');
  const [openedAt, setOpenedAt] = useState<Date | null>(null);
  const [closedAt, setClosedAt] = useState<Date | null>(null);
  const [platform, setPlatform] = useState('');
  const [tag_name, setTag] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [phone_number, setPhone] = useState('');
  const [since, setSince] = useState(defaultPeriod.getSince());
  const [until, setUntil] = useState(defaultPeriod.getUntil());
  const [showTags, setShowTags] = useState(false);
  const [team, setTeam] = useState('');
  const firstUpdate = useRef(true);

  useEffect(() => {
    setGetHistoryPayload({
      contentSize,
      page: currentPage,
      since,
      until,
      attendant_id: attendant,
      user_id: botUser,
      protocol,
      client,
      tag_name,
      phone_number,
      opened_at: openedAt ? openedAt.toISOString() : '',
      closed_at: closedAt ? DateTime.fromJSDate(closedAt).endOf('day').toJSDate().toISOString() : '',
      platform,
      team
    });
    getHistory();
  }, [
    contentSize,
    currentPage,
    since,
    until,
    attendant,
    botUser,
    protocol,
    client,
    platform,
    openedAt,
    closedAt,
    tag_name,
    phone_number,
    getHistory,
    setAttendances,
    setGetHistoryPayload,
    team
  ]);

  useEffect(() => {
    if (!firstUpdate.current) {
      newMessageNotification(lastMsgSenderName, messagesNotReadCounter, lastMsgSender, current);
    }
  }, [messagesNotReadCounter]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      getTeams(user?.company || '');
    }

    socket.on(ONGOING_CHAT_MSG_EVENT, (msg: Message) => getMessagesNotReadCounter(msg.user_id || ''));
    return () => {
      socket.off(ONGOING_CHAT_MSG_EVENT);
    };
  }, []);

  useEffect(() => {
    setPlatform(checkBotType());
  }, [attendances]);

  const handlePeriod = (period: Period) => {
    const sinceString = period.since.toISOString();
    const untilString = period.until.toISOString();

    setSince(sinceString);
    setUntil(untilString);
  };

  const checkBotType = () => {
    const firstAttendance = attendances[0];
    if (firstAttendance) {
      return firstAttendance.platform;
    }
    return '';
  };

  const whatsAppMask = (value: string) => 
    value
      .replace(/\D/, '')
      .replace(/(\d{0,2})(\d{0,2})(\d{0,4})(\d*)/, '+$1 ($2) $3-$4');
  
  const getPlataformNumberLabel = () =>{
    switch (platform) {
      case 'whatsapp':
        return 'Número de telefone';
      case 'web':
        return 'ID da sessão';
      case 'facebook':
        return 'PSID do usuário';
      default:
        return 'Número de telefone';
    }
  };

  return (
    <div className="app-container">
      <ReactNotification />
      <DashboardMenu>
        <div className={styles.content}>
          <section className={styles.infoContainer}>
            <div className={styles.header}>
              <h1>Histórico de atendimentos</h1>
              <p>Veja seus atendimentos no período especificado</p>
            </div>

            <PeriodPicker onSelect={handlePeriod} />
          </section>

          <section className={styles.tableContainer}>
            <Table
              width="80%"
              height="80vh"
              title="Seus atendimentos no período"
              columns={HISTORY_COLUMNS}
              data={historyToLineItem(attendances, {
                attendantName: user?.name,
                clientName: 'Qualquer',
              })}
              loading={loadingHistory}
              topRightItem={
                <div className={styles.tableOptions}>
                  <img src={optionsImg} alt="Itens por página" />
                  <RegularPicker
                    label="Itens por página"
                    onSelect={(selected) => setContentSize(Number(selected))}
                    options={CONTENT_OPTIONS}
                  />
                </div>
              }
            />

            <div className={styles.filtersContainer}>
              <div hidden={shouldHideIf(user, ['attendant', 'manager'])}>
                <EditableLabel
                  label="Cliente/Marca"
                  onSubmit={(value) => setClient(String(value))}
                />
              </div>

              <div>
                <button
                  type="button"
                  className={styles.optionItem}
                  onClick={() => {
                    setShowTags(true);
                  }}
                >
                  <span className={styles.tagsLabel}>Tags do atendimento</span>
                  <span className={styles.tagsValue}>
                    <p>{tags.length > 0 ? 'Alterar' : 'Qualquer'}</p>
                    <FaAngleDown size={20} />
                  </span>
                </button>
                <TagsModal
                  isOpen={showTags}
                  onClose={() => setShowTags(false)}
                  viewType='filter'
                  selectTags={{ tags, setTags }}
                  setTags={setTag}
                />
              </div>
              <div>                
                <EditableLabel
                  label={getPlataformNumberLabel()}
                  onSubmit={(value) => setPhone(String(value))}
                  mask={(platform === 'whatsapp') ? whatsAppMask : undefined}
                />
              </div>             
              <div hidden={shouldHideIf(user, ['attendant'])}>
                <SearchPicker
                  mode="attendants"
                  label="Atendente"
                  onSelect={setAttendant}
                />
              </div>

              <div>
                <EditableLabel
                  label="Protocolo"
                  onSubmit={(value) => setProtocol(String(value))}
                />
              </div>

              <div>
                <RegularPicker
                  label="Plataforma"
                  options={PLATFORM_OPTIONS}
                  onSelect={(selected) => setPlatform(String(selected))}
                  unpicked={UNPICKED_ITEM}
                />
              </div>

              <div hidden={shouldHideIf(user, ['attendant'])}>
                <RegularPicker
                  label="Equipe"
                  options={teams.map(teamTemp => (
                    {
                      label: teamTemp.name,
                      value: teamTemp.id
                    }
                  ))}
                  onSelect={(selected) => setTeam(String(selected))}
                  unpicked={UNPICKED_ITEM}
                />
              </div>

              <div>
                <SearchPicker
                  mode="users"
                  label="Usuário"
                  onSelect={setBotUser}
                />
              </div>

              <div>
                <DatePicker
                  label="Data de abertura"
                  selected={openedAt}
                  onSelect={(date) => setOpenedAt(date)}
                />
              </div>

              <div>
                <DatePicker
                  label="Data de fechamento"
                  selected={closedAt}
                  onSelect={(date) => setClosedAt(date)}
                />
              </div>

              <Paginator
                current={currentPage}
                hasNext={hasNextPage}
                nextPage={() => setCurrentPage(currentPage + 1)}
                previousPage={() => {
                  if (currentPage > 1) setCurrentPage(currentPage - 1);
                }}
              />
            </div>
          </section>
        </div>
      </DashboardMenu>
    </div>
  );
}

export default History;

function shouldHideIf(user: User | null, userTypes: UserType[]) {
  if (user) {
    return userTypes.includes(user.type);
  }

  return false;
}
