import Modal from '@components/Modal';
import alert from '@assets/alert.png';
import check from '@assets/check.png';
import styles from './styles.module.css';

export interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  success?: boolean;
}

function AlertModal({ isOpen, onClose, message, success }: AlertModalProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={styles.messageContainer}>
        <div className={styles.imageContainer}>
          <img
            src={success ? check : alert}
            alt={success ? 'Sucesso' : 'Alerta'}
          />
        </div>
        <p>{message}</p>
      </div>
      <button className={styles.confirm} type="button" onClick={onClose}>
        OK
      </button>
    </Modal>
  );
}

export default AlertModal;
