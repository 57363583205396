import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import styles from './styles.module.css';

export interface PaginatorProps {
  current: number;
  hasNext: boolean;
  nextPage: () => void;
  previousPage: () => void;
}

function Paginator({ current, hasNext, nextPage, previousPage }: PaginatorProps) {
  return (
    <div style={{ background: 'none' }} className={styles.container}>
      <span>Página {current}</span>

      <div className={styles.paginator}>
        <button type="button" onClick={previousPage} disabled={current === 1}>
          <FaAngleLeft size={14} color="black" />
          Anterior
        </button>

        <span />

        <button type="button" onClick={nextPage} disabled={!hasNext}>
          Próxima
          <FaAngleRight size={14} color="black" />
        </button>
      </div>
    </div>
  );
}

export default Paginator;
