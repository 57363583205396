import { useEffect, useRef } from 'react';
import { Message } from '@entities';
import AudioMessage from './components/AudioMessage';
import ImageMessage from './components/ImageMessage';
import TextMessage from './components/TextMessage';
import VideoMessage from './components/VideoMessage';
import DocumentMessage from './components/DocumentMessage';
import styles from './styles.module.css';

interface MessageProps {
  messages: Message[];
  setMedia: (type: string, content: string) => void;
}

function Messages({ messages, setMedia }: MessageProps) {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  // return (
  //   <div className={styles.messages}>
  //     {messages.map((payload) => (
  //       <div
  //         key={Math.random()}
  //         className={
  //           payload.origin === 'user'
  //             ? styles.messageOrigin
  //             : styles.messageSender
  //         }
  //       >
  //         <p>{payload.message}</p>
  //       </div>
  //     ))}
  //     <div ref={messagesEndRef} />
  //   </div>
  // );

  const getMessage = (message: Message) => {
    const { body } = message;
    if (body) {
      const { message_type } = body;
      switch (message_type) {
        case 'AUDIO':
          return <AudioMessage 
            key={Math.random()} 
            message={message} 
            setMedia={setMedia} 
          />;
        case 'IMAGE':
          return <ImageMessage 
            key={Math.random()} 
            message={message} 
            setMedia={setMedia} 
          />;
        case 'VIDEO':
          return <VideoMessage 
            key={Math.random()} 
            message={message} 
            setMedia={setMedia} 
          />;
        case 'DOCUMENT':
          return <DocumentMessage 
            key={Math.random()} 
            message={message}  
          />;
        default:
          return <TextMessage key={Math.random()} message={message} />;
      }
    } else {
      return <TextMessage key={Math.random()} message={message} />;
    }
  };

  return (
    <div className={styles.messages}>
      {messages.map((payload) => getMessage(payload))}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default Messages;
