import { AiFillPlusCircle } from 'react-icons/ai';
import { RiRobotLine } from 'react-icons/all';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useModel } from '@flux/models/pages/contactsPage/hooks';
import { useModel as useModelApplication } from '@flux/models/application/hooks';
import { csvToJson } from '@utils/csvUtils';
import { filterPhone, verifyPhone } from '@utils/phoneUtil';
import * as AdminHook from '@flux/models/pages/adminPage/hooks';
import { Contact, ContactToImport } from '@entities';
import styles from './styles.module.css';

interface ImportViewProps {
  contacts: Contact[],
  onClose: Function
}


function ImportView({ contacts, onClose }: ImportViewProps) {
  const { importContacts } = useModel();
  const { user } = useModelApplication();

  const { getChatbots, chatbots } = AdminHook.useModel();
  const [selectedBot, setSelectedBot] = useState('');
  const [contactsToImport, setContatcsToImport] = useState([]);
  const [contactsWithError, setContactsWithError] = useState([]);

  useEffect(() => {
    getChatbots(user?.company || '');
  }, [getChatbots, user?.company]);

  useEffect(() => {
    if (chatbots.length > 0) {
      setSelectedBot(chatbots[0].id);
    }
  }, [chatbots]);

  const company = user ? user.company : '';

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      importContacts({ contacts, newContacts: contactsToImport, company, onClose, bot_id: selectedBot });
    },
    [company, contacts, onClose, contactsToImport]
  );

  async function handleFile(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file.name.endsWith('.csv')) {
        const contactsFromFile = await csvToJson(file);
        // @ts-ignore
        contactValidator(contactsFromFile);
      }
    }
  }

  function contactValidator(contactList: Array<ContactToImport>) {
    const correctContacts: Array<ContactToImport> = [];
    const errorContacts: Array<ContactToImport> = [];
    contactList.forEach((element: ContactToImport) => {
      if (element.telefone && element.nome) {
        const telefone = filterPhone(element.telefone);
        if (verifyPhone(telefone)) {
          correctContacts.push({
            nome: element.nome,
            telefone
          });
          return;
        }
      }
      errorContacts.push(element);
    });
    // @ts-ignore
    setContatcsToImport(correctContacts);
    // @ts-ignore
    setContactsWithError(errorContacts);
  }

  return (
    <div className={styles.container}>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.title}>
          <h1>Importar Contatos</h1>
          <p>Selecione um arquivo csv com as colunas "nome" e "telefone".</p>
        </div>

        <div className={styles.input}>
          <input
            type="file"
            accept=".csv"
            onChange={event => handleFile(event)}
          />
        </div>

        <div className={styles.input}>
          <RiRobotLine size={20} />
          <select
            value={selectedBot}
            onChange={e => setSelectedBot(e.target.value)}
            required={true}
          >
            {chatbots.map(bot =>
              <option key={bot.id} value={bot.id}>{bot.name}</option>
            )}
          </select>
        </div>

        <div className={styles.contactResult}>
          <div className={styles.contactResultCorrect}>Corretos: {contactsToImport.length}</div>
          <div className={styles.contactResultIncorrect}>Inválidos: {contactsWithError.length}</div>
        </div>

        <button className={styles.newContactButton} type="submit">
          <span>Adicionar Contatos</span>
          <AiFillPlusCircle size={30} color="white" />
        </button>
      </form>
    </div>
  );
}

export default ImportView;
