import { AiFillCloseCircle } from 'react-icons/ai';
import Modal from '@components/Modal';
import { ContactToImport, ContactWithErrorInterface } from '@entities';
import CorrectView from '@pages/BulkMessaging/components/ResultModal/components/CorrectView';
import InvalidView from '@pages/BulkMessaging/components/ResultModal/components/InvalidView';
import styles from './styles.module.css';

export type ViewType = 'correct' | 'invalid';

export interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  contactsToSend?: ContactToImport[];
  contactsWithError?: ContactWithErrorInterface[];
  message: string;
  type?: ViewType;
}

function ContactModal({ isOpen, onClose, contactsToSend, contactsWithError, message, type = 'correct' }: ResultModalProps) {
  const viewComponent = {
    correct: <CorrectView contacts={contactsToSend} message={message} />,
    invalid: <InvalidView contacts={contactsWithError} />,
  };


  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <header className={styles.header}>
        <h1>Contatos {type === 'correct' ? 'Corretos' : 'Inválidos'}</h1>
        <button type="button" onClick={onClose}>
          <AiFillCloseCircle size={30} color="#999" />
        </button>
      </header>

      {viewComponent[type]}
    </Modal>
  );
}

export default ContactModal;
