import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { ProfilePageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const profilePageState = useSelector<RootState, ProfilePageState>(
    (state) => state.profilePage
  );

  return {
    ...dispatch.profilePage,
    ...profilePageState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.models.profilePage
  );

  return loading;
};
