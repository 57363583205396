import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Contact, ContactToImport } from '@entities';
import { filterPhone, verifyPhone } from '@utils/phoneUtil';
import api, { errorHandler } from '@services/api';
import { ContactPageState, GetContactPayload } from '@flux/models/pages/contactsPage/entities';

interface CreateContactPayload {
  name: string,
  phone: string,
  company: string,
  bot_id: string,
  contacts: Contact[],
  onClose: Function
}

interface ImportContactsPayload {
  newContacts: Array<ContactToImport>,
  company: string,
  bot_id: string,
  contacts: Contact[],
  onClose: Function
}

interface RemoveContactPayload {
  id: string,
  contacts: Contact[],
}

interface UpdateContactPayload {
  id: string,
  newName: string,
  newPhone: string,
  newBotId: string,
  contacts: Contact[],
  onClose: Function
}

export const contactPage = createModel<RootModel>()({
  state: {
    error: '',
    contacts: [],
    getContactPayload: null,
    hasNextPage: false,
  } as ContactPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setGetContactPayload(state, payload: GetContactPayload) {
      state.getContactPayload = payload;
      return state;
    },

    setContacts(state, payload: Contact[]) {
      state.contacts = payload;
      return state;
    },

    setHasNextPage(state, payload: boolean) {
      state.hasNextPage = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async getContacts(payload, state) {
      const { setError, setContacts, setHasNextPage } = dispatch.contactPage;
      const { getContactPayload } = state.contactPage;

      if (!getContactPayload) return;

      const url = getContactsUrl(getContactPayload);

      await errorHandler(async () => {
        const response = await api.get(url);
        // const { contacts, hasNext } = response.data;
        const contacts = response.data;

        setContacts(contacts);
        setHasNextPage(false);
      }, setError);
    },
    async createContact(payload: CreateContactPayload) {
      const url = '/contacts';
      const { setContacts, setError } = dispatch.contactPage;

      const phone = filterPhone(payload.phone);

      if (!verifyPhone(phone)) {
        setError('Digite um Telefone Válido');
        return;
      }

      if (payload.bot_id === '') {
        setError('Selecione um Bot');
        return;
      }

      await errorHandler(async () => {
        const response = await api.post(url, {
          name: payload.name,
          phone,
          company: payload.company,
          bot_id: payload.bot_id
        });
        const newContact: Contact = response.data;
        setContacts([...payload.contacts, newContact]);
        payload.onClose();
      }, setError);
    },

    async importContacts(payload: ImportContactsPayload) {
      const url = '/contacts/many';
      const { setContacts, setError } = dispatch.contactPage;

      if (payload.bot_id === '') {
        setError('Selecione um Bot');
        return;
      }

      await errorHandler(async () => {
        const response = await api.post(url, {
          contacts: payload.newContacts,
          company: payload.company,
          bot_id: payload.bot_id
        });
        const newContacts: Contact[] = response.data;
        setContacts([...payload.contacts, ...newContacts]);
        payload.onClose();
      }, setError);
    },

    async removeContact(payload: RemoveContactPayload) {
      const url = `/contacts/${payload.id}`;
      const { setContacts, setError } = dispatch.contactPage;

      await errorHandler(async () => {
        await api.delete(url);
        const newContacts = payload.contacts.filter(contact => contact.id !== payload.id);
        setContacts(newContacts);
      }, setError);
    },
    async updateContact(payload: UpdateContactPayload) {
      const url = `/contacts/${payload.id}`;
      const { setContacts, setError } = dispatch.contactPage;

      const newPhone = filterPhone(payload.newPhone);

      if (!verifyPhone(newPhone)) {
        setError('Digite um Telefone Válido');
        return;
      }

      if (payload.newBotId === '') {
        setError('Selecione um Bot');
        return;
      }

      await errorHandler(async () => {
        await api.put(url, {
          new_name: payload.newName,
          new_phone: newPhone,
          new_bot_id: payload.newBotId
        });

        const newContact = payload.contacts.map(contact => {
          if (contact.id === payload.id) {
            return {
              ...contact,
              name: payload.newName,
              phone: newPhone,
              bot_id: payload.newBotId
            };
          }
          return contact;
        });

        setContacts(newContact);
        payload.onClose();
      }, setError);
    },
  }),
});

function getContactsUrl(
  payload: GetContactPayload
) {
  const {
    page = 1,
    contentSize = 25,
    phone_number
  } = payload;

  const url = new URL(`${process.env.REACT_APP_API}/contacts`);

  const skip = (page - 1) * contentSize;
  url.searchParams.append('skip', String(skip));
  url.searchParams.append('limit', String(contentSize));

  if (phone_number) {
    url.searchParams.append('phone_number', phone_number);
  }

  return url.href;
}
