import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { User } from '@entities/index';
import api, { errorHandler } from '@services/api';

export interface ApplicationState {
  error: string;
  logged: boolean;
  token: string | null;
  user: User | null;
}

interface SetLoggedPayload {
  user: User | null;
  token: string | null;
}

export const application = createModel<RootModel>()({
  state: {
    error: '',
    logged: false,
    token: null,
    user: null,
  } as ApplicationState,

  reducers: {
    setLogged(state, payload: SetLoggedPayload) {
      const { user, token } = payload;

      state = {
        error: '',
        logged: !!user && !!token,
        token,
        user,
      };

      return state;
    },

    setUser(state, payload: User | null) {
      state.user = payload;
      return state;
    },

    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    logOut(state) {
      state = {
        error: '',
        logged: false,
        token: null,
        user: null,
      };

      return state;
    },
  },

  effects: (dispatch) => ({
    async clearData() {
      dispatch.application.logOut();
      window.open('/login', '_self');
    },

    async verifyToken() {
      const { setError } = dispatch.application;
      await errorHandler(async () => {
        await api.get('/profile');
      }, setError);
    },
  }),
});