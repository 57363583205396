import { createModel } from '@rematch/core';
import { RootModel } from '@flux/models';
import { Chatbot, Company, Task, TaskTeam, Team, User } from '@entities';
import api, { errorHandler } from '@services/api';

export interface AdminPageState {
  error: string;
  chatbots: Chatbot[];
  teams: Team[];
  collaborators: User[];
  requests: User[];
  companies: Company[];
  logs: HoursLogged[]
}

interface CreateTeamPayload {
  name: string,
  teams: Team[],
  company: string
}

interface RemoveTeamPayload {
  id: string,
  teams: Team[],
}

interface UpdateTeamPayload {
  id: string,
  newName: string,
  teams: Team[],
}

interface CreateBot {
  id: string;
  name: string;
  url: string;
  created_at: string;
  plataform: string;
}

interface ToogleAvailablePayload {
  company: string;
  available: boolean;
  email: string;
}

interface RemoveBotPayload {
  bot: Chatbot;
  updateCompany: string;
}

interface RemoveUserPayload {
  user: User;
  updateCompany: string;
}

interface RequestPayload {
  user: User;
  can_login_request: boolean;
  origem: string;
}

interface UpdateTeamsPayload {
  user: User;
  tasks: TaskTeam[];
}

interface UpdateBotsPayload {
  user: User;
  tasks: Task[];
}

interface ChangeUserTypePayload {
  user: User;
  type: 'administrator' | 'manager' | 'attendant';
}

interface MakeManagerPayload {
  user: User,
  origem: 'administrator' | 'manager';
}

interface AddCompanyPayload {
  nome: string;
  sigla: string;
}

interface AddChatbotPayload {
  nome: string;
  company: Company;
  url: string;
  plataform: string;
  plataformId: string;
}

interface HoursLogged {
  hours: number;
  attendant_name: string;
  attendant_id: string;
  company: string;
}

interface getLogsPayload {
  company: string;
  since: Date;
  until: Date;
}

const createBot = (bot: CreateBot) => {
  const newBot = {
    ...bot,
    platform: bot.plataform,
  };
  return newBot;
};

export const adminPage = createModel<RootModel>()({
  state: {
    error: '',
    chatbots: [],
    teams: [],
    collaborators: [],
    requests: [],
    companies: [],
    logs: [],
  } as AdminPageState,

  reducers: {
    setError(state, payload: string) {
      state.error = payload;
      return state;
    },

    setChatbots(state, payload: Chatbot[]) {
      state.chatbots = payload;
      return state;
    },

    setTeams(state, payload: Team[]) {
      state.teams = payload;
      return state;
    },

    setCollaborators(state, payload: User[]) {
      state.collaborators = payload;
      return state;
    },

    setRequests(state, payload: User[]) {
      state.requests = payload;
      return state;
    },

    setCompanies(state, payload: Company[]) {
      state.companies = payload;
      return state;
    },

    setLogs(state, payload: HoursLogged[]) {
      state.logs = payload;
      return state;
    }
  },

  effects: (dispatch) => ({
    async getChatbots(payload: string) {
      const url = `/team/search/bots${payload ? `?company=${payload}` : ''}`;
      const { setChatbots, setError } = dispatch.adminPage;

      await errorHandler(async () => {
        const response = await api.get(url);
        const { data } = response.data;
        const bots = data.map((bot: CreateBot) => createBot(bot));
        setChatbots(bots);
      }, setError);
    },

    async getTeams(payload: string) {
      const url = `/attendances-teams/${payload}`;
      const { setTeams, setError } = dispatch.adminPage;

      await errorHandler(async () => {
        const response = await api.get(url);
        const teams = response.data;
        setTeams(teams);
      }, setError);
    },

    async getUsers(payload: string) {
      const url = `/team/search/users${payload ? `?company=${payload}` : ''}`;
      const {
        setCollaborators,
        setRequests,
        setError,
        getRequests
      } = dispatch.adminPage;

      await errorHandler(async () => {
        const response = await api.get(url);
        const { companyUsers } = response.data;
        const { can_login_users, cannot_login_users } = companyUsers.data;
        setCollaborators(can_login_users);
        if (payload) {
          // getUsers foi chamado de manager, retorna usuarios e requests de uma company
          setRequests(cannot_login_users);
        } else {
          // getUsers foi chamado de admin, chama get requests para retornar todas as solicitações
          getRequests();
        }
      }, setError);
    },

    async getRequests() {
      const { setRequests, setError } = dispatch.adminPage;
      await errorHandler(async () => {
        const response = await api.get('/approvals');
        const { data } = response;
        setRequests(data);
      }, setError);
    },

    async getCompanies() {
      const { setCompanies, setError } = dispatch.adminPage;
      await errorHandler(async () => {
        const response = await api.get('/companies');
        const { data } = response;
        setCompanies(data);
      }, setError);
    },

    async createTeam(payload: CreateTeamPayload) {
      const url = '/attendances-teams';
      const { setTeams, setError } = dispatch.adminPage;

      await errorHandler(async () => {
        const response = await api.post(url, {
          name: payload.name,
          company: payload.company
        });
        const newTeam: Team = {
          id: response.data.id,
          name: response.data.name
        };
        setTeams([...payload.teams, newTeam]);
      }, setError);
    },

    async updateTeam(payload: UpdateTeamPayload) {
      const url = `/attendances-teams/${payload.id}`;
      const { setTeams, setError } = dispatch.adminPage;

      await errorHandler(async () => {
        await api.put(url, {
          new_name: payload.newName
        });

        const newTeams = payload.teams.map(team => {
          if (team.id === payload.id) {
            return {
              ...team,
              name: payload.newName
            };
          }
          return team;
        });

        setTeams(newTeams);
      }, setError);
    },

    async removeTeam(payload: RemoveTeamPayload) {
      const url = `/attendances-teams/${payload.id}`;
      const { setTeams, setError } = dispatch.adminPage;

      await errorHandler(async () => {
        await api.delete(url);
        const newTeams = payload.teams.filter(team => team.id !== payload.id);
        setTeams(newTeams);
      }, setError);
    },

    async toogleAvailable(payload: ToogleAvailablePayload) {
      const { setError, getUsers } = dispatch.adminPage;
      const { available, company, email } = payload;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          available,
          company,
        });
        await getUsers(company);
      }, setError);
    },

    async removeBot(payload: RemoveBotPayload) {
      const { setError, getChatbots } = dispatch.adminPage;
      const { bot, updateCompany } = payload;
      const { id, company, url } = bot;
      await errorHandler(async () => {
        await api.put('bots', {
          id,
          company,
          updateCompany,
          url,
        });
        await getChatbots(company);
      }, setError);
    },

    async deleteBot(payload: Chatbot) {
      const { setError, getChatbots } = dispatch.adminPage;
      const { id } = payload;
      await errorHandler(async () => {
        await api.delete(`bots/${id}`, {
          params: { id }
        });
        await getChatbots('');
      }, setError);
    },

    async removeUser(payload: RemoveUserPayload) {
      const { setError, getUsers } = dispatch.adminPage;
      const { user, updateCompany } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          company: updateCompany,
        });
        await getUsers(company);
      }, setError);
    },

    async resolveRequest(payload: RequestPayload) {
      const { setError, getUsers } = dispatch.adminPage;
      const { user, can_login_request, origem } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          can_login_request,
        });
        await getUsers(origem === 'administrator' ? '' : company);
      }, setError);
    },

    async updateBots(payload: UpdateBotsPayload) {
      const { setError } = dispatch.adminPage;
      const { user, tasks } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          tasks,
        });
      }, setError);
    },

    async updateTeams(payload: UpdateTeamsPayload) {
      const { setError } = dispatch.adminPage;
      const { user, tasks } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put('team/user', {
          email,
          company,
          teams: tasks,
        });
      }, setError);
    },

    async changeUserType(payload: ChangeUserTypePayload) {
      const { setError, getUsers } = dispatch.adminPage;
      const { user, type } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          type,
        });
        await getUsers(company);
      }, setError);
    },

    async makeAttendant(payload: MakeManagerPayload) {
      const { setError, getUsers } = dispatch.adminPage;
      const { user, origem } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          type: 'attendant',
        });
        await getUsers(origem === 'administrator' ? '' : company);
      }, setError);
    },

    async makeManager(payload: MakeManagerPayload) {
      const { setError, getUsers } = dispatch.adminPage;
      const { user, origem } = payload;
      const { email, company } = user;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          type: 'manager',
        });
        await getUsers(origem === 'administrator' ? '' : company);
      }, setError);
    },

    async makeAdmin(payload: User) {
      const { setError, getUsers } = dispatch.adminPage;
      const { email, company } = payload;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          type: 'administrator',
        });
        await getUsers('');
      }, setError);
    },

    async deactivateUser(payload: User) {
      const { setError, getUsers } = dispatch.adminPage;
      const { email, company } = payload;
      await errorHandler(async () => {
        await api.put(`team/user?company=${company}`, {
          email,
          can_login: false,
        });
        await getUsers('');
      }, setError);
    },

    async addCompany(payload: AddCompanyPayload) {
      const { setError, getCompanies } = dispatch.adminPage;
      const { nome, sigla } = payload;
      await errorHandler(async () => {
        await api.post('companies', {
          fullname: nome,
          company: sigla,
        });
        await getCompanies();
      }, setError);
    },

    async addChatbot(payload: AddChatbotPayload) {
      const { setError, getChatbots } = dispatch.adminPage;
      const { nome, company, url, plataform, plataformId  } = payload;
      await errorHandler(async () => {
        await api.post('bots', {
          name: nome,
          url,
          plataform,
          plataform_id: plataformId,
          company_id: company.id
        });
        await getChatbots('');
      }, setError);
    },

    async getLogs(payload: getLogsPayload) {
      const { setError, setLogs } = dispatch.adminPage;
      const { since, until, company } = payload;
      await errorHandler(async () => {
        const resp = await api.get(`logs?since=${since}&until=${until}&company=${company}`);
        await setLogs(resp.data);
      }, setError);
    },
  }),
});

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
