import { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import styles from './styles.module.css';

export interface PickerItem {
  label: string;
  value: unknown;
}

export interface RegularPickerProps {
  label: string;
  options: PickerItem[];
  onSelect: (selected: unknown) => void;
  unpicked?: PickerItem;
}

function RegularPicker({
  label,
  onSelect,
  options,
  unpicked,
}: RegularPickerProps) {
  const [selected, setSelected] = useState(
    unpicked ? unpicked.label : options[0].label
  );

  return (
    <div className={styles.container}>
      <p>{label}</p>

      <span className={styles.current}>
        <p>{selected}</p>
        <FaAngleDown size={20} />
      </span>

      <ul className={styles.dropDownContent}>
        {unpicked && (
          <li key={Math.random()}>
            <button
              type="button"
              onClick={() => {
                onSelect(unpicked.value);
                setSelected(unpicked.label);
              }}
            >
              {unpicked.label}
            </button>
          </li>
        )}

        {options.map((option) => (
          <li key={Math.random()}>
            <button
              type="button"
              onClick={() => {
                onSelect(option.value);
                setSelected(option.label);
              }}
            >
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RegularPicker;
