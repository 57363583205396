import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { SendEmailPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const sendEmailPageState = useSelector<RootState, SendEmailPageState>(
    (state) => state.sendEmailPage
  );

  return {
    ...dispatch.sendEmailPage,
    ...sendEmailPageState,
  };
};

export const useLoading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.loading.effects.sendEmailPage.sendEmail
  );

  return loading;
};
