import { useEffect, useState } from 'react';
import { Company } from '@entities';
import { BsPlus, BsArrowLeft } from 'react-icons/bs';
import SearchInput from '@components/SearchInput';
import GradCells from '@pages/Admin/components/GradCells';
import CompanyCard from '@pages/Admin/AdminView/components/CompanyCard';
import AddCompany from '@pages/Admin/AdminView/components/AddCompany';
import styles from './styles.module.css';

interface CompaniesTabProps {
  companies: Company[];
}

function CompaniesTab({ companies }: CompaniesTabProps) {

  const [searchTerms, setSearchTerms] = useState('');
  const [selected, setSelected] = useState<Company | null>(null);
  const [showCompanies, setShowCompanies] = useState<Company[]>([]);
  const [addCompany, setAddCompany] = useState(false);

  useEffect(() => {
    if (searchTerms) {
      setShowCompanies(
        companies.filter(
          (company: Company) =>
            company.fullname
              .toLocaleLowerCase()
              .includes(searchTerms.toLocaleLowerCase())
        )
      );
    } else {
      setShowCompanies(companies);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  useEffect(() => {
    setShowCompanies(companies);
  }, [companies]);

  const toggleSelected = (item: Company) => {
    setSelected(selected?.id === item.id ? null : item);
  };

  return (
    <div>
      <section className={styles.navBarTeam}>
        <h1>Todas as Empresas</h1>

        <button 
          type="button" 
          onClick={() => {
            setAddCompany(!addCompany);
          }}
        >
          {addCompany ? <BsArrowLeft color="#4a69bd" size={35} /> : <BsPlus color="#4a69bd" size={35} />}
          {addCompany ? 'Voltar' : 'Nova Empresa'}
        </button>

        {!addCompany && <SearchInput
          placeholder="Pesquise por nome e plataformas..."
          type="text"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />}
      </section>

      <section className={styles.companiesContainer}>
        {addCompany ? 
          <AddCompany callback={() => setAddCompany(!addCompany)} /> :
          <GradCells
            items={showCompanies}
            getTitle={(company) => company.fullname}
            getDescription={(company) => `ID: ${company.id}`}
            onSelect={toggleSelected}
          />}

        {selected && (
          <section className={styles.infoCompany}>
            <CompanyCard 
              company={selected} 
              setCompany={setSelected}
            />
          </section>
        )}
      </section>
    </div>
  );
}

export default CompaniesTab;