import { ButtonHTMLAttributes } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import styles from './styles.module.css';

export interface SubmitButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

function EditCollaboratorBotsButton({
  className,
  style,
  ...props
}: SubmitButtonProps) {
  return (
    <button className={styles.button} {...props} type="button">
      <FiEdit2 />
      Editar acesso aos chatbots
    </button>
  );
}
export default EditCollaboratorBotsButton;
