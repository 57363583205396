import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsPersonFill } from 'react-icons/bs';
import { IoSettingsOutline } from 'react-icons/io5';
import { CgFileDocument } from 'react-icons/cg';
import { RiLogoutBoxLine, RiArrowDropDownLine } from 'react-icons/ri';
import * as Application from '@flux/models/application/hooks';
import person from '@assets/person.png';
import styles from './styles.module.css';

export interface DropDownMenuItemProps {
  text: ReactNode;
  leftIcon?: ReactNode;
  label?: ReactNode;
  onClick?: () => void;
}

function DropDownMenuItem({
  text,
  leftIcon,
  label,
  onClick,
}: DropDownMenuItemProps) {
  return (
    <button
      className={styles.menuItem}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick();
      }}
      type="button"
    >
      {label && <span className={styles.labelButton}>{label}</span>}
      <span className={styles.menuValue}>
        {leftIcon}
        {text}
      </span>
    </button>
  );
}

function DropDownMenu() {
  const history = useHistory();
  const { clearData } = Application.useModel();

  return (
    <div className={styles.dropdownContent}>
      <DropDownMenuItem
        text="Perfil"
        label="Usuário"
        onClick={() => history.push('/perfil')}
        leftIcon={<BsPersonFill />}
      />

      <div className={styles.dropLine} />

      <DropDownMenuItem
        onClick={() => history.push('/configuracoes')}
        text="Configurações"
        label="Empresa"
        leftIcon={<IoSettingsOutline />}
      />

      <div className={styles.dropLine} />

      <DropDownMenuItem
        onClick={() => {
          window.open(
            'https://storage.googleapis.com/teamelifebots/roboter/Pol%C3%ADtica%20de%20Privacidade%20dos%20bots%20FB.pdf',
            '_blank'
          );
        }}
        text="Termos de Uso"
        leftIcon={<CgFileDocument />}
      />

      <DropDownMenuItem
        onClick={clearData}
        text="Sair"
        leftIcon={<RiLogoutBoxLine />}
      />
    </div>
  );
}

function DropDown() {
  const { user } = Application.useModel();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user?.name) {
      setMessage(user.name);
    } else {
      setMessage('seja muito bem-vindo(a)!');
    }
  }, [user]);

  return (
    <div className={styles.dropdown}>
      <button type="button" tabIndex={0} className={styles.dropDownButton}>
        <img src={user?.avatar_url || person} alt={message} />
        <p>Olá, {message}</p>
        <RiArrowDropDownLine size={20} color="black" />
      </button>

      <DropDownMenu />
    </div>
  );
}

export default DropDown;
