import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '@flux/store';
import { SettingsOptionsPageState } from './index';

export const useModel = () => {
  const dispatch = useDispatch<Dispatch>();
  const settingsOptionsPageState = useSelector<
    RootState,
    SettingsOptionsPageState
  >((state) => state.settingsOptionsPage);

  return {
    ...dispatch.settingsOptionsPage,
    ...settingsOptionsPageState,
  };
};

export const useLoading = () => {
  const loadingChangePassword = useSelector<RootState, boolean>(
    (state) => state.loading.effects.settingsOptionsPage.handleChangePassword
  );

  const loadingDeleteAccount = useSelector<RootState, boolean>(
    (state) => state.loading.effects.settingsOptionsPage.handleDeleteAccount
  );

  return { loadingChangePassword, loadingDeleteAccount };
};
