import { ReactNode, useState, useEffect } from 'react';
import LoadingLabel from '@components/LoadingLabel';
import { Attendance, Team } from '@entities';


import styles from './styles.module.css';

export interface CATProps {
  topRightItem?: ReactNode;
  width?: string;
  height?: string;
  loading?: boolean;
  teams: Team[];
  attendances: Attendance[];
}

export interface teamAttendances{
  team: string;
  attendances: number;
}

function CurrentAttendancesByTeam({
  topRightItem,
  width = '800px',
  height = '10vh',
  loading,
  teams,
  attendances,
}: CATProps) {
  const [attendancesPerTeam, setAttendancesPerTeam] = useState<teamAttendances[]>([]);
  
  useEffect(() => { 
    if (teams) setAttendancesPerTeam(settingAttendances(teams, attendances));
  }, [attendances]);

  function settingAttendances(teamArray: Team[], attendanceArray: Attendance[]) {
    const arrayOfTeams: teamAttendances[] = teamArray.map( team => {
      const teamName = team.name;
      let currentAttendances = 0;

      attendanceArray.forEach( attendance => {
        if (attendance.team_id === team.id) currentAttendances += 1;
      });

      return ({
        team: teamName,
        attendances: currentAttendances
      });
    });
    return arrayOfTeams;
  }

  return (
    <div style={{ width }} className={styles.Container}>
      <header className={styles.Header}>
        <h2>Atendimentos em andamento</h2>

        {topRightItem}
      </header>

      {
        loading ? (
          <div className={styles.loadingContainer}>
            <LoadingLabel size="medium" />
          </div>
        ) : (
          <div style={{ height }} className={styles.Content}>
            {
              attendancesPerTeam && attendancesPerTeam.map( (item) => (
                <div className={styles.listItem} key={item.team}>
                  <p className={styles.teamName}>{item.team}:</p>
                  <p className={styles.teamAttendances}>{item.attendances}</p>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
}

export default CurrentAttendancesByTeam;
