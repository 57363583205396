import { ContactToImport, ContactWithErrorInterface } from '@entities';
import styles from './styles.module.css';

interface InvalidViewProps {
  contacts?: ContactWithErrorInterface[]
}

function verifyName(contact: ContactToImport): boolean {
  const { nome } = contact;
  return !!(nome && nome.length > 0);
}

function verifyPhone(contact: ContactToImport) {
  const { nome } = contact;
  return !(nome && nome.length > 0);
}

function displayError(contact: ContactToImport) {
  let error = '';
  const correctName = verifyName(contact);
  const correctPhone = verifyPhone(contact);

  if (!correctName) {
    error = 'Nome Inválido';
  } else if (!correctPhone) {
    error = 'Telefone Inválido';
  }

  return error;
}


function InvalidView({ contacts }: InvalidViewProps) {
  return (
    <div className={styles.container}>
      <div className={styles.contacts}>
        {(!contacts || contacts.length === 0) && (<h2 className={styles.emptyMessage}>Nenhum Contato</h2>)}
        {contacts?.map((contact, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.contact} key={i}>
            <div className={styles.data}>
              <span>Linha {contact.line}: {displayError(contact.data)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InvalidView;
