import { AiFillPlusCircle } from 'react-icons/ai';
import { RiTeamFill } from 'react-icons/all';
import { FormEvent, useState } from 'react';
import { useModel } from '@flux/models/pages/adminPage/hooks';
import { Team } from '@entities';
import styles from './styles.module.css';

interface UpdateViewProps {
  teams: Team[],
  onClose: Function,
  selectedTeam?: Team
}

function UpdateView({ teams, onClose, selectedTeam }: UpdateViewProps) {
  const [name, setName] = useState(selectedTeam?.name);
  const { updateTeam } = useModel();

  const handleUpdate = (e: FormEvent) => {
    e.preventDefault();
    if (name) {
      updateTeam({ id: selectedTeam?.id || '', newName: name, teams });
      onClose();
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.container} onSubmit={handleUpdate}>
        <div className={styles.title}>
          <h1>Editar Equipe</h1>
        </div>

        <div className={styles.input}>
          <RiTeamFill size={20} />
          <input
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <button className={styles.newTeam} type="submit">
          <span>Editar Equipe</span>
          <AiFillPlusCircle size={30} color="white" />
        </button>
      </form>
    </div>
  );
}

export default UpdateView;
