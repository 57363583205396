import { useState, useCallback, useEffect, FormEvent } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { BsFillLockFill } from 'react-icons/bs';
import logo from '@assets/logo.png';
import FormInput from '@components/FormInput';
import SubmitButton from '@components/SubmitButton';
import AlertModal from '@components/AlertModal';
import {
  useModel,
  useLoading,
} from '@flux/models/pages/recoverPasswordPage/hooks';
import styles from './styles.module.css';

function RecoverPassword() {
  const location = useLocation();
  const history = useHistory();
  const { success, sendNewPassword, error, setError } = useModel();
  const loading = useLoading();

  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleNewPassword = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      sendNewPassword({
        password,
        password_confirmation: passwordConfirmation,
        token,
      });
    },
    [token, password, passwordConfirmation, sendNewPassword]
  );

  useEffect(() => {
    const searchParams = queryString.parse(location.search);

    if (searchParams.token) {
      setToken(String(searchParams.token));
    }
  }, [location.search, setToken]);

  useEffect(() => {
    if (success) {
      history.push('/login');
    }
  }, [success, history]);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <section className={styles.sideImage}>
          <h1>Gerenciamento de atendimentos dos chatbots</h1>
        </section>
        <section>
          <div className={styles.formHeader}>
            <img src={logo} alt="Elife" />
            <h1>Recupere sua senha</h1>
            <h2>
              Preencha o campo com o seu endereço de e-mail cadastrado e siga o
              passo a passo do e-mail enviado.
            </h2>
          </div>
          <form className={styles.form} onSubmit={handleNewPassword}>
            <FormInput
              type="password"
              name="password"
              placeholder="Nova senha"
              icon={() => <BsFillLockFill color="black" />}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <FormInput
              type="password"
              name="password"
              placeholder="Confirmar nova senha"
              icon={() => <BsFillLockFill color="black" />}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              value={passwordConfirmation}
              required
            />

            <SubmitButton
              submit
              label="Redefinir senha"
              className={styles.submitButton}
              loading={loading}
            />

            <h2>Deseja fazer login?</h2>
            <Link to="/login">
              <strong>Iniciar sessão</strong>
            </Link>
          </form>
        </section>
      </div>

      <AlertModal
        isOpen={!!error}
        message={error}
        onClose={() => setError('')}
      />
    </div>
  );
}

export default RecoverPassword;
